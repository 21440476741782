import React, { useEffect, useRef, useState } from 'react'

export const ThirdSec = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = (index) => setHoveredIndex(index);
  const handleMouseLeave = () => setHoveredIndex(null);

    // const [isImageFixed, setIsImageFixed] = useState(false);
    // const [isInFifthSection, setIsInFifthSection] = useState(false);
    
    // const portfolioRef = useRef(null);
    // const textSections = useRef([]);
  
    // useEffect(() => {
    //   const options = {
    //     root: null,
    //     threshold: 0.1,
    //   };
  
    //   const observer = new IntersectionObserver((entries) => {
    //     entries.forEach((entry) => {
    //       const index = entry.target.getAttribute("data-index");
  
    //       if (entry.isIntersecting) {
    //         switch (parseInt(index)) {
    //           case 0:
    //             return(
    //               <>
    //               <h3>
    //               Why <span>Website Development</span> is Essential for Your <span>Business</span>
    //               </h3>
    //               <p>
    //               In today's digital world, your website is often the first point of contact between your business and potential customers. A professionally developed website serves as your 24/7 storefront, showcasing what you offer in a way that attracts and engages visitors. At Apex Creative Designs, our website design and development services focus on creating websites that are easy to navigate, mobile-friendly, and optimized for conversions.
    //               </p>
    //               </>
    //       );
    //             break;
    //           case 1:
    //             return(
    //                     <>
    //                     <h3>
    //                     Why <span>Website Development</span> is Essential for Your <span>Business</span>
    //                     </h3>
    //                     <p>
    //                     In today's digital world, your website is often the first point of contact between your business and potential customers. A professionally developed website serves as your 24/7 storefront, showcasing what you offer in a way that attracts and engages visitors. At Apex Creative Designs, our website design and development services focus on creating websites that are easy to navigate, mobile-friendly, and optimized for conversions.
    //                     </p>
    //                     </>
    //             );
    //             break;
    //           case 2:
    //             return(
    //                 <>
    //                 <h3>
    //                     Why <span>Website Development</span> is Essential for Your <span>Business</span>
    //                     </h3>
    //                     <p>
    //                     In today's digital world, your website is often the first point of contact between your business and potential customers. A professionally developed website serves as your 24/7 storefront, showcasing what you offer in a way that attracts and engages visitors. At Apex Creative Designs, our website design and development services focus on creating websites that are easy to navigate, mobile-friendly, and optimized for conversions.
    //                     </p>
    //                 </>
    //         );
    //             break;

    //           case 3:
    //             setIsInFifthSection(true);  // When the fifth section is visible
    //             break;
    //           default:
    //             break;
    //         }
    //       } else {
    //         if (parseInt(index) === 3) {
    //           setIsInFifthSection(false); // Reset when leaving fifth section
    //         }
    //       }
    //     });
    //   }, options);
  
    //   textSections.current.forEach((section) => {
    //     if (section) observer.observe(section);
    //   });
  
    //   return () => {
    //     textSections.current.forEach((section) => {
    //       if (section) observer.unobserve(section);
    //     });
    //   };
    // }, []);
  
    // useEffect(() => {
    //   const handleScroll = () => {
    //     if (portfolioRef.current) {
    //       const rect = portfolioRef.current.getBoundingClientRect();
    //       setIsImageFixed(rect.top <= 0 && rect.bottom > window.innerHeight);
    //     }
    //   };
  
    //   window.addEventListener("scroll", handleScroll);
  
    //   return () => {
    //     window.removeEventListener("scroll", handleScroll);
    //   };
    // }, []);
  return (
    <>
    <section className='designThirdSec' >
        <div className='container'>
            <div className='row'>
                <div className='col-lg-6'>
                    <div className={`firstTextSec `}>
                    <h3>
                        Why <span>Website Development</span> is Essential for Your <span>Business</span>
                        </h3>
                        <p>
                        In today's digital world, your website is often the first point of contact between your business and potential customers. A professionally developed website serves as your 24/7 storefront, showcasing what you offer in a way that attracts and engages visitors. At Apex Creative Designs, our website design and development services focus on creating websites that are easy to navigate, mobile-friendly, and optimized for conversions.
                        </p>
                    </div>
                </div>
                <div className='col-lg-6'>
                <div className='firstTextSec'>
                {[
                  {
                    title: "Custom Website Development Services ",
                    text:
                      "At Apex Creative Designs, we understand that no two businesses are alike. That's why our custom website development services are designed to deliver a site that reflects your brand identity and supports your long-term business objectives. We offer complete flexibility in design and functionality, allowing you to have a website that stands out from the competition. Custom website development goes beyond just aesthetics. It's about crafting a user experience that drives traffic, keeps visitors engaged, and turns leads into customers. Our developers work hand-in-hand with you to ensure your website is not only visually appealing but also highly functional, secure, and scalable."
                  },
                  {
                    title: "Comprehensive Website Design & Development Services",
                    text:
                      "Our team at Apex Creative Designs provides comprehensive website design & development services, handling everything from initial concept to final launch. We begin by understanding your business needs, target audience, and overall vision. This allows us to create a website that effectively communicates your message while offering an intuitive user experience. We also ensure that your website is optimized for search engines, giving you the best chance to rank highly on platforms like Google. With our website development services, you're not just getting a website but a powerful tool that works hard for your business."
                  },
                  {
                    title: "Website Development Services in the UK",
                    text:
                      "If you're based in the UK, Apex Creative Designs proudly offers website development services UK businesses can rely on. Our team understands the local market and its nuances, providing a level of service that meets the unique demands of UK-based companies. From local SEO to regulatory compliance, our services are tailored to ensure your website looks good and functions smoothly in the UK market."
                  },
                  {
                    title: "Magento Website Development Services",
                    text:
                      "Are you looking to build a powerful eCommerce platform? Our team specializes in Magento website development services, one of the most robust and flexible platforms for eCommerce businesses. Magento offers a range of features that make it ideal for companies that want to sell online and proliferate. From product management to secure payment gateways, we'll ensure your Magento site is equipped with all the tools you need to succeed. At Apex Creative Designs, we have the expertise to develop custom Magento solutions that will help you manage your online store efficiently. Whether you're looking to build a new site or revamp an existing one, our Magento website development services are designed to deliver results."
                  },
                  {
                    title: "Your Social Insights",
                    text:
                      "Understanding the impact of your social media efforts is crucial to refining your strategy. At Apex Creative Designs, we provide your social insights by offering detailed reports and analytics that help you track your performance. With insights into metrics such as engagement, reach, and conversion rates, we give you the tools to make informed decisions about your social media strategy. By consistently analyzing your social performance, we ensure your campaigns are always optimized for success."
                  }
                ].map((item, index) => (
                  <div
                    className="portfolio-text-div"
                    key={index}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <h4 data-aos="fade-left" data-aos-duration="1000">
                      <b>{item.title}</b>
                    </h4>
                    <p
                      className={`portfolio-text ${
                        hoveredIndex === index ? "show" : ""
                      }`}
                    >
                      {item.text}
                    </p>
                  </div>
                ))}
                        
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    {/* Optional styles */}
    <style jsx>{`
      .firstTextSec.fixed {
    position: fixed;
    top: 100px;
    width: 515px;
}
    
.firstTextSec.move-to-top {
    bottom: 65%;
    width: 515px;
    position: fixed;
    top: 100px;
}

    `}</style>
    </>
    
  )
}
