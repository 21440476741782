import React from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

export const FifthSec = () => {
    var settings = {
        // dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        arrows: true,
        className: "myCustomCarousel"
      };
  return (
    <>
    <section className='materialDigital'>
        <div className='container'>
        {/* <div className='row '>
        <div className='col-lg-12'>
                        <div className='firstTextSecght'>
                        <h3>
                        <span>iPhone App Development Services</span>
                        </h3>
                        <p>
                        Our iPhone app development services are designed to provide you with a high-quality, custom app that meets your specific needs. Whether you're looking to create a new app from scratch or improve an existing one, our team of experts is here to help. We use the latest tools and technologies to ensure that your iPhone app is visually appealing but also secure and reliable.
                        </p>
                        </div>
                    </div>
            </div> */}
            <div className='row workwithuk'>
                <div className='col-lg-8'>
                   <div className='colEight'>
                   <h3>
                    <span> Our Development </span> Process
                    </h3>
                    <p>
                    At Apex Creative Designs, our development process is designed to ensure the success of your mobile app. Here's a brief overview of how we work:
                    </p>
                   </div>
                </div>
            <Slider {...settings}>
            <div>
            <div className='box-digital'>
                        <img src={require('../assets/discovery_17621260-app.png')} />
                        <h4>
                        DISCOVERY & PLANNING
                        </h4>
                        <p>
                        We begin by understanding your business needs, goals, and target audience. This allows us to create a detailed plan for your sign and development.
                        </p>
                    </div>
                    </div>
                   <div>
                   <div className='box-digital'>
                        <img src={require('../assets/graphic-design_10448097.png')} />
                        <h4>
                        DESIGN
                        </h4>
                        <p>
                        Our talented app designers create a visually stunning and intuitive user interface for your app. We focus on creating a seamless user experience that engages your audience.
                        </p>
                    </div>
                    </div>
                    <div>
                    <div className='box-digital'>
                        <img src={require('../assets/coding_2010990developmeny.png')} />
                        <h4>
                        DEVELOPMENT
                        </h4>
                        <p>
                         Our experienced developers bring your app to life, using the latest tools and technologies to ensure its functionality, security, and performance.
                        </p>
                    </div>
                    </div>
                    <div>
                    <div className='box-digital'>
                        <img src={require('../assets/usability-testing_17636773-testing.png')} />
                        <h4>
                        TESTING
                        </h4>
                        <p>
                        We conduct thorough testing to ensure that your app is free of bugs and performs well on all devices. We also ensure that it meets all platform requirements and guidelines.
                        </p>
                    </div>
                    </div>
                    <div>
                    <div className='box-digital'>
                        <img src={require('../assets/Layer_32-deployment.png')} />
                        <h4>
                        DEPLOYMENT
                        </h4>
                        <p>
                        Once your app is ready, we handle the submission process to the Apple App Store and Google Play Store, ensuring that it meets all the necessary criteria for approval.
                        </p>
                    </div>
                    </div>
                    <div>
                    <div className='box-digital'>
                        <img src={require('../assets/technical-support_4230715 1.png')} />
                        <h4>
                        SUPPORT & MAINTAINCE
                        </h4>
                        <p>
                        After your app is launched, we provide ongoing support and maintenance to ensure its continued success. We offer updates and improvements as needed to keep your app running smoothly.
                        </p>
                    </div>
                    </div>
            </Slider>
            </div>
            <div className='row'>
                <div className='col-lg-4'>
                    
                </div>
                <div className='col-lg-4'>
                
                </div>
                <div className='col-lg-4'>
               
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
