import React, { useEffect, useRef, useState } from "react";

export const ThirdSec = () => {
  const [isImageFixed, setIsImageFixed] = useState(false);
  const [isInFifthSection, setIsInFifthSection] = useState(false);

  const portfolioRef = useRef(null);
  const textSections = useRef([]);

  useEffect(() => {
    const options = {
      root: null,
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const index = entry.target.getAttribute("data-index");

        if (entry.isIntersecting) {
          switch (parseInt(index)) {
            case 0:
              {
              }
              break;
            case 1:
              return (
                <>
                  <h3>
                    OUR EXPERT <span>USER INTERFACE</span> CAPABILITIES
                  </h3>
                  <p>
                    At Apex Creative Design, we pride ourselves on delivering
                    user interface (UI) designs that perfectly balance
                    creativity, usability, and functionality. Our expert team
                    brings years of experience and industry knowledge, crafting
                    UI designs that resonate with users and elevate your digital
                    products.
                    <br />
                    Our UI design capabilities are centered on creating seamless
                    and engaging user experiences across various platforms.
                    Whether you're developing a mobile app, website, or web
                    application, our expert designers ensure your interface is
                    intuitive, visually appealing, and aligned with your brand.
                  </p>
                </>
              );
              break;
            case 2:
              return (
                <>
                  <h3>
                    OUR EXPERT <span>USER INTERFACE</span> CAPABILITIES
                  </h3>
                  <p>
                    At Apex Creative Design, we pride ourselves on delivering
                    user interface (UI) designs that perfectly balance
                    creativity, usability, and functionality. Our expert team
                    brings years of experience and industry knowledge, crafting
                    UI designs that resonate with users and elevate your digital
                    products.
                    <br />
                    Our UI design capabilities are centered on creating seamless
                    and engaging user experiences across various platforms.
                    Whether you're developing a mobile app, website, or web
                    application, our expert designers ensure your interface is
                    intuitive, visually appealing, and aligned with your brand.
                  </p>
                </>
              );
              break;
            case 3:
              return (
                <>
                  <h3>
                    OUR EXPERT <span>USER INTERFACE</span> CAPABILITIES
                  </h3>
                  <p>
                    At Apex Creative Design, we pride ourselves on delivering
                    user interface (UI) designs that perfectly balance
                    creativity, usability, and functionality. Our expert team
                    brings years of experience and industry knowledge, crafting
                    UI designs that resonate with users and elevate your digital
                    products.
                    <br />
                    Our UI design capabilities are centered on creating seamless
                    and engaging user experiences across various platforms.
                    Whether you're developing a mobile app, website, or web
                    application, our expert designers ensure your interface is
                    intuitive, visually appealing, and aligned with your brand.
                  </p>
                </>
              );

            case 4:
              setIsInFifthSection(true); // When the fifth section is visible
              break;
            default:
              break;
          }
        } else {
          if (parseInt(index) === 4) {
            setIsInFifthSection(false); // Reset when leaving fifth section
          }
        }
      });
    }, options);

    textSections.current.forEach((section) => {
      if (section) observer.observe(section);
    });

    return () => {
      textSections.current.forEach((section) => {
        if (section) observer.unobserve(section);
      });
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (portfolioRef.current) {
        const rect = portfolioRef.current.getBoundingClientRect();
        setIsImageFixed(rect.top <= 0 && rect.bottom > window.innerHeight);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <section className="designThirdSec"  >
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div
                className={`firstTextSec    `}
              >
                <h3>
                  OUR EXPERT <span>USER INTERFACE</span> CAPABILITIES
                </h3>
                <p>
                  At Apex Creative Design, we pride ourselves on delivering user
                  interface (UI) designs that perfectly balance creativity,
                  usability, and functionality. Our expert team brings years of
                  experience and industry knowledge, crafting UI designs that
                  resonate with users and elevate your digital products.
                  <br />
                  Our UI design capabilities are centered on creating seamless
                  and engaging user experiences across various platforms.
                  Whether you're developing a mobile app, website, or web
                  application, our expert designers ensure your interface is
                  intuitive, visually appealing, and aligned with your brand.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="firstTextSec">
                <div
                  className="portfolio-text-div"
                  data-index="0"
                  ref={(el) => (textSections.current[0] = el)}
                >
                  <h4>
                    <b>Comprehensive UI Design Capabilities</b>
                    <br />
                    <br />
                    Custom User Interface Solutions
                  </h4>
                  <p>
                  We understand that each project is unique, and that's why we provide tailored UI design solutions. From the initial concept to final execution, we create custom interfaces that address your business needs while providing users with a smooth and engaging experience.
                  </p>
                </div>
                <div
                  className="portfolio-text-div"
                  data-index="1"
                  ref={(el) => (textSections.current[1] = el)}
                >
                  <h4>Mobile UI Design</h4>
                  <p>
                  Our team excels in designing intuitive, responsive, and visually compelling mobile interfaces for iOS and Android platforms. We follow industry best practices while adding unique elements that ensure your app stands out in a competitive market.
                  </p>
                </div>
                <div
                  className="portfolio-text-div"
                  data-index="2"
                  ref={(el) => (textSections.current[2] = el)}
                >
                  <h4>Web Application UI Design</h4>
                  <p>
                  Designing for web applications requires careful attention to usability and scalability. Our expert designers craft UIs that simplify complex processes, ensuring your web app is both user-friendly and capable of handling heavy functionality without overwhelming users.
                  </p>
                </div>
                <div
                  className="portfolio-text-div"
                  data-index="3"
                  ref={(el) => (textSections.current[3] = el)}
                >
                  <h4>E-commerce UI Design</h4>
                  <p>
                  Our expertise extends to e-commerce platforms, where a user-centered UI can dramatically increase conversion rates. We design intuitive product pages, streamlined checkout processes, and engaging shopping experiences that help drive sales and boost customer satisfaction.
                  </p>
                </div>
                <div
                  className="portfolio-text-div"
                  data-index="4"
                  ref={(el) => (textSections.current[4] = el)}
                >
                  <h4>AI and Data-Driven UI Design</h4>
                  <p>
                  In the world of artificial intelligence and data-driven applications, user interfaces need to be clear, straightforward, and easy to navigate. We create interfaces for AI systems that make complex data and technologies accessible, delivering an intuitive user experience.
                  </p>
                </div>
                <div
                  className="portfolio-text-div"
                  data-index="5"
                  ref={(el) => (textSections.current[5] = el)}
                >
                  <h4>Start Your UI Design Journey with Us</h4>
                  <p>
                  Your digital product deserves a user interface that reflects your brand, engages users, and drives success. With Apex Creative Design's expert UI capabilities, we bring your vision to life with functional and beautiful designs.
                    </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Optional styles */}
      <style jsx>{`
        .firstTextSec.fixed {
          position: fixed;
          top: 100px;
          width: 515px;
        }

        .firstTextSec.move-to-top {
          bottom: 65%;
          width: 515px;
          position: fixed;
          top: 100px;
        }
      `}</style>
    </>
  );
};
