import React, { useEffect, useRef, useState } from "react";

export const ThirdSec = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = (index) => setHoveredIndex(index);
  const handleMouseLeave = () => setHoveredIndex(null);

  return (
    <>
      <section className="designThirdSec">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div
                className={`firstTextSec`}
              >
                <h3>
                  Why Choose <span>Digital Mockup</span> Services?
                  </h3>
                  <p>
                  An animated logo takes your branding to the next level, making it memorable and engaging. Imagine your fashion brand logos coming to life with subtle motion or your Halloween logo animation adding a playful twist during the spooky season. These elements not only grab attention but also convey professionalism and creativity, which are essential for brands looking to connect with modern consumers.
                  </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="firstTextSec">
              {[
                  {
                    title: "Tailored to Your Brand's Identity",
                    text:
                      "At Apex Creative Design, we believe that every product should tell a story. That's why our digital mock up services are customized to reflect your brand's personality and values. From color schemes to design elements, our team ensures your mockup packaging aligns with your branding and captures your target audience's attention."
                  },
                  {
                    title: "High-Quality, Realistic Mockups",
                    text:
                      "Our digital mock ups stand out for their high level of detail and clarity. With crystal-clear visuals, your product can be viewed from every angle, providing a true-to-life representation. Whether it's a packaging mock up for a new product launch or a food packaging mockup for your restaurant's latest menu item, we deliver visuals that accurately represent your product's design."
                  },
                  {
                    title: "Visual Consistency Across All Platforms",
                    text:
                      "Maintaining brand consistency across platforms is crucial. Our digital mock ups are designed to look great no matter where they're displayed on your website, social media, or physical advertisements. This consistency ensures that your customers receive the same message no matter how they interact with your brand."
                  },
                  {
                    title: "Cost-Effective Solution",
                    text:
                      "Using digital mock ups is a cost-effective solution for businesses looking to perfect their product design before going to market. By providing a visual representation of your product, our mockup packaging services allow you to make necessary changes and adjustments before committing to expensive production."
                  },
                  {
                    title: "Multiple Design Revisions",
                    text:
                      "We understand that product design can be an iterative process. With our digital mock up services, you have the flexibility to request multiple revisions until you're delighted with the final look. This ensures that the packaging mock up meets all your expectations and is ready for production."
                  }
                ].map((item, index) => (
                  <div
                    className="portfolio-text-div"
                    key={index}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <h4 data-aos="fade-left" data-aos-duration="1000">
                      <b>{item.title}</b>
                    </h4>
                    <p
                      className={`portfolio-text ${
                        hoveredIndex === index ? "show" : ""
                      }`}
                    >
                      {item.text}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Optional styles */}
      <style jsx>{`
        .firstTextSec.fixed {
          position: fixed;
          top: 100px;
          width: 515px;
        }

        .firstTextSec.move-to-top {
          bottom: 65%;
          width: 515px;
          position: fixed;
          top: 100px;
        }
      `}</style>
    </>
  );
};
