import React from 'react'

export const SecondSec = () => {
  return (
    <>
    <section className='UxDesignBannerSec'>
        <div className='container'>
            <div className='row'>   
                <div className='col-lg-12'>
                    <div className='secdesign'>
                        <h3>We blend creativity and strategy to design a brand identity.</h3>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
 