import React, { useEffect, useRef, useState } from 'react'

export const ThirdSec = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = (index) => setHoveredIndex(index);
  const handleMouseLeave = () => setHoveredIndex(null);

  return (
    <>
    <section className='designThirdSec'>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-6'>
                    <div className={`firstTextSec`}>
                    <h3>
                        Why Choose <span>Mobile App</span> Design Matters
                        </h3>
                        <p>
                        Welcome to Apex Creative Designs, your go-to expert for all things related to mobile app design. In today's fast-paced digital world, having a sleek, functional, and user-friendly mobile app is crucial for engaging your audience and enhancing your business. Our experienced team of designers and developers is here to help you create a mobile app that meets your needs and exceeds your expectations.
                        </p>
                    </div>
                </div>
                <div className='col-lg-6'>
                <div className='firstTextSec'>
                {[
                  {
                    title: "Custom Website Development Services ",
                    text:
                      "Regarding iOS app development, Apex Creative Designs is a leader in the field. Our team of skilled iPhone app developers is dedicated to creating apps that run seamlessly on all Apple devices. From initial concept to final launch, we ensure every aspect of your iOS app is designed to provide an exceptional user experience. Our iOS development process is thorough and meticulous, ensuring that your app looks great and performs flawlessly. As an experienced iOS app development company, we understand the unique challenges and opportunities of the iOS platform. Our custom solutions are created to meet the specific needs of your business, ensuring that your app stands out in the crowded Apple App Store."
                  },
                  {
                    title: "Android App Development Services",
                    text:
                      "In addition to our expertise in iOS, Apex Creative Designs also offers top-notch Android app development services. Our team of Android app developers is proficient in creating apps compatible with various Android devices. We focus on delivering apps that are not only functional but also engaging and user-friendly. As a leading Android app development company, we provide end-to-end services, from design and development to testing and deployment. We aim to create an Android app that enhances your business operations and provides value to your customers."
                  },
                  {
                    title: "Comprehensive Application Development Services",
                    text:
                      "At Apex Creative Designs, we offer a full range of application development services to meet the diverse needs of our clients. Whether you need an app for iOS, Android, or both, our team has the skills and expertise to deliver. We take a holistic approach to app development, ensuring that every aspect of your app is designed to provide a seamless user experience. Our web app developers are also skilled in creating web-based applications that offer the same level of functionality and usability as native mobile apps. Whether you need a mobile app, a web app, or both, Apex Creative Designs has you covered."
                  },
                  {
                    title: "Engaging Mobile App Design",
                    text:
                      "The design of your mobile app plays a crucial role in its success. At Apex Creative Designs, our talented app designers focus on creating visually stunning and highly functional apps that captivate your audience. We believe that great design is more than aesthetics; it's about creating an intuitive user experience that keeps users coming back. As a premier app design agency, we work closely with you to understand your vision and translate it into a beautiful, user-friendly app. Our design process is collaborative and iterative, ensuring that the final product aligns with your goals and exceeds your expectations."
                  }
                ].map((item, index) => (
                  <div
                    className="portfolio-text-div"
                    key={index}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <h4 data-aos="fade-left" data-aos-duration="1000">
                      <b>{item.title}</b>
                    </h4>
                    <p
                      className={`portfolio-text ${
                        hoveredIndex === index ? "show" : ""
                      }`}
                    >
                      {item.text}
                    </p>
                  </div>
                ))}
              
                    </div>
                </div>
            </div>
        </div>
    </section>
  

    {/* Optional styles */}
    <style jsx>{`
      .firstTextSec.fixed {
    position: fixed;
    top: 100px;
    width: 515px;
}
    
.firstTextSec.move-to-top {
    bottom: 65%;
    width: 515px;
    position: fixed;
    top: 100px;
}

    `}</style>
    </>
    
  )
}
