import React from "react";
import { Banner } from '../../WebDeveloping/Banner'
import { SecondSec } from '../../WebDeveloping/SecondSec'
import { ThirdSec } from '../../WebDeveloping/ThirdSec'
import { FourthSec } from '../../WebDeveloping/FourthSec'
import { FifthSec } from '../../WebDeveloping/FifthSec'
import { SixtySec } from '../../WebDeveloping/SixtySec'
import { SeventhSec } from "../../WebDeveloping/SeventhSec";

export const WebDeveloping = () => {
  return (
    <>
      <Banner />
      <SecondSec />
      <ThirdSec />
      <FourthSec />
      <FifthSec />
      <SixtySec />
      <SeventhSec />
    </>
  );
};
