import React, { useEffect, useState } from 'react';

export const Banner = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <>
    	<section id="home-sec1" className='digitalBanner'>
  <div className="container">
    <div className="row align-items-center">
      <div className="col-lg-7 col-12">
        <div className="headings-home" data-aos="fade-left" data-aos-duration="5000">
          <h1>Digital Marketing <br /><b>Services</b></h1>
          <p>
            In today's digital world, having a solid online presence is essential for the success of any business. That's why Apex Creative Designs offers comprehensive digital marketing services designed to help you reach your target audience, grow your brand, and boost your online visibility. Our expert team knows what it takes to create powerful digital marketing strategies that drive results, whether you're a local business or an e-commerce giant.
            <br /><br />
            With over ten years of experience in the field, Apex Creative Designs has become one of the top digital marketing agencies in the UK. We provide bespoke solutions to businesses across various industries. Our services are tailored to meet your specific needs, ensuring you get the best return on your investment. Learn more about our offerings and how we can help your business grow.
          </p>
        </div>
        <div className="buttons">
          <ul>
            <li>
              <a className="open-popup" onClick={openPopup}>Get a quote</a>
            </li>
          </ul>
        </div> 
      </div>
      <div className="col-lg-5 col-12">
        <div className="side-image">
          <img width="100%" src={require('../assets/digitalMarketting.png')} />
        </div>
      </div>
    </div>
  </div>
  {isPopupOpen && (
          <div className="popup-overlay" id="quote-popup">
            <div className="popup-content">
              <span className="close-popup" onClick={closePopup}>&times;</span>
              <div className="row align-items-center">
                <div className="col-lg-7">
                  <div className="popup-form">
                    <h2>Request a Quote</h2>
                    <p>Fill out the form below and we'll get back to you as soon as possible!</p>
                    <form>
                      <input type="text" placeholder="Your Name" required />
                      <input type="email" placeholder="Your Email" required />
                      <textarea placeholder="Your Message" required></textarea>
                      <button type="submit">Send Message</button>
                    </form>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className='animatedPopupImage'>
                    <img src={require('../assets/digitalMarketting.png')} alt='digital marketing' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
</section>

    {/* <section className='digitalBanner'>
    <div className='container'>
        <div className='row align-items-center'>
            <div className='col-lg-12'>
                <h2>
                    <span>
                    Digital Marketing 
                    </span><br/>
                    Services
                </h2>
                <p>
                In today's digital world, having a solid online presence is essential for the success of any business. That's why Apex Creative Designs offers comprehensive digital marketing services designed to help you reach your target audience, grow your brand, and boost your online visibility. Our expert team knows what it takes to create powerful digital marketing strategies that drive results, whether you're a local business or an e-commerce giant.
                <br/>
                <br/>
                With over ten years of experience in the field, Apex Creative Designs has become one of the top digital marketing agencies in the UK. We provide bespoke solutions to businesses across various industries. Our services are tailored to meet your specific needs, ensuring you get the best return on your investment. Learn more about our offerings and how we can help your business grow.
                </p>
            </div>
              
        </div>
    </div>
    </section> */}
    </>
  )
}
