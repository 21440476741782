import React from "react";

export const SixtySec = () => {
  return (
    <>
      <section className="designSixthSec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12"></div>
          </div>
          <div className="row justify-content-center item-content-center gap-5">
            <div className="col-lg-8">
              <div className="partnerApex">
                <h2>
                Why  <br />
                  <span>Apex Creative Design </span> is Your Best Choice
                </h2>
                <p>
                At Apex Creative Design, we are committed to providing top-notch digital mock up services that help your brand shine. Here's why we're the best choice for your packaging design needs:
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="portfolio-text-sec-left" data-aos="fade-right" data-aos-duration="1000">
                  <h4>
                  Expert Team
                  </h4>
                  <p className="animated-text">
                  Our team of designers has years of experience in creating stunning mockup packaging designs. Whether you need a simple food packaging mockup or a complex 3D digital mock up, we have the skills and expertise to bring your vision to life.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6"></div>
              <div className="col-lg-6">
                <div className="portfolio-text-sec-right" data-aos="fade-left" data-aos-duration="1000">
                  <h4>
                   Fast Turnaround Times
                  </h4>
                  <p className="animated-text">
                  We know how important time is in the product development process. That's why we offer quick turnaround times for all our digital mock up services, ensuring that you have your mockups ready when you need them.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="portfolio-text-sec-left" data-aos="fade-right" data-aos-duration="1000">
                  <h4>
                  Attention to Detail  
                  </h4>
                  <p className="animated-text">
                  We understand that every detail matters when it comes to product presentation. Our digital mock ups are designed with care and precision, ensuring that every element of your packaging is accurate and visually appealing.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6"></div>
              <div className="col-lg-6">
                <div className="portfolio-text-sec-right" data-aos="fade-left" data-aos-duration="1000">
                  <h4>
                   Affordable Pricing 
                  </h4>
                  <p className="animated-text">
                  Our digital mock up services are competitively priced, making it easy for businesses of all sizes to access high-quality designs without breaking the bank. Whether you need a one-off mockup packaging design or ongoing food packaging mockup services, we offer flexible pricing options to suit your budget.
                  </p>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </section>
    </>
  );
};
