import React, { useEffect, useRef, useState } from "react";

export const ThirdSec = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = (index) => setHoveredIndex(index);
  const handleMouseLeave = () => setHoveredIndex(null);

  return (
    <>
      <section className="designThirdSec">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div
                className={`firstTextSec `}
              >
                <h3>
                  Why Choose Our Branding and <span>Packaging</span>  Solutions?
                  </h3>
                  <p>
                  We believe in the power of cohesive branding. Our branding and packaging services are designed to create a seamless look and feel across all aspects of your product. By integrating branding elements into the packaging design, we help you establish a strong and memorable brand identity.
                  </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="firstTextSec">
              <div
                  className="portfolio-text-div"
                  data-index="0"
                >
                  <h4>
                    <b>Key Features of Our Packaging Design Services</b> 
                  </h4>
                </div>
              {[
                  {
                    title: "Custom Designs Tailored to Your Brand",
                    text:
                      "Every product is unique, and so should be its packaging. Our packaging design experts create custom designs that align with your brand's identity, ensuring your product stands out."
                  },
                  {
                    title: "Sustainable Packaging Solutions",
                    text:
                      "We prioritize eco-friendly materials and sustainable design practices, offering packaging that not only enhances your product's appeal but also supports a greener planet."
                  },
                  {
                    title: "End-to-End Design Services",
                    text:
                      "From concept development to final production, our team handles every aspect of the branding and packaging design process. We work with you to refine ideas and deliver packaging that complements your product."
                  },
                  {
                    title: "Consumer-Centric Designs",
                    text:
                      "Our packaging designers focus on creating designs that appeal to your target audience. Whether you need sleek, minimalistic packaging or vibrant, bold designs, we ensure that your product's packaging captures attention and drives engagement."
                  }
                ].map((item, index) => (
                  <div
                    className="portfolio-text-div"
                    key={index}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <h4 data-aos="fade-left" data-aos-duration="1000">
                      <b>{item.title}</b>
                    </h4>
                    <p
                      className={`portfolio-text ${
                        hoveredIndex === index ? "show" : ""
                      }`}
                    >
                      {item.text}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Optional styles */}
      <style jsx>{`
        .firstTextSec.fixed {
          position: fixed;
          top: 100px;
          width: 515px;
        }

        .firstTextSec.move-to-top {
          bottom: 65%;
          width: 515px;
          position: fixed;
          top: 100px;
        }
      `}</style>
    </>
  );
};
