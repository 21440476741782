import React, { useEffect } from 'react';
import { FaFacebook, FaInstagram, FaLinkedinIn, FaTwitter } from 'react-icons/fa';
import { Link } from 'react-router-dom';

export const Main = () => {
  useEffect(() => {
    const inputs = document.querySelectorAll('.input');

    function focusFunc() {
      let parent = this.parentNode;
      parent.classList.add('focus');
    }

    function blurFunc() {
      let parent = this.parentNode;
      if (this.value === '') {
        parent.classList.remove('focus');
      }
    }

    inputs.forEach((input) => {
      input.addEventListener('focus', focusFunc);
      input.addEventListener('blur', blurFunc);
    });

    return () => {
      inputs.forEach((input) => {
        input.removeEventListener('focus', focusFunc);
        input.removeEventListener('blur', blurFunc);
      });
    };
  }, []);

  return (
    <>
      <div className="contact-container">
        <span className="big-circle"></span>
        <img src="img/shape.png" className="square" alt="Decorative shape" />
        
        <div className="form">
          <div className="contact-info">
            <h3 className="title">Let's Get in Touch</h3>
            <p className="text">
              We’re here to help you with any questions or concerns. Drop us a message, and we’ll get back to you as soon as possible!
            </p>

            <div className="info">
              <div className="information">
                <i className="fas fa-map-marker-alt"></i>
                <p>A 79, Allenby Road, Lady Margaret road, Southall, Middlesex, UB1 2EZ,
                UNITED KINGDOM</p>
              </div>
              <div className="information">
                <i className="fas fa-envelope"></i>
                <p>info@apexcreativedesigns.co.uk</p>
              </div>
              <div className="information">
                <i className="fas fa-phone"></i>
                <p>+44 (731) 183-0572</p>
              </div>
            </div>

            <div className="social-media">
              <p>Connect with Us:</p>
              <div className="social-icons">
                <a href='https://www.facebook.com/apexcreativedesignsUK' target='_blank'><FaFacebook/></a>
                {/* <a href='https://www.facebook.com/apexcreativedesignsUK' target='_blank'><FaTwitter/></a> */}
                <a href='https://www.instagram.com/apexcreativedesignsuk1' target='_blank'><FaInstagram/></a>
                {/* <a href='https://www.facebook.com/apexcreativedesignsUK' target='_blank'><FaLinkedinIn/></a> */}
                {/* <i className="fab fa-twitter"></i>
                <i className="fab fa-instagram"></i>
                <i className="fab fa-linkedin-in"></i> */}
              </div>
            </div>
          </div>

          <div className="contact-form">
            <span className="circle one"></span>
            <span className="circle two"></span>

            <form action="index.html" autoComplete="off">
              <h3 className="title">Contact Us</h3>

              <div className="input-container">
                <input type="text" name="name" className="input" required />
                <label>Username</label>
                <span>Username</span>
              </div>
              <div className="input-container">
                <input type="email" name="email" className="input" required />
                <label>Email</label>
                <span>Email</span>
              </div>
              <div className="input-container">
                <input type="tel" name="phone" className="input" required />
                <label>Phone</label>
                <span>Phone</span>
              </div>
              <div className="input-container textarea">
                <textarea name="message" className="input" required></textarea>
                <label>Message</label>
                <span>Message</span>
              </div>

              <input type="submit" value="Send" className="btn" />
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
