import React, { useState } from 'react';

export const Banner = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <>
      <section id="home-sec1" className='digitalBanner'>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7 col-12">
              <div className="headings-home" data-aos="fade-left" data-aos-duration="5000">
                
                <h1>
                <span>
                    Custom Book
                </span>
                <br/>
                    <span>Design</span> <b> Services</b>
                </h1>
                <p>
                At Apex Creative Design, book designs are more than just arranging words and images on a page. Our team of professional book designers brings your story to life through visually captivating and functional designs. Whether you're publishing a novel, a coffee table book, or an academic journal, we ensure your design book is not only aesthetically pleasing but also offers a seamless reading experience. Our expertise covers everything from the book cover design ideas to the layout of every book page design.
                <br />
                We understand that designing a book requires attention to detail and creativity. That's why our custom book design services are tailored to meet your specific needs, ensuring your book stands out on any bookshelf design. At Apex Creative Design, we help you create a book that captures the essence of your content while offering a smooth and enjoyable reading experience.
                </p>
              </div>
              <div className="buttons">
                <ul>
                  <li>
                    <a onClick={openPopup} href="#">Get a quote</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-5 col-12">
              <div className="side-image">
                <img width={100} src={require('../assets/BookDesign.png')} alt="Social Media Marketing" />
              </div>
            </div>
          </div>
        </div>

        {isPopupOpen && (
          <div className="popup-overlay" id="quote-popup">
            <div className="popup-content">
              <span className="close-popup" onClick={closePopup}>&times;</span>
              <div className="row align-items-center">
                <div className="col-lg-7">
                  <div className="popup-form">
                    <h2>Request a Quote</h2>
                    <p>Fill out the form below and we'll get back to you as soon as possible!</p>
                    <form>
                      <input type="text" placeholder="Your Name" required />
                      <input type="email" placeholder="Your Email" required />
                      <textarea placeholder="Your Message" required></textarea>
                      <button type="submit">Send Message</button>
                    </form>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className='animatedPopupImage'>
                    <img src={require('../assets/digitalMarketting.png')} alt='digital marketing' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};
