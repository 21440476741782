import React from 'react'
import { Banner } from '../../MockupDesign/Banner'
import { SecondSec } from '../../MockupDesign/SecondSec'
import { ThirdSec } from '../../MockupDesign/ThirdSec'
import { FourthSec } from '../../MockupDesign/FourthSec'
import { FifthSec } from '../../MockupDesign/FifthSec'
import { SixtySec } from '../../MockupDesign/SixtySec'
import { SeventhSec } from "../../MockupDesign/SeventhSec";

export const MockupDesign = () => {
  return (
    <>
    <Banner />
    <SecondSec />
    <ThirdSec />
    {/* <FourthSec /> */}
    <FifthSec />
    <SixtySec />
    <SeventhSec />
  </>
  )
}
