import React from 'react'

export const Banner = () => {
  return (
    <>
    <section className='digitalBanner'>
    <div className='container'>
        <div className='row align-items-center'>
            <div className='col-lg-12'>
                <h2>
                    <span>
                    UX DESIGN
                    </span><br/>
                    Services
                </h2>
                <p>
                Want to deliver exceptional user experiences and stand out in today’s competitive tech world? Apex Creative Design is here to help you craft designs that not only meet but exceed UX design principles. With a talented team of 80+ professionals, we prioritize user experience design services to create memorable, engaging interactions.  
                </p>
            </div>
              
        </div>
    </div>
    </section>
    </>
  )
}
