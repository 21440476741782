import { useEffect } from "react";
import "./Testimonial.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import { Autoplay, Navigation } from 'swiper/modules';
import { Link } from "react-router-dom";
import { FaArrowAltCircleRight } from "react-icons/fa";
import { FaStar } from "react-icons/fa6";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export const Testimonial = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <>
      <section id="testimonials-sections" style={{ paddingTop: "60px", paddingBottom: "60px" }}>
      <div className="heading-text" style={{ textAlign: "center" }}>
        <h2>Testimonials</h2>
        <h3>
          Satisfied <b>Client’s</b>
        </h3>
        <p style={{ color: "#fff" }}>
          Solutions to fit every budget and need - our flexible pricing and plans make it easy for you <br />
          to choose the perfect solution for your brand!
        </p>
      </div>
      <div className="container">
        <div id="page">
          <div className="row">
            <div className="column small-11 small-centered">
              <Slider {...settings}>
                <div className="testimonial-text">
                  <h3>Thomas Clark</h3>
                  <p>
                  My team and I have begun a movement and needed a logo that would not only serve as an icon of what we are doing but also serve as a conversation starter. I described what I wanted to Dwayne, and challenged him and his team to produce what I had visualized. Not only did they deliver, but our new logo exceeded my expectations!
                  </p>
                </div>
                <div className="testimonial-text">
                  <h3>Ge'O-Vanna </h3>
                  <p>
                  This have an awesome and amazing experience for me! Dwayne was very professional in his approach in providing you exactly what you in vision. 5 stars across the board. Apex Creative Design is my go to company!
                  </p>
                </div>
                <div className="testimonial-text">
                  <h3>Kenn (Kenneth) </h3>
                  <p>
                  From day one I’ve worked with Dwayne & he was very helpful and very knowledgeable about all the things that I have done for my website. He was able to create my vision and absolutely brought it to life!! Not to mention he was available literally 24/7 I am so glad I used this company and was able to work with Dwayne. Absolutely worth every penny!! Kenn
                  </p>
                </div>
                <div className="testimonial-text">
                  <h3>Jordan </h3>
                  <p>
                  So where do I start? I was very skeptical about these guys being as they have too good to be true prices and the dreaded facebook scams goin on. I pulled the trigger and got one of the higher tier packages, and let me tell you....I could call my project manager day or night and he listened in detail to bring my ideas to life within hours. Do yourself a favor and use them.
                  </p>
                </div>
                <div className="testimonial-text">
                  <h3>Angie S. (Angie) </h3>
                  <p>
                  Originally I was a bit hesitant to try Apex based on the special they had going as I'm a firm believer in "you get what you pay for" and it being so cheap, I figured that's the quality they would offer. Thank you for all your hard work!
                  </p>
                </div>
                <div className="testimonial-text">
                  <h3>Shon </h3>
                  <p>
                  Very efficient and thorough. My project manager and his team were were steadfast from start to finish. Any questions I had were answered and they were readily available. Also very professional.
                  </p>
                </div>
                <div className="testimonial-text">
                  <h3>Clarissa (Claire)  </h3>
                  <p>
                  I am so happy with Apex Creative Designs. They have built such a beautiful website for me. They are knowledgeable and talented. Everyone is nice and always available and willing to help.  Thank you for a great experience and a wonderful website. 👍
                  </p>
                </div>
                <div className="testimonial-text">
                  <h3>Teresa Harris </h3>
                  <p>
                  Dwayne & his team worked hard & did not stop until I was 100% satisfied. The kindness, timely, superior customer service was amazing. I totally appreciate the professionalism with all my detailed multiple requests w/ creating my logo. Thank you, T.H.
                  </p>
                </div>
                <div className="testimonial-text">
                  <h3>Leonedi Rodriguez </h3>
                  <p>
                  I liked the company for the follow-up they give to the work, they are always attentive. And they do what they propose. They are high quality and I would always work with them again.
                  </p>
                </div>
                <div className="testimonial-text">
                  <h3>Joy Alozie </h3>
                  <p>
                  I was skeptical throughout the process, but Danny made it easy. The end product was amazingly beautiful. 100% recommend.
                  </p>
                </div>
              </Slider>
              <div className="trhstpiot">
                {/* <a target="_blank" rel="noopener noreferrer" href="https://apexcreativedesigns.com/assets/clogo5.webp"> */}
                  <img src={require('../assets/clogo5.webp')} alt="TrustPilot Logo" />
                {/* </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  );
};