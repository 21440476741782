import React, { useEffect, useRef, useState } from 'react'

export const ThirdSec = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = (index) => setHoveredIndex(index);
  const handleMouseLeave = () => setHoveredIndex(null);

  return (
    <>
    <section className='designThirdSec'>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-6'>
                    <div className={`firstTextSec`}>
                        <h3>
                        Discover Our <span>Application Development </span> Capabilities
                        </h3>
                        <p>
                        At Apex Creative Design, we provide comprehensive custom web application development services that ensure your digital platform stands out. From planning to deployment, we focus on delivering customized solutions that meet your business needs while enhancing user experience.
                        </p>
                    </div>
                </div>
                <div className='col-lg-6'>
                <div className='firstTextSec'>
                {[
                  {
                    title: "MVP Development",
                    text:
                      "As an experienced MVP development company, we help startups and businesses bring their ideas to life quickly and efficiently. Our MVP development services focus on building a functional prototype that can be tested and refined, allowing you to launch your product faster and with reduced risk."
                  },
                  {
                    title: "E-commerce Development",
                    text:
                      "We specialize in creating custom ecommerce development solutions that provide seamless online shopping experiences. Our team builds scalable e-commerce platforms with features like secure payment gateways, inventory management, and user-friendly interfaces to boost customer engagement and sales."
                  },
                  {
                    title: "Custom CMS Development",
                    text:
                      "A powerful content management system is essential for managing and updating your website efficiently. Our CMS developer team creates custom CMS platforms tailored to your business needs, giving you full control over your content while ensuring a user-friendly experience."
                  },
                  {
                    title: "Front-End and Back-End Integration",
                    text:
                      "We offer complete front-end and back-end integration, ensuring your application is not only visually appealing but also functions seamlessly. Our development team uses the latest technologies to deliver an intuitive, responsive front end paired with a robust, efficient back end."
                  },
                  {
                    title: "Scalable Solutions for Future Growth",
                    text:
                      "As your business evolves, your application should too. We build applications that are easily scalable, allowing you to add new features and functionalities as your business grows without compromising performance."
                  }
                ].map((item, index) => (
                  <div
                    className="portfolio-text-div"
                    key={index}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <h4 data-aos="fade-left" data-aos-duration="1000">
                      <b>{item.title}</b>
                    </h4>
                    <p
                      className={`portfolio-text ${
                        hoveredIndex === index ? "show" : ""
                      }`}
                    >
                      {item.text}
                    </p>
                  </div>
                ))}
                        
                    </div>
                </div>
            </div>
        </div>
    </section>
   

    {/* Optional styles */}
    <style jsx>{`
      .firstTextSec.fixed {
    position: fixed;
    top: 100px;
    width: 515px;
}
    
.firstTextSec.move-to-top {
    bottom: 65%;
    width: 515px;
    position: fixed;
    top: 100px;
}

    `}</style>
    </>
    
  )
}
