import React, { useEffect, useRef, useState } from 'react'

export const ThirdSec = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = (index) => setHoveredIndex(index);
  const handleMouseLeave = () => setHoveredIndex(null);
  return (
    <>
    <section className='designThirdSec'>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-6'>
                    <div className={`firstTextSec`}>
                    <h3>
                        What Are <span>Professional SEO</span> Services?
                        </h3>
                        <p>
                        Professional SEO services focus on optimizing your website to make it more search engine-friendly. This includes optimizing content, improving site structure, building high-quality backlinks, and implementing strategies that align with search engine algorithms. At Apex Creative Designs, we use proven techniques to enhance your site's visibility for the keywords and phrases that are most relevant to your business. This allows you to reach your target audience and convert leads into loyal customers.
                        </p>
                    </div>
                </div>
                <div className='col-lg-6'>
                <div className='firstTextSec'>
                {[
                  {
                    title: "Our Range of SEO Services",
                    text:
                      "At Apex Creative Designs, we offer a variety of SEO services to meet your specific needs. Our solutions are designed to boost your search rankings, drive traffic, and increase conversions."
                  },
                  {
                    title: "SEO Audit Services",
                    text:
                      "Before we begin any SEO campaign, we perform a thorough SEO audit of your website. This involves analyzing your site's structure, content, backlink profile, and overall performance. Our SEO audit services identify areas for improvement, such as broken links, duplicate content, slow loading times, and weak keywords. We then provide a detailed report outlining the steps to improve your site's optimization and achieve higher rankings."
                  },
                  {
                    title: "SEO Consultant Services",
                    text:
                      "If you're looking for expert guidance on improving your website's SEO, our SEO consultant services are here to help. We provide one-on-one consultations with experienced SEO professionals who can evaluate your site and offer actionable recommendations. Whether you need help with on-page optimization, keyword research, or content strategy, our SEO consultants will work closely with you to develop a custom plan that aligns with your business goals."
                  },
                  {
                    title: "White Label SEO Services UK",
                    text:
                      "If you're an agency looking to offer SEO services to your clients but lack in-house expertise, our white label SEO services UK are the perfect solution. Apex Creative Designs provides white-label SEO solutions that allow you to resell our SEO services under your brand. We handle all the work, from keyword research and content optimization to reporting and link building, while you take the credit. With our white label SEO services, you can expand your service offerings and grow your business without the added overhead."
                  },
                  {
                    title: "SEO for Local Businesses in the UK",
                    text:
                      "For small businesses and startups, ranking locally can make all the difference in reaching customers nearby and ready to make a purchase. Our local SEO services UK focus on helping you rank for geo-targeted keywords so your business appears in local searches. Whether optimizing your Google My Business listing or improving your local citations, Apex Creative Designs ensures that your business stands out in local search results."
                  }
                ].map((item, index) => (
                  <div
                    className="portfolio-text-div"
                    key={index}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <h4 data-aos="fade-left" data-aos-duration="1000">
                      <b>{item.title}</b>
                    </h4>
                    <p
                      className={`portfolio-text ${
                        hoveredIndex === index ? "show" : ""
                      }`}
                    >
                      {item.text}
                    </p>
                  </div>
                ))}
                    </div>
                </div>
            </div>
        </div>
    </section>
 

    {/* Optional styles */}
    <style jsx>{`
      .firstTextSec.fixed {
    position: fixed;
    top: 100px;
    width: 515px;
}
    
.firstTextSec.move-to-top {
    bottom: 65%;
    width: 515px;
    position: fixed;
    top: 100px;
}

    `}</style>
    </>
    
  )
}
