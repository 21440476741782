import React from 'react'

export const SixtySec = () => {
  return (
    <>
    <section className='designseventhSec'>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-12'>
                    <div className='partnerApex'>
                            <h2>
                            <span>Partner With </span><br/>
                            Apex Creative Designs
                            </h2>
                            <p>
                            If you're looking for a trusted partner to help you achieve your advertising goals, Apex Creative Designs is here to help. Our comprehensive pay per click services UK are designed to drive traffic, generate leads, and boost your bottom line. Whether you're doing PPC or looking to optimize your existing campaigns, our team has the expertise to deliver the results you need.
                            <br/>
                            <br/>
                            <span>Contact us today</span> to learn more about how our PPC services can help your business grow. With our expert team by your side, you'll have the confidence that your ads are always reaching the right audience at the right time. Let's work together to create a PPC strategy that drives success for your business.
                            </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
