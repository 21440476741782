import React from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

export const FifthSec = () => {
    var settings = {
        // dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        arrows: true,
        className: "myCustomCarousel"
      };
  return (
    <>
    <section className='materialDigital' style={{background:'#232323'}}>
        <div className='container'>
        <div className='row'>
                    <div className='col-lg-12'>
                        <div className='firstTextSecght'>
                        <h3 data-aos="fade-left" data-aos-duration="1000">
                        <span>Trusted Social Media Marketing Company in the UK</span>
                        </h3>
                        <p data-aos="fade-up" data-aos-duration="1000">
                        As a leading social media marketing company UK, Apex Creative Designs is committed to helping businesses like yours thrive in the digital space. Our proven track record and client satisfaction speak to the quality of our services. We offer everything from organic social media management to paid advertising strategies, ensuring that you have the support you need to grow your presence across multiple platforms.
                        </p>
                        </div>
                    </div>
                </div>
        <div className='row align-items-center'>
                <div className='col-lg-8'>
                   <div className='colEight'>
                   <h3 data-aos="fade-down" data-aos-duration="1000">
                    <span>Digital Agency Services for Every </span>Business
                    </h3>
                    <p data-aos="fade-up" data-aos-duration="1000">
                    At Apex Creative Designs, we don’t just focus on social media. We offer a range of digital agency services designed to help your business grow. From website design and development to SEO and email marketing, we provide a full suite of services that complement your social media efforts. Whether you need a complete digital overhaul or specific social marketing strategies, our team of experts is here to help.
                    </p>
                   </div>
                </div>
                <div className='col-lg-4'>
                    <img style={{objectFit:'contain', width:'100%', animation:'afterUpDown 2s ease-in-out infinite'}} src={require('../assets/degitalAgencyService.png')} />
                </div>
                <div className='col-lg-8'>
                   <div className='colEight'>
                   <h3 data-aos="fade-down" data-aos-duration="1000">
                   Social Media <span>Marketing Management Services</span>
                    </h3>
                    <p data-aos="fade-up" data-aos-duration="1000">
                    Our social media marketing management services are designed to take the hassle out of running your social media accounts. We manage everything from scheduling posts to engaging with your audience, allowing you to focus on running your business. By consistently sharing relevant content, engaging with followers, and monitoring trends, we help your brand stay connected and top-of-mind for your audience.
                    </p>
                   </div>
                </div>
               <div className='col-lg-4'>
                        <img style={{objectFit:'contain', width:'100%', animation:'afterUpDown 2s ease-in-out infinite'}} src={require('../assets/digitalAgency.png')} />    
               </div>
            </div>
            <div className='row workwithuk'>
            <div className='col-lg-8'>
                   <div className='colEight'>
                   <h3 data-aos="fade-down" data-aos-duration="1000">
                   Work with <span>Apex Creative Designs </span>
                    </h3>
                    <p data-aos="fade-up" data-aos-duration="1000">
                    Why choose Apex Creative Designs for your social media marketing needs? Here are just a few reasons why we’re one of the top social media marketing companies UK:
                    </p>
                   </div>
                </div>
            <Slider {...settings}>
            <div>
            <div className='box-digital' data-aos="fade-up" data-aos-duration="1000">
                        <img src={require('../assets/tailored.png')} />
                        <h4>
                        TAILORED STRATEGIES
                        </h4>
                        <p>
                        We create personalized social media strategies based on your business’s goals and audience.
                        </p>
                    </div>
                    </div>
                   <div>
                   <div className='box-digital' data-aos="fade-up" data-aos-duration="1000">
                        <img src={require('../assets/expert.png')} />
                        <h4>
                        EXPERT TEAM
                        </h4>
                        <p>
                        Our team has years of experience in social media marketing and content creation.
                        </p>
                    </div>
                    </div>
                    <div>
                    <div className='box-digital' data-aos="fade-up" data-aos-duration="1000">
                        <img src={require('../assets/result.png')} />
                        <h4>
                        RESULT-DRIVEN
                        </h4>
                        <p>
                        Our focus is on driving real, measurable results that align with your business objectives.
                        </p>
                    </div>
                   
                    </div>
                    <div>
                    <div className='box-digital' data-aos="fade-up" data-aos-duration="1000">
                        <img src={require('../assets/customerFullService.png')} />
                        <h4>
                        FULL SERVICE AGENCY
                        </h4>
                        <p>
                        From strategy and content creation to paid advertising and analytics, we offer everything you need to succeed in social media.
                        </p>
                    </div>
                    </div>
            </Slider>
            </div>
            
        </div>
    </section>
    </>
  )
}
