import React from 'react'
import { Banner } from '../../WebsiteDevelopment/Banner'
import { SecondSec } from '../../WebsiteDevelopment/SecondSec'
import { ThirdSec } from '../../WebsiteDevelopment/ThirdSec'
import { FourthSec } from '../../WebsiteDevelopment/FourthSec'
import { FifthSec } from '../../WebsiteDevelopment/FifthSec'
import { SixtySec } from '../../WebsiteDevelopment/SixtySec'

export const WebsiteDevelopment = () => {
  return (
    <>
    <Banner/>
    <SecondSec/>
    <ThirdSec/>
    {/* <FourthSec/> */}
    <FifthSec/>
    <SixtySec/>
    </>
  )
}
