import React from 'react'
import { Main } from '../PortfolioComponent/Main'
import { SecondSec } from '../PortfolioComponent/SecondSec'
import { Third } from '../PortfolioComponent/Third'

export const Portfollio = () => {
  return (
    <>
    <section id="portfolio" className="portfolio-area py-4">
    <Main/>
    <SecondSec/>
    <Third/>
    </section>
    </>
  )
}
