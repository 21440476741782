import React, { useEffect, useRef, useState } from "react";

export const ThirdSec = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = (index) => setHoveredIndex(index);
  const handleMouseLeave = () => setHoveredIndex(null);

  return (
    <>
      <section className="designThirdSec">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div
                className={`firstTextSec`}
              >
                <h3>
                  Why Choose Animated <span>Logos?</span>
                  </h3>
                  <p>
                  An animated logo takes your branding to the next level, making it memorable and engaging. Imagine your fashion brand logos coming to life with subtle motion or your Halloween logo animation adding a playful twist during the spooky season. These elements not only grab attention but also convey professionalism and creativity, which are essential for brands looking to connect with modern consumers.
                  </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="firstTextSec">
              <div
                  className="portfolio-text-div"
                  data-index="0"
                >
                  <h4>
                    <b>Comprehensive UI Design Capabilities</b>
                    
                  </h4>
                </div>
              {[
                  {
                    title: "Customized Animated Logo Design",
                    text:
                      "Every business is unique, and so should its logo. Our designers create custom animated logos that align perfectly with your brand's message, from luxury brand logos for high-end businesses to restaurant logos that leave customers craving more."
                  },
                  {
                    title: "Seasonal Animated Logos",
                    text:
                      "Seasonal promotions deserve extra flair! Whether it's a festive Christmas logo or a spooky Halloween logo, we can animate your logo to match any occasion, adding a touch of magic to your brand's seasonal campaigns."
                  },
                  {
                    title: "Monochrome Animated Logos",
                    text:
                      "We offer black and white logo animations for brands seeking simplicity and elegance. These are perfect for a modern logo design that remains timeless and versatile, fitting seamlessly into any digital or print medium."
                  },
                  {
                    title: "Font Exploration for Animated Logos",
                    text:
                      "Choosing the right font is crucial for any logo design. We explore various font ideas to ensure your animated logo maintains readability while expressing your brand's unique identity. Whether you're aiming for bold, creative, or minimalist fonts, we've got the expertise to make it happen."
                  },
                  {
                    title: "Luxury and Fashion Brand Logos",
                    text:
                      "For high-end brands, we create luxury brand logos and fashion brand logos with sophisticated animations that reflect prestige and exclusivity. These logos add a class to your branding, enhancing your brand's appeal in the luxury market."
                  }
                ].map((item, index) => (
                  <div
                    className="portfolio-text-div"
                    key={index}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <h4 data-aos="fade-left" data-aos-duration="1000">
                      <b>{item.title}</b>
                    </h4>
                    <p
                      className={`portfolio-text ${
                        hoveredIndex === index ? "show" : ""
                      }`}
                    >
                      {item.text}
                    </p>
                  </div>
                ))}
                {/* <div
                  className="portfolio-text-div"
                  data-index="0"
                >
                  <h4>
                    <b>Comprehensive UI Design Capabilities</b>
                    <br />
                    Customized Animated Logo Design
                  </h4>
                  <p>
                  Every business is unique, and so should its logo. Our designers create custom animated logos that align perfectly with your brand's message, from luxury brand logos for high-end businesses to restaurant logos that leave customers craving more.
                  </p>
                </div>
                <div
                  className="portfolio-text-div"
                  data-index="1"
                  ref={(el) => (textSections.current[1] = el)}
                >
                  <h4>Seasonal Animated Logos</h4>
                  <p>
                  Seasonal promotions deserve extra flair! Whether it's a festive Christmas logo or a spooky Halloween logo, we can animate your logo to match any occasion, adding a touch of magic to your brand's seasonal campaigns.
                  </p>
                </div>
                <div
                  className="portfolio-text-div"
                  data-index="2"
                  ref={(el) => (textSections.current[2] = el)}
                >
                  <h4>Monochrome Animated Logos</h4>
                  <p>
                  We offer black and white logo animations for brands seeking simplicity and elegance. These are perfect for a modern logo design that remains timeless and versatile, fitting seamlessly into any digital or print medium.
                  </p>
                </div>
                <div
                  className="portfolio-text-div"
                  data-index="3"
                  ref={(el) => (textSections.current[3] = el)}
                >
                  <h4>Font Exploration for Animated Logos</h4>
                  <p>
                  Choosing the right font is crucial for any logo design. We explore various font ideas to ensure your animated logo maintains readability while expressing your brand's unique identity. Whether you're aiming for bold, creative, or minimalist fonts, we've got the expertise to make it happen.
                  </p>
                </div>
                <div
                  className="portfolio-text-div"
                  data-index="4"
                  ref={(el) => (textSections.current[4] = el)}
                >
                  <h4>Luxury and Fashion Brand Logos</h4>
                  <p>
                  For high-end brands, we create luxury brand logos and fashion brand logos with sophisticated animations that reflect prestige and exclusivity. These logos add a class to your branding, enhancing your brand's appeal in the luxury market.
                  </p>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Optional styles */}
      <style jsx>{`
        .firstTextSec.fixed {
          position: fixed;
          top: 100px;
          width: 515px;
        }

        .firstTextSec.move-to-top {
          bottom: 65%;
          width: 515px;
          position: fixed;
          top: 100px;
        }
      `}</style>
    </>
  );
};
