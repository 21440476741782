import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

export const FifthSec = () => {
  var settings = {
    // dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: true,
    className: "myCustomCarousel",
  };
  return (
    <>
      <section className="materialDigital uimaterialDigital">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="colEight">
                <h3>
                  <span>Why </span> UI Design <span>Matters</span>
                </h3>
                <p>
                  The utility of user interface (UI) design lies in its ability
                  to enhance usability, accessibility, and aesthetics. A
                  well-designed UI ensures that users can easily navigate your
                  digital products, whether it's a website, mobile app, or web
                  application. This not only reinforces your brand but also
                  significantly boosts user satisfaction.
                </p>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="colEight">
                <h3>
                  <span>Our Approach to </span> UI Design
                </h3>
                <p>
                  Our process begins with understanding your brand, goals, and
                  user needs. We focus on creating interfaces that are:
                  <br />
                  <br />
                  <br />
                  <span>Intuitive:</span> Easy to navigate, ensuring users can
                  accomplish their goals with minimal effort.
                  <br />
                  <span>Engaging:</span> Visually appealing designs that capture
                  attention and keep users engaged.
                  <br />
                  <span>Consistent:</span> Cohesive design elements that
                  reinforce your brand identity across all platforms.
                </p>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="colEight">
                <h3>
                  Services <span> We Offer</span>
                </h3>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-2">
                <img
                  width={150}
                  src={require("../assets/design-thinking_12471768.png")}
                />
              </div>
              <div className="col-lg-10">
                <div className="colEight">
                  <h3>CUSTOM UI SOLUTIONS</h3>
                  <p>
                    We tailor our designs to fit your specific needs, whether
                    you require a bespoke solution for your business website or
                    a unique interface for a mobile app. Our custom UI solutions
                    ensure that your digital product stands out and meets your
                    users' expectations.
                  </p>
                </div>
              </div>
            </div>
            <div className="div-border-line"></div>
            <div className="row align-items-center">
              <div className="col-lg-2">
                <img
                  width={150}
                  src={require("../assets/lesson_3527412.png")}
                />
              </div>
              <div className="col-lg-10">
                <div className="colEight">
                  <h3>Web Application Design</h3>
                  <p>
                    For web applications, a well-thought-out UI is crucial. Our
                    team excels in designing complex interfaces for web apps,
                    ensuring that every user interaction is smooth and
                    efficient. We integrate user flows that enhance usability
                    and make your web application user-friendly and accessible.
                  </p>
                </div>
              </div>
            </div>
            <div className="div-border-line"></div>
            <div className="row align-items-center">
              <div className="col-lg-2">
                <img
                  width={150}
                  src={require("../assets/smartphone_1563934.png")}
                />
              </div>
              <div className="col-lg-10">
                <div className="colEight">
                  <h3>Mobile App UI Design</h3>
                  <p>
                    For web applications, a well-thought-out UI is crucial. Our
                    team excels in designing complex interfaces for web apps,
                    ensuring that every user interaction is smooth and
                    efficient. We integrate user flows that enhance usability
                    and make your web application user-friendly and accessible.
                  </p>
                </div>
              </div>
            </div>
            <div className="div-border-line"></div>
            <div className="row align-items-center">
              <div className="col-lg-2">
                <img width={150} src={require("../assets/ai_1319136.png")} />
              </div>
              <div className="col-lg-10">
                <div className="colEight">
                  <h3>AI Interface Design</h3>
                  <p>
                    In the evolving world of AI, we design interfaces that make
                    interacting with AI technologies straightforward and
                    intuitive. Our AI interface designs prioritize user
                    experience, making advanced technologies accessible to all
                    users.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-4">
              <h3>
                <span>Benefits of Our</span> UI Design <span>Services</span>
              </h3>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-lg-4">
              <div className="ui-fifthSec-btm-sec-div-1">
                <h6>Enhanced Efficiency</h6>
                <p>
                  Our designs streamline user interactions, shortening learning
                  curves and improving efficiency.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="ui-fifthSec-btm-sec-div-2">
                <h6>Increased Conversions</h6>
                <p>
                  By creating intuitive and engaging interfaces, we help
                  increase conversions and ensure a successful product or
                  service experience.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="ui-fifthSec-btm-sec-div-3">
                <h6>Reinforced Branding</h6>
                <p>
                  Consistent and visually appealing UI designs strengthen your
                  brand identity and leave a lasting impression on users.
                </p>
              </div>
            </div>
          </div> */}
          <div className="ui-fifthSec-btm-sec">
            <div className="ui-fifthSec-btm-sec-div-1">
              <h6>Enhanced Efficiency</h6>
              <p>
                Our designs streamline user interactions, shortening learning
                curves and improving efficiency.
              </p>
            </div>
            <div className="ui-fifthSec-btm-sec-div-2">
              <h6>Increased Conversions</h6>
              <p>
                By creating intuitive and engaging interfaces, we help increase
                conversions and ensure a successful product or service
                experience.
              </p>
            </div>
            <div className="ui-fifthSec-btm-sec-div-3">
              <h6>Reinforced Branding</h6>
              <p>
                Consistent and visually appealing UI designs strengthen your
                brand identity and leave a lasting impression on users.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
