import React from "react";

export const FourthSec = () => {
  return (
    <>
      <section className="designThirdSec text-center">
        <div className="container">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="firstTextSecght">
                  <h3>
                    <span>
                      Pros and Cons of User <br />
                      Interface{" "}
                    </span>{" "}
                    (UI) Design
                  </h3>
                  <p>
                    User Interface (UI) design plays a crucial role in shaping
                    how users interact with digital products. A well-designed UI
                    enhances user satisfaction, while a poorly designed one can
                    frustrate users and harm your brand. Here’s a breakdown of
                    the pros and cons of UI design:
                  </p>
                </div>
              </div>
            </div>
            <div className="uiForthSec-btm">
                <div className="row">
                <div className="col-lg-6">
                    <div className="uiForthSec-btm-lft">
                    <h5>
                        <b>Pros</b> of User Interface Design
                    </h5>
                    <ul>
                        <li>Enhanced User Experience</li>
                        <li>Increased User Engagement</li>
                        <li>Improved Usability </li>
                        <li>Brand Consistency  </li>
                        <li>Higher Conversion Rates</li>
                        <li>Accessibility</li>
                        <li>Competitive Advantage</li>
                    </ul>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="uiForthSec-btm-rgt">
                    <h5>
                        <b>CONS</b> of User Interface Design
                    </h5>
                    <ul>
                        <li>Time-Consuming Development </li>
                        <li>High Costs</li>
                        <li>Risk of Overcomplication </li>
                        <li>Requires Frequent Updates </li>
                        <li>Balancing Aesthetics and Functionality</li>
                        <li>Subject to User Preferences</li>
                        <li>Technical Constraints</li>
                    </ul>
                    </div>
                </div>
                </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
