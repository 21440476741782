import React, { useEffect, useRef, useState } from "react";

export const ThirdSec = () => {
  const [isImageFixed, setIsImageFixed] = useState(false);
  const [isInFifthSection, setIsInFifthSection] = useState(false);

  const portfolioRef = useRef(null);
  const textSections = useRef([]);

  useEffect(() => {
    const options = {
      root: null,
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const index = entry.target.getAttribute("data-index");

        if (entry.isIntersecting) {
          switch (parseInt(index)) {
            case 0:
              return (
                <>
                  <h3>
                  Business <span>Stationery Design</span>  that Makes a Statement
                  </h3>
                  <p>
                  We understand that first impressions matter. Our business stationery design services ensure that your stationery communicates professionalism and attention to detail. Whether you need custom logo stationery or a full range of business stationery solutions, we design products that stand out and leave a lasting impact on your clients.
                  </p>
                </>
              );
              break;
            case 1:
              return (
                <>
                  <h3>
                  Business <span>Stationery Design</span>  that Makes a Statement
                  </h3>
                  <p>
                  We understand that first impressions matter. Our business stationery design services ensure that your stationery communicates professionalism and attention to detail. Whether you need custom logo stationery or a full range of business stationery solutions, we design products that stand out and leave a lasting impact on your clients.
                  </p>
                </>
              );
              break;
            case 2:
              return (
                <>
                  <h3>
                  Business <span>Stationery Design</span>  that Makes a Statement
                  </h3>
                  <p>
                  We understand that first impressions matter. Our business stationery design services ensure that your stationery communicates professionalism and attention to detail. Whether you need custom logo stationery or a full range of business stationery solutions, we design products that stand out and leave a lasting impact on your clients.
                  </p>
                </>
              );
              break;

            case 3:
              setIsInFifthSection(true); // When the fifth section is visible
              break;
            default:
              break;
          }
        } else {
          if (parseInt(index) === 3) {
            setIsInFifthSection(false); // Reset when leaving fifth section
          }
        }
      });
    }, options);

    textSections.current.forEach((section) => {
      if (section) observer.observe(section);
    });

    return () => {
      textSections.current.forEach((section) => {
        if (section) observer.unobserve(section);
      });
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (portfolioRef.current) {
        const rect = portfolioRef.current.getBoundingClientRect();
        setIsImageFixed(rect.top <= 0 && rect.bottom > window.innerHeight);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <section className="designThirdSec"  >
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div
                className={`firstTextSec    `}
              >
                <h3>
                  Business <span>Stationery Design</span>  that Makes a Statement
                  </h3>
                  <p>
                  We understand that first impressions matter. Our business stationery design services ensure that your stationery communicates professionalism and attention to detail. Whether you need custom logo stationery or a full range of business stationery solutions, we design products that stand out and leave a lasting impact on your clients.
                  </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="firstTextSec">
                <div
                  className="portfolio-text-div"
                  data-index="0"
                  ref={(el) => (textSections.current[0] = el)}
                >
                  <h4>
                    <b>Key Features of Our Stationery Design Services</b> 
                    <br/>
                    <br/>
                    Custom Stationery Designed for Your Brand
                  </h4>
                  <p>
                  Our stationery designer team specializes in creating bespoke business stationery that aligns with your brand identity. From sleek and modern designs to more traditional styles, we tailor each design to reflect your business's personality.
                  </p>
                </div>
                <div
                  className="portfolio-text-div"
                  data-index="1"
                  ref={(el) => (textSections.current[1] = el)}
                >
                  <h4>High-Quality Materials</h4>
                  <p>
                  We use only premium-quality materials to ensure your small business stationery is as durable as it is visually stunning. Whether you need letterheads, envelopes, or business cards, we provide designs that are both functional and attractive.
                  </p>
                </div>
                <div
                  className="portfolio-text-div"
                  data-index="2"
                  ref={(el) => (textSections.current[2] = el)}
                >
                  <h4>End-to-End Design Services</h4>
                  <p>
                  From initial concept development to the final product, we manage the entire process of your business stationery design. Our goal is to deliver elegant, cohesive stationery that elevates your brand's professional image.
                  </p>
                </div>
                <div
                  className="portfolio-text-div"
                  data-index="3"
                  ref={(el) => (textSections.current[3] = el)}
                >
                  <h4>Versatile and Functional Designs</h4>
                  <p>
                  Whether you're running a large enterprise or looking for small business stationery UK solutions, our designs cater to a variety of business needs. We create stationery that's not just beautiful but also practical, helping you maintain consistency in all your communication materials.
                  </p>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Optional styles */}
      <style jsx>{`
        .firstTextSec.fixed {
          position: fixed;
          top: 100px;
          width: 515px;
        }

        .firstTextSec.move-to-top {
          bottom: 65%;
          width: 515px;
          position: fixed;
          top: 100px;
        }
      `}</style>
    </>
  );
};
