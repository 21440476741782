import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

export const FifthSec = () => {
  var settings = {
    // dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: true,
    className: "myCustomCarousel",
  };
  return (
    <>
      <section className="materialDigital" style={{background:'#232323'}}>
        <div className="container">
          {/* <div className="row">
          <div className="col-lg-12">
                <div className="firstTextSecght">
                  <h3>
                    <span>What to Expect from</span> <br />Digital Branding
                  </h3>
                  <p>
                  At Apex Creative Design, our Digital Branding Services are designed to help your business grow and stand out in today’s competitive digital landscape. Here’s what you can expect when you choose to elevate your brand with us.
                  </p>
                </div>
              </div>
              </div> */}
          <div className="row workwithuk">
            <div className="col-lg-8">
              <div className="colEight">
                <h3>
                Why Choose Apex Creative <br />
                Design for Your <span> Book? </span>
                </h3>
                <p>
                At Apex Creative Design, we don't just design books; we help bring your vision to life. Here's what you can expect from our book design services:
                </p>
              </div>
            </div>
            <Slider {...settings}>
                <div>
                  <div className="box-digital">
                    <img src={require("../assets/discovery_176212.png")} />
                    <h4>Unique Book Cover Design Ideas</h4>
                    <p>
                    We brainstorm book cover design ideas tailored to your genre and audience, ensuring your book grabs attention on the shelf or online.
                    </p>
                  </div>
                </div>
                <div>
                  <div className="box-digital">
                    <img src={require("../assets/graphic-design_10448097.png")} />
                    <h4>High-Quality Book Page Design</h4>
                    <p>
                    Our team delivers a polished and professional book page design that enhances readability and maintains the flow of your content from chapter to chapter.
                    </p>
                  </div>
                </div>
                <div>
                  <div className="box-digital">
                    <img src={require("../assets/coding_2010990.png")} />
                    <h4>Professional and Creative Designers</h4>
                    <p>
                    With years of experience in designing a book, our designers know how to balance creativity with function, delivering a finished product that reflects your book's message.
                    </p>
                  </div>
                </div>
                
              </Slider>
          
          </div>
        </div>
      </section>
    </>
  );
};
