import { useState, useEffect, useRef } from "react";
import portfolio1 from "../assets/portfolio-1.png";
import portfolio2 from "../assets/portfolio-2.png";
import portfolio3 from "../assets/portfolio-3.png";
import portfolio4 from "../assets/portfolio-4.png";
import portfolio5 from "../assets/portfolio-5.png";
import { IoMdStar } from "react-icons/io";
import './Portfolio.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Portfolio = () => {
  const [currentImage, setCurrentImage] = useState(portfolio1);
  const [currentAOSAnimation, setCurrentAOSAnimation] = useState('fade-up');
  const [isImageFixed, setIsImageFixed] = useState(false);
  const [isInFifthSection, setIsInFifthSection] = useState(false);
  
  const portfolioRef = useRef(null);
  const textSections = useRef([]);

  useEffect(() => {
    AOS.init({ duration: 2000 }); // Initialize AOS with a longer duration for smoother effects
    return () => AOS.refresh();
  }, [currentImage]);

  useEffect(() => {
    const options = {
      root: null,
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const index = entry.target.getAttribute("data-index");
        if (entry.isIntersecting) {
          setCurrentAOSAnimation(''); // Reset animation to trigger it again
          setTimeout(() => {
            setCurrentAOSAnimation('fade-up'); // Set the animation
            switch (parseInt(index)) {
              case 0:
                setCurrentImage(portfolio1);
                break;
              case 1:
                setCurrentImage(portfolio2);
                break;
              case 2:
                setCurrentImage(portfolio3);
                break;
              case 3:
                setCurrentImage(portfolio4);
                break;
              case 4:
                setCurrentImage(portfolio5);
                setIsInFifthSection(true); // When the fifth section is visible
                break;
              default:
                break;
            }
          }, 50); // A slight delay to ensure the animation triggers on update
        } else {
          if (parseInt(index) === 4) {
            setIsInFifthSection(false); // Reset when leaving fifth section
          }
        }
      });
    }, options);

    textSections.current.forEach((section) => {
      if (section) observer.observe(section);
    });

    return () => {
      textSections.current.forEach((section) => {
        if (section) observer.unobserve(section);
      });
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (portfolioRef.current) {
        const rect = portfolioRef.current.getBoundingClientRect();
        setIsImageFixed(rect.top <= 0 && rect.bottom > window.innerHeight);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
     <div className="portfolio-sec-main-div" ref={portfolioRef}>
        <section className="portfolio-sec">
          <div className="container">
            <div className="row justify-content-center item-content-center">
              <div className="col-lg-7">
                <div 
                  className={`portfolio-img ${isImageFixed ? "fixed" : ""} ${isInFifthSection ? "move-to-top" : ""}`}
                  data-aos={currentAOSAnimation}
                >
                <img src={currentImage} alt="Portfolio" />
              </div>
            </div>
            <div className="col-lg-5">
              <div className="portfolio-text-sec1">
                <div
                  className="portfolio-text-div"
                  data-index="0"
                  ref={(el) => (textSections.current[0] = el)}
                   data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="3000"
                >
                  <h5>Opening Heart Digital Gateway</h5>
                  <h3>Mobile Application</h3>
                  <p>
                    Best creative design agencies for innovative, tailored solutions that elevate your brand and drive engagement.
                  </p>
                  <div className="box-div">
                    <h4>Isatou Senghore</h4>
                    <p>
                      "Apex Creative Design did an outstanding job with my app. The final product is both innovative and reliable, with a smooth user experience."
                    </p>
                    <div className="star-icon-div">
                      <IoMdStar className="star-icon" />
                      <IoMdStar className="star-icon" />
                      <IoMdStar className="star-icon" />
                      <IoMdStar className="star-icon" />
                      <IoMdStar className="star-icon" />
                    </div>
                  </div>
                </div>
                
                <div
                  className="portfolio-text-div"
                  data-index="1"
                  ref={(el) => (textSections.current[1] = el)}
                   data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="3000"
                >
                  <h5>E-commerce</h5>
                  <h3>Website Development</h3>
                  <p>
                    Best creative design agencies for innovative, tailored solutions that elevate your brand and drive engagement.
                  </p>
                  <div className="box-div">
                    <h4>Sarah Mitchell</h4>
                    <p>
                      "Working with Apex Creative Design on my website was a fantastic experience. They took my ideas and turned them into a sleek, modern website."
                    </p>
                    <div className="star-icon-div">
                      <IoMdStar className="star-icon" />
                      <IoMdStar className="star-icon" />
                      <IoMdStar className="star-icon" />
                      <IoMdStar className="star-icon" />
                      <IoMdStar className="star-icon" />
                    </div>
                  </div>
                </div>
                <div
                  className="portfolio-text-div"
                  data-index="2"
                  ref={(el) => (textSections.current[2] = el)}
                   data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="3000"
                >
                  <h5>Alice Neverland</h5>
                  <h3>Book Design</h3>
                  <p>
                    Best creative design agencies for innovative, tailored solutions that elevate your brand and drive engagement.
                  </p>
                  <div className="box-div">
                    <h4>Michael Reynolds</h4>
                    <p>
                      “Apex Creative Design did an incredible job bringing my book to life."
                    </p>
                    <div className="star-icon-div">
                      <IoMdStar className="star-icon" />
                      <IoMdStar className="star-icon" />
                      <IoMdStar className="star-icon" />
                      <IoMdStar className="star-icon" />
                      <IoMdStar className="star-icon" />
                    </div>
                  </div>
                </div>

                <div
                  className="portfolio-text-div"
                  data-index="3"
                  ref={(el) => (textSections.current[3] = el)}
                   data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="3000"
                >
                  <h5>Krook</h5>
                  <h3>Logo Designing</h3>
                  <p>
                    Best creative design agencies for innovative, tailored solutions that elevate your brand and drive engagement.
                  </p>
                  <div className="box-div">
                    <h4>Robert Carter</h4>
                    <p>
                      "The team at Apex Creative Design exceeded my expectations with their logo design."
                    </p>
                    <div className="star-icon-div">
                      <IoMdStar className="star-icon" />
                      <IoMdStar className="star-icon" />
                      <IoMdStar className="star-icon" />
                      <IoMdStar className="star-icon" />
                      <IoMdStar className="star-icon" />
                    </div>
                  </div>
                </div>

                <div
                  className="portfolio-text-div"
                  data-index="4"
                  ref={(el) => (textSections.current[4] = el)}
                   data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="3000"
                >
                  <h5>WGS</h5>
                  <h3>Stationary</h3>
                  <p>
                    Best creative design agencies for innovative, tailored solutions that elevate your brand and drive engagement.
                  </p>
                  <div className="box-div">
                    <h4>Michael Reynolds</h4>
                    <p>
                      "I’m thrilled with the stationery design by Apex Creative Design."
                    </p>
                    <div className="star-icon-div">
                      <IoMdStar className="star-icon" />
                      <IoMdStar className="star-icon" />
                      <IoMdStar className="star-icon" />
                      <IoMdStar className="star-icon" />
                      <IoMdStar className="star-icon" />
                    </div>
                  </div>
                </div>
                {/* Repeat text sections for each item */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
   
      <section className="Glimpse">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
                <div className="text-glips">
                  <h3>Take a Glimpse into Our Creative Journey…</h3>
                </div>
            </div>
            <div className="col-lg-6">
              <div className="button-glips">
                <button>
                View All
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Portfolio;
