import React, { useState } from 'react';

export const Banner = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <>
      <section id="home-sec1" className='digitalBanner'>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7 col-12">
              <div className="headings-home" data-aos="fade-left" data-aos-duration="5000">
                <h1>
                  <span>Digital Mockup</span>                  <b> Services</b>
                </h1>
                <p>
                At Apex Creative Design, our digital mock up services are crafted to help brands visualize their products with precision and style. In today's competitive market, having a clear and attractive presentation of your product can make all the difference. Our digital mock ups are designed to give you a realistic preview of how your product will look in real-world scenarios. Whether you need a mockup packaging for retail or a food packaging mockup for your restaurant brand, we provide high-quality designs that perfectly match your vision.
                </p>
              </div>
              <div className="buttons">
                <ul>
                  <li>
                    <a onClick={openPopup} href="#">Get a quote</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-5 col-12">
              <div className="side-image">
                <img width={100} src={require('../assets/digitalMockupSec.png')} alt="Social Media Marketing" />
              </div>
            </div>
          </div>
        </div>

        {isPopupOpen && (
          <div className="popup-overlay" id="quote-popup">
            <div className="popup-content">
              <span className="close-popup" onClick={closePopup}>&times;</span>
              <div className="row align-items-center">
                <div className="col-lg-7">
                  <div className="popup-form">
                    <h2>Request a Quote</h2>
                    <p>Fill out the form below and we'll get back to you as soon as possible!</p>
                    <form>
                      <input type="text" placeholder="Your Name" required />
                      <input type="email" placeholder="Your Email" required />
                      <textarea placeholder="Your Message" required></textarea>
                      <button type="submit">Send Message</button>
                    </form>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className='animatedPopupImage'>
                    <img src={require('../assets/digitalMarketting.png')} alt='digital marketing' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};
