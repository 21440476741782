import React, { useState } from 'react';

export const Banner = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <>
      <section id="home-sec1" className='digitalBanner'>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7 col-12">
              <div className="headings-home" data-aos="fade-left" data-aos-duration="5000">
                <h1>
                  <span> Digital Branding </span>                  <b> Services</b>
                </h1>
                <p>
                At Apex Creative Design, digital branding is more than just a logo or a clever tagline. It's about creating a distinctive identity that resonates with your audience. In today's fast-paced digital world, your brand needs to stand out and make a lasting impact. 
                <br />
                Our Digital Branding Services are crafted to reflect the core values and aspirations of your business, ensuring that every touchpoint, from website design to social media, delivers meaningful connections with your customers. We help your brand break through the noise and communicate its unique value, driving engagement and loyalty.
                </p>
              </div>
              <div className="buttons">
                <ul>
                  <li>
                    <a onClick={openPopup} href="#">Get a quote</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-5 col-12">
              <div className="side-image">
                <img width={100} src={require('../assets/digitalBranding.png')} alt="Social Media Marketing" />
              </div>
            </div>
          </div>
        </div>

        {isPopupOpen && (
          <div className="popup-overlay" id="quote-popup">
            <div className="popup-content">
              <span className="close-popup" onClick={closePopup}>&times;</span>
              <div className="row align-items-center">
                <div className="col-lg-7">
                  <div className="popup-form">
                    <h2>Request a Quote</h2>
                    <p>Fill out the form below and we'll get back to you as soon as possible!</p>
                    <form>
                      <input type="text" placeholder="Your Name" required />
                      <input type="email" placeholder="Your Email" required />
                      <textarea placeholder="Your Message" required></textarea>
                      <button type="submit">Send Message</button>
                    </form>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className='animatedPopupImage'>
                    <img src={require('../assets/digitalMarketting.png')} alt='digital marketing' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};
