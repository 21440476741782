import React, { useEffect, useRef, useState } from "react";
import img1 from "../assets/app-list-1.png";
import img2 from "../assets/app-list-2.png";
import img3 from "../assets/app-list-3.png";
import img4 from "../assets/app-list-4.png";
import img5 from "../assets/app-list-5.png";
import img6 from "../assets/app-list-6.png";
import img7 from "../assets/app-list-7.png";
import img8 from "../assets/app-list-8.png";
import img9 from "../assets/app-list-9.png";
import img10 from "../assets/app-list-10.png";
import img11 from "../assets/app-list-11.png";
import img12 from "../assets/app-list-12.png";
import img13 from "../assets/app-list-13.png";
import img14 from "../assets/app-list-14.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "../../index";
import { Autoplay, FreeMode, Navigation } from "swiper/modules";
import { Link } from "react-router-dom";
import { RiArrowLeftCircleFill, RiArrowRightCircleFill } from "react-icons/ri";

const SecondSec = () => {
  
  const [selectedLanguage, setSelectedLanguage] = useState("");

  const handleChange = (event) => {
    setSelectedLanguage(event.target.value);
  };
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const containerRef = useRef(null);

  // Handle scroll effect
  const handleScroll = () => {
    const container = containerRef.current;
    const scrollPosition = window.scrollY;


  };

  // Add scroll event listener
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 3,
    arrows: true,
    className: "myCustomCarousel",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  const [scrollSpeed, setScrollSpeed] = useState(50); // Default speed
  const lastScrollTop = useRef(0);
  const lastScrollTime = useRef(Date.now());

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const currentTime = Date.now();
      const timeDiff = currentTime - lastScrollTime.current;
      const scrollDiff = currentScrollTop - lastScrollTop.current;

      // Calculate speed: distance/time (pixels per millisecond)
      const speed = Math.abs(scrollDiff / timeDiff);

      // Adjust animation speed based on scroll speed
      const animationSpeed = Math.max(30, 80 - speed * 800);

      setScrollSpeed(animationSpeed);

      lastScrollTop.current = currentScrollTop;
      lastScrollTime.current = currentTime;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const images = [
    require("../assets/app-list-1.png"),
    require("../assets/app-list-2.png"),
    require("../assets/app-list-3.png"),
    require("../assets/app-list-4.png"),
    require("../assets/app-list-5.png"),
    require("../assets/app-list-6.png"),
    require("../assets/app-list-1.png"),
    require("../assets/app-list-2.png"),
    require("../assets/app-list-3.png"),
    require("../assets/app-list-4.png"),
    require("../assets/app-list-6.png"),
    require("../assets/app-list-5.png"),
    require("../assets/app-list-3.png"),
    require("../assets/app-list-4.png"),
  ];

  const [isOpen, setIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const openPopup = (index) => {
    setCurrentIndex(index);
    setIsOpen(true);
  };

  const closePopup = () => {
    setIsOpen(false);
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  return (
    <>
      {/* <div className="container mainSlider">
      
        <Slider {...settings}>
          <div className="box-service">
            <img src={require("../assets/music.png")} />
            <h3>Music</h3>
          </div>

          <div className="box-service">
            <img src={require("../assets/agriculture.png")} />
            <h3>Agriculture</h3>
          </div>

          <div className="box-service">
            <img src={require("../assets/healthcare.png")} />
            <h3>Health</h3>
          </div>

          <div className="box-service">
            <img src={require("../assets/fast-food.png")} />
            <h3>Food</h3>
          </div>

          <div className="box-service">
            <img src={require("../assets/tech.png")} />
            <h3>Technology</h3>
          </div>

          <div className="box-service">
            <img src={require("../assets/realState.png")} />
            <h3>Real Estate</h3>
          </div>
        </Slider>
      </div> */}
      
      <section className="sec-two" id="second">
      <div className="auto_slider_container">
      <ul className="auto_slider">
        {images.map((image, index) => (
          <li key={index} onClick={() => openPopup(index)}>
            <img src={image} alt={`Slide ${index + 1}`} />
          </li>
        ))}
      </ul>

      {isOpen && (
        <div className="popup-overlay-image" onClick={closePopup}>
          <div className="popup-content-image" onClick={(e) => e.stopPropagation()}>
            <span className="close-button-image" onClick={closePopup}>&times;</span>
            <img src={images[currentIndex]} alt={`Slide ${currentIndex + 1}`} />
            <div className="popup-navigation-image">
              <button onClick={handlePrev}><RiArrowLeftCircleFill/></button>
              <button onClick={handleNext}><RiArrowRightCircleFill /></button>
            </div>
          </div>
        </div>
      )}
    </div>
        <div className="container">
          <div className="row">
            <div className="text-box">
              <h2>Apex Creative Design</h2>
              <p>
                Best creative design agencies for innovative, tailored solutions
                that elevate your brand and drive engagement. Experience
                excellence in design and unmatched client satisfaction.
              </p>
            </div>
            <div className="col-lg-12 d-flex gap-4 mt-4">
              <div className="hoverable-new hoverable-sec1"  data-aos="fade-right" data-aos-duration="500">
                <div className="innerbox1">
                  <h3>DELIGHTING THROUGH DESIGN</h3>
                  <p>
                    We breathe life into designs through scalable and visually
                    stunning development that engage end-users and convert.
                  </p>
                  {!isHovered && (
                    <div className={`innerbox-checkbox-sec }`}>
                      <Link className="innerbox-checkbox" to="/ux-design">
                        <label htmlFor="html">UX DESIGN</label>
                      </Link>
                      <Link to={'/digital-branding'} className="innerbox-checkbox">
                        <label htmlFor="html">DIGITAL BRANDING</label>
                      </Link>
                      <Link to={'/logo-design'} className="innerbox-checkbox">
                        <label htmlFor="html">LOGO DESIGNING</label>
                      </Link>
                      <Link to={'/book-design'} className="innerbox-checkbox">
                        <label htmlFor="html">BOOK DESIGN</label>
                      </Link>
                      <Link to={'/mockup-design'} className="innerbox-checkbox">
                        <label htmlFor="html">MOCKUP DESIGN</label>
                      </Link>
                      <Link className="innerbox-checkbox" to="/ui-design">
                        <label htmlFor="html">UI DESIGN</label>
                      </Link>
                      <Link to={'/stationary-design'} className="innerbox-checkbox">
                        <label htmlFor="html">STATIONARY DESIGN</label>
                      </Link>
                      <div className="innerbox-checkbox">
                        <label htmlFor="html">ANIMATION</label>
                      </div>
                      <Link to={'/packaging-design'} className="innerbox-checkbox">
                        <label htmlFor="html">PACKAGING DESIGN</label>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
              <div
                className="hoverable-new hoverable-sec2"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                 data-aos="fade-left" data-aos-duration="500"
              >
                <div className="innerbox2">
                  <h3>Developing lasting digital products</h3>
                  <p>
                    We breathe life into designs through scalable and visually
                    stunning development that engage end-users and convert.
                  </p>
                  <div className="innerbox-checkbox-sec">
                    <Link className="innerbox-checkbox" to="/web-developing">
                      <label htmlFor="html">WEB DEVELOPMENT</label>
                    </Link>
                    <Link className="innerbox-checkbox">
                      <label htmlFor="html">APP DEVELOPMENT</label>
                    </Link>
                    <Link
                      className="innerbox-checkbox"
                      to="/custom-application"
                    >
                      <label htmlFor="html">CUSTOM APPLICATION</label>
                    </Link>
                    <Link
                      className="innerbox-checkbox"
                     
                    >
                      <label htmlFor="html">CROSS PLATFORM APPLICATION</label>
                    </Link>
                    <Link  to="/custom-frontend-application" className="innerbox-checkbox">
                      <label htmlFor="html">FRONTEND DEVELOPMENT</label>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="sec-three">
                        <svg className="swirl" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 400" preserveAspectRatio="none">
                            <path d="M10 200 C150 50, 300 350, 450 150 S700 250, 790 200" stroke="white" stroke-width="3" fill="none"></path>
                            <path d="M10 220 C150 70, 300 370, 450 170 S700 270, 790 220" stroke="#6fd119" stroke-width="2" fill="none"></path>
                            <path d="M10 240 C150 90, 300 390, 450 190 S700 290, 790 240" stroke="white" stroke-width="1.5" fill="none"></path>
                            <path d="M10 260 C150 110, 300 410, 450 210 S700 310, 790 260" stroke="#6fd119" stroke-width="1" fill="none" stroke-opacity="0.7"></path>
                        </svg>
                        </section>
                
    </>
  );
};

export default SecondSec;