import React from 'react'
import { Main } from '../AboutComponents/Main'
import { ThirdSec } from '../AboutComponents/ThirdSec'

export const About = () => {
  return (
    <>
    <Main/>
    {/* <ThirdSec/> */}
    </>
  )
}
