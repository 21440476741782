import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

export const FifthSec = () => {
  var settings = {
    // dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: true,
    className: "myCustomCarousel",
  };
  return (
    <>
      <section className="materialDigital" style={{background:'#232323'}} >
        <div className="container">
          <div className="row workwithuk">
            <div className="col-lg-8">
              <div className="colEight">
                <h3>
                Custom Front End <br/>Development <span>Benchmarks </span>
                </h3>
                <p>
                At Apex Creative Design, we set clear benchmarks to ensure your front end development project is a success. By combining advanced coding techniques with strategic performance tracking, we deliver front-end solutions that not only look good but also drive measurable results.
                </p>
              </div>
            </div>
            <Slider {...settings}>
                <div>
                  <div className="box-digital">
                    <img src={require("../assets/person-filled.png")} />
                    <h4>UI/UX Design Integration</h4>
                    <p>
                       We seamlessly merge user experience (UX) design with user interface (UI) development to create front-ends that are both visually appealing and highly functional. This ensures users have an enjoyable and engaging experience on your site.
                    </p>
                  </div>
                </div>
                <div>
                  <div className="box-digital">
                    <img src={require("../assets/ux-design_1370315.png")} />
                    <h4>Optimized Performance Metrics</h4>
                    <p>
                       Fast loading websites are critical to user satisfaction. We use performance benchmarking tools to monitor key metrics like page load speed, time to interact (TTI), and bounce rate, ensuring your website performs at its best.
                    </p>
                  </div>
                </div>
                <div>
                  <div className="box-digital">
                    <img src={require("../assets/design-reniew.png")} />
                    <h4>Cross-Platform Consistency</h4>
                    <p>
                       We test your website across different devices and browsers to ensure consistent functionality and appearance. From mobile to desktop, we make sure your brand's digital presence is flawless, no matter where it's viewed.
                    </p>
                  </div>
                </div>
              </Slider>
            
            <div className="col-lg-12">
              <div className="colEight text-center">
                <h3>
                  <span>Apex Creative Design: </span> Your Trusted <br/>Front-End Development Partner
                </h3>
                <p>
                At Apex Creative Design, we specialize in creating cutting-edge front-end solutions that enhance your brand's digital presence. As your trusted partner in front-end development, we focus on delivering designs that not only captivate your audience but also function seamlessly across devices.
                </p>
              </div>
            </div>
            <div className="col-lg-4 bg-darkShadow bg-darkShadow-left">
              <h2>
              User-Centered Design Approach
              </h2>
              <p>
              User-Centered Design Approach
                 We place users at the center of every design decision. By understanding their needs, behaviors, and preferences, we create intuitive and engaging interfaces that provide a smooth and enjoyable experience on your website.
              </p>
            </div>
            <div className="col-lg-4 bg-lightShadow">
              <h2>
              Responsive and Accessible Development  
              </h2>
              <p>
                 We design responsive websites that work flawlessly across all devices and are accessible to users with different abilities, ensuring your digital presence reaches a broader audience and complies with accessibility standards.
              </p>
            </div>
            <div className="col-lg-4 bg-darkShadow bg-darkShadow-right">
              <h2>
              Continue Testing and Optimization
              </h2>
              <p>
              The digital world is constantly evolving, and so should your website. We offer ongoing testing and optimization services to ensure your front end stays up to date with the latest technologies and continues to deliver the best possible user experience.
              </p>
            </div>
            
          </div>
        </div>
      </section>
    </>
  );
};
