import React, { useState } from "react";

export const Banner = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };
  return (
    <>
      <section id="home-sec1" className='digitalBanner'>
  <div className="container">
    <div className="row align-items-center">
      <div className="col-lg-7 col-12">
        <div className="headings-home" data-aos="fade-left" data-aos-duration="5000">
        
        <h1>
                    <span>
                    PPC 
                    </span>
                    <b> Services</b>
                </h1>
                <p>
                Pay-per-click (PPC) advertising is one of the fastest and most
                effective ways to get your business in front of the right
                audience. At Apex Creative Designs, we offer comprehensive PPC
                services that are designed to help you maximize your online
                visibility and achieve measurable results. Our team of experts
                specializes in creating, managing, and optimizing PPC campaigns
                that drive traffic and generate leads. Whether you're a small
                business or a growing enterprise, our PPC management services
                ensure that your advertising budget works harder and delivers
                the best possible return on investment (ROI).
                </ p>
               
        </div>
        <div className="buttons">
          <ul>
            <li>
              <a className="open-popup" onClick={openPopup}>Get a quote</a>
            </li>
          </ul>
        </div> 
      </div>
      <div className="col-lg-5 col-12">
        <div className="side-image">
          <img width="100%" src={require('../assets/ppcMarketting.png')} />
        </div>
      </div>
      <div className="col-lg-12">
      <div className="ppcBanner-secpara">
                  <h3>
                    What are <span>PPC</span>
                  </h3>
                  <p>
                    PPC services involve creating and managing ads that appear on
                    search engines, social media platforms, and other online
                    channels. These ads are displayed to potential customers when
                    they search for products or services that are related to your
                    business. The beauty of PPC advertising is that you only pay
                    when someone clicks on your ad, making it a cost-effective way
                    to attract quality traffic. At Apex Creative Designs, we
                    specialize in crafting targeted ads that reach your ideal
                    audience, ensuring that every click counts.
                  </p>
                </div>
      </div>
    </div>
  </div>

  {isPopupOpen && (
          <div className="popup-overlay" id="quote-popup">
            <div className="popup-content">
              <span className="close-popup" onClick={closePopup}>&times;</span>
              <div className="row align-items-center">
                <div className="col-lg-7">
                  <div className="popup-form">
                    <h2>Request a Quote</h2>
                    <p>Fill out the form below and we'll get back to you as soon as possible!</p>
                    <form>
                      <input type="text" placeholder="Your Name" required />
                      <input type="email" placeholder="Your Email" required />
                      <textarea placeholder="Your Message" required></textarea>
                      <button type="submit">Send Message</button>
                    </form>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className='animatedPopupImage'>
                    <img src={require('../assets/digitalMarketting.png')} alt='digital marketing' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
</section>
   
    </>
  );
};
