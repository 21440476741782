import React from 'react'
import { Banner } from '../../LogoDesign/Banner'
import { SecondSec } from '../../LogoDesign/SecondSec'
import { ThirdSec } from '../../LogoDesign/ThirdSec'
import { FourthSec } from '../../LogoDesign/FourthSec'
import { FifthSec } from '../../LogoDesign/FifthSec'
import { SixtySec } from '../../LogoDesign/SixtySec'
import { SeventhSec } from "../../LogoDesign/SeventhSec";

export const LogoDesign = () => {
  return (
    <>
    <Banner />
    <SecondSec />
    <ThirdSec />
    {/* <FourthSec /> */}
    <FifthSec />
    <SixtySec />
    <SeventhSec />
  </>
  )
}
