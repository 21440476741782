import React, { useEffect, useState, useRef } from "react";
import { FaHeart, FaMinimize, FaRegHeart } from "react-icons/fa6";
import { FaShoppingCart } from "react-icons/fa";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { MdCancel } from "react-icons/md";

const FifthSec = () => {
  const [scrollY, setScrollY] = useState(0);
  const [isInView, setIsInView] = useState(false);
  const qouteRef = useRef(null);

  // Function to handle scroll
  const handleScroll = () => {
    if (isInView) {
      setScrollY(window.scrollY);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        setIsInView(entry.isIntersecting);
      },
      {
        root: null, // viewport
        threshold: 0.1, // Trigger when 10% of the element is visible
      }
    );

    if (qouteRef.current) {
      observer.observe(qouteRef.current);
    }

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up on unmount
    return () => {
      if (qouteRef.current) {
        observer.unobserve(qouteRef.current);
      }
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isInView]);

  // Calculate transformations based on scroll position
  const headingStyle = {
    transform: `translateY(${scrollY * 0.1}px)`,
    transition: "transform 0.1s ease-out",
  };

  const firstContentStyle = {
    transform: `translateY(${scrollY * 0.002}px)`,
    transition: "transform 0.1s ease-out",
  };

  const secondContentStyle = {
    transform: `translateY(${scrollY * 0.03}px))`,
    transition: "transform 0.1s ease-out",
  };

  const thirdContentStyle = {
    transform: `translateY(${scrollY * 1.2}px))`,
    transition: "transform 0.1s ease-out",
  };
  return (
    <>
      <div class="industries " data-industries="">
        <div class="container">
          <div class="row">
            <div class="col-lg-8 col-md-10 col-12">
              <div
                class="industries__content"
                data-speed="1.2"
                style={{
                  translate: "none",
                  rotate: "none",
                  scale: "none",
                  transform: "translate(0px, -14.9px)",
                  willChange: "transform",
                }}
                data-lag="0"
              >
                <h2 class="industries__title" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000">
                  Delivering Brilliance Across <span>Domains</span>
                </h2>
                <p class="para2 industries__text"data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                  Best creative design agencies for innovative, tailored
                  solutions that elevate your brand and drive engagement.
                  Experience excellence in design and unmatched client
                  satisfaction.
                </p>
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-12">
              <div
                class="industries__hexa-gallery"
                data-speed="1.1"
                style={{
                  translate: "none",
                  rotate: "none",
                  scale: "none",
                  willChange: "transform",
                }}
                data-lag="0"
              >
                <a class="industries__hexa" data-text="tab-1">
                  <div class="industries__hexa-inner">
                    <div class="industries__hexa-front">
                      <div class="industries__hexa-text-cont">
                        <p class="headline industries__hexa-text">
                          Real Estate
                        </p>
                      </div>
                    </div>
                    <div class="industries__hexa-back">
                      <img
                        decoding="async"
                        src={require("../assets/Real-Estate.png")}
                        alt="Real-Estate"
                        class="industries__hexa-img"
                      />
                      <p class="headline industries__hexa-text">Real Estate</p>
                    </div>
                  </div>
                </a>
                <a class="industries__hexa" data-text="tab-2">
                  <div class="industries__hexa-inner">
                    <div class="industries__hexa-front">
                      <div class="industries__hexa-text-cont">
                        <p class="headline industries__hexa-text">
                          Artificial <br />
                          Intelligence
                        </p>
                      </div>
                    </div>
                    <div class="industries__hexa-back">
                      <img
                        decoding="async"
                        src={require("../assets/Artificial-Intelligence.png")}
                        alt="Artificial-Intelligence"
                        class="industries__hexa-img"
                      />
                      <p class="headline industries__hexa-text">
                        Artificial <br /> Intelligence
                      </p>
                    </div>
                  </div>
                </a>
                <a class="industries__hexa" data-text="tab-3">
                  <div class="industries__hexa-inner">
                    <div class="industries__hexa-front">
                      <div class="industries__hexa-text-cont">
                        <p class="headline industries__hexa-text">
                          Enterprise &amp;
                          <br />
                          Logistics
                        </p>
                      </div>
                    </div>
                    <div class="industries__hexa-back">
                      <img
                        decoding="async"
                        src={require("../assets/enterprise-logistic.png")}
                        alt="enterprise-logistic"
                        class="industries__hexa-img"
                      />
                      <p class="headline industries__hexa-text">
                        Enterprise &amp;
                        <br />
                        Logistics
                      </p>
                    </div>
                  </div>
                </a>
                <a class="industries__hexa" data-text="tab-4">
                  <div class="industries__hexa-inner">
                    <div class="industries__hexa-front">
                      <div class="industries__hexa-text-cont">
                        <p class="headline industries__hexa-text">
                          Sports &amp;
                          <br />
                          Fitness
                        </p>
                      </div>
                    </div>
                    <div class="industries__hexa-back">
                      <img
                        decoding="async"
                        src={require("../assets/sports-and-fitness.png")}
                        alt="sports-and-fitness"
                        class="industries__hexa-img"
                      />
                      <p class="headline industries__hexa-text">
                        Sports &amp;
                        <br /> Fitness
                      </p>
                    </div>
                  </div>
                </a>
                <a class="industries__hexa" data-text="tab-5">
                  <div class="industries__hexa-inner">
                    <div class="industries__hexa-front">
                      <div class="industries__hexa-text-cont">
                        <p class="headline industries__hexa-text">Fintech</p>
                      </div>
                    </div>
                    <div class="industries__hexa-back">
                      <img
                        decoding="async"
                        src={require("../assets/fintech.png")}
                        alt="fintech"
                        class="industries__hexa-img"
                      />
                      <p class="headline industries__hexa-text">Fintech</p>
                    </div>
                  </div>
                </a>
                <a class="industries__hexa" data-text="tab-6">
                  <div class="industries__hexa-inner">
                    <div class="industries__hexa-front">
                      <div class="industries__hexa-text-cont">
                        <p class="headline industries__hexa-text">eCommerce</p>
                      </div>
                    </div>
                    <div class="industries__hexa-back">
                      <img
                        decoding="async"
                        src={require("../assets/eCommerce.png")}
                        alt="eCommerce"
                        class="industries__hexa-img"
                      />
                      <p class="headline industries__hexa-text">eCommerce</p>
                    </div>
                  </div>
                </a>
                <a class="industries__hexa" data-text="tab-7">
                  <div class="industries__hexa-inner">
                    <div class="industries__hexa-front">
                      <div class="industries__hexa-text-cont">
                        <p class="headline industries__hexa-text">Healthcare</p>
                      </div>
                    </div>
                    <div class="industries__hexa-back">
                      <img
                        decoding="async"
                        src={require("../assets/Healthcare-we.png")}
                        alt="Healthcare"
                        class="industries__hexa-img"
                      />
                      <p class="headline industries__hexa-text">Healthcare</p>
                    </div>
                  </div>
                </a>
                <a class="industries__hexa" data-text="tab-8">
                  <div class="industries__hexa-inner">
                    <div class="industries__hexa-front">
                      <div class="industries__hexa-text-cont">
                        <p class="headline industries__hexa-text">
                          Food, Travel <br />
                          &amp; Leisure
                        </p>
                      </div>
                    </div>
                    <div class="industries__hexa-back">
                      <img
                        decoding="async"
                        src={require("../assets/food-travel-leisure.png")}
                        alt="food-travel-leisure"
                        class="industries__hexa-img"
                      />
                      <p class="headline industries__hexa-text">
                        Food, Travel <br />
                        &amp; Leisure
                      </p>
                    </div>
                  </div>
                </a>
                <a class="industries__hexa" data-text="tab-9">
                  <div class="industries__hexa-inner">
                    <div class="industries__hexa-front">
                      <div class="industries__hexa-text-cont">
                        <p class="headline industries__hexa-text">Edutech</p>
                      </div>
                    </div>
                    <div class="industries__hexa-back">
                      <img
                        decoding="async"
                        src={require("../assets/edutech.png")}
                        alt="edutech"
                        class="industries__hexa-img"
                      />
                      <p class="headline industries__hexa-text">Edutech</p>
                    </div>
                  </div>
                </a>
                <a class="industries__hexa" data-text="tab-10">
                  <div class="industries__hexa-inner">
                    <div class="industries__hexa-front">
                      <div class="industries__hexa-text-cont">
                        <p class="headline industries__hexa-text">
                          Social Media
                          <br />
                          &amp;
                          <br /> Entertainment
                        </p>
                      </div>
                    </div>
                    <div class="industries__hexa-back">
                      <img
                        decoding="async"
                        src={require("../assets/social-media.png")}
                        alt="social-media"
                        class="industries__hexa-img"
                      />
                      <p class="headline industries__hexa-text">
                        Social Media
                        <br />
                        &amp;
                        <br /> Entertainment
                      </p>
                    </div>
                  </div>
                </a>
                <a href="#" class="industries__hexa industries__hexa-more">
                  <div class="industries__hexa-color"></div>
                  <p class="industries__hexa-text">
                    And still more <br />
                    discovering…
                  </p>
                </a>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <p id="tab-1" class="industries__hexa-content para2">
                From residential to commercial spaces, and from brokerage
                services to real estate technology, we craft holistic user
                experiences to foster genuine connections across the real estate
                landscape
              </p>
              <p id="tab-2" class="industries__hexa-content para2">
                Machine learning, neural networks, robotics, natural language
                processing and more – our design solutions have been making AI
                solutions more accessible one interaction at a time
              </p>
              <p id="tab-3" class="industries__hexa-content para2">
                ERP systems, supply chain solutions, inventory management and
                transport automation are ingeniously interconnected through
                effective and efficient design solutions
              </p>
              <p id="tab-4" class="industries__hexa-content para2">
                Incorporating everything from sports gear to fitness apps and
                wellness studios to professional athletics, our user-centric
                design solutions form the core of up-and-coming sports and
                fitness industry experiences
              </p>
              <p id="tab-5" class="industries__hexa-content para2">
                Mobile banking, robo-advising, blockchain technology and
                Insurtech – our design solutions keep people at the center of
                the process through seamless cohesive integrations and secure
                interactions
              </p>
              <p id="tab-6" class="industries__hexa-content para2">
                We are creating new pathways across the online retail,
                dropshipping, digital payments and supply chain management
                domains through user-focused design strategies in the Ecommerce
                space
              </p>
              <p id="tab-7" class="industries__hexa-content para2">
                Telemedicine, health informatics, diagnostics and therapeutic
                solutions we help make lives better through empathetic and
                user-centric design solutions
              </p>
              <p id="tab-8" class="industries__hexa-content para2">
                Online food delivery, travel booking platforms, wellness
                retreats or adventure tourism, we catch the pulse of your user
                enhancing their experience even more with our expertly crafted
                UI/UX solutions for food, travel and leisure
              </p>
              <p id="tab-9" class="industries__hexa-content para2">
                We’re helping digital classrooms, learning apps, course
                management systems and virtual tutoring break the glass ceiling
                through comprehensive, user-friendly and interactive designs
              </p>
              <p id="tab-10" class="industries__hexa-content para2">
                Social networking, streaming platforms, user-generated content
                or professional production – our strategic UI UX solutions give
                an added charisma to your creations
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="qoute" data-qoute="" ref={qouteRef}>
        <h3 className="qoute__heading" style={headingStyle}>
          We <span>STRONGLY</span> follow the Words
        </h3>
        <div className="qoute__content" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1000">
          <div className="qoute__content-first"  style={firstContentStyle}>
            <h4>Imagination Sparks Potential.</h4>
          </div>
          <div className="qoute__content-second" style={secondContentStyle}>
            <h4>Design Creates Possibilities.</h4>
          </div>
          <div className="qoute__content-third" style={thirdContentStyle}>
            <h4>Possibilities Shape the Future.</h4>
          </div>
        </div>
      </div>
    </>
  );
};

export default FifthSec;
