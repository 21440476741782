import React, { useEffect, useRef, useState } from 'react'

export const ThirdSec = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = (index) => setHoveredIndex(index);
  const handleMouseLeave = () => setHoveredIndex(null);
  return (
    <>
    <section className='designThirdSec'>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-6'>
                    <div className={`firstTextSec`}>
                    <h3>
                        Our Expert  <span>User Interface </span> Capabilities
                        </h3>
                        <p>
                        At Apex Creative Design, we offer a full suite of digital branding services to help your business shine in the digital world. From crafting memorable identities to delivering consistent and impactful brand experiences, our capabilities are designed to meet your unique needs.
                        </p>
                    </div>
                </div>
                <div className='col-lg-6'>
                <div className='firstTextSec'>
                {[
                  {
                    title: "Brand Identity Creation",
                    text:
                      "We work closely with you to create a unique and compelling brand identity that truly represents your business. From logos to color schemes, typography, and brand messaging, we ensure every element aligns with your values and vision."
                  },
                  {
                    title: "Website Design and Development",
                    text:
                      "Your website is the digital face of your brand. Our team designs responsive, user-friendly websites that not only look stunning but also provide a seamless experience for your customers, all while reinforcing your brand identity."
                  },
                  {
                    title: "Social Media Branding",
                    text:
                      "We help you establish a cohesive presence across social media platforms, ensuring that your brand’s voice, visuals, and messaging are consistent and engaging. Our goal is to help you connect with your audience and build lasting relationships."
                  },
                  {
                    title: "Content Strategy and Development",
                    text:
                      "Content is key to building a strong brand. We develop tailored content strategies that tell your brand’s story and resonate with your audience. From blog posts to social media content, we ensure your message is clear and impactful."
                  },
                  {
                    title: "Digital Advertising and Campaigns",
                    text:
                      "Our digital branding capabilities extend to creating targeted advertising campaigns that boost your brand’s visibility. We craft ads that not only attract attention but also communicate your brand’s value, driving engagement and conversions."
                  },
                  {
                    title: "Brand Consultation and Strategy",
                    text:
                      "Your digital product deserves a user interface that reflects your brand, engages users, and drives success. With Apex Creative Design's expert UI capabilities, we bring your vision to life with functional and beautiful designs."
                  }
                ].map((item, index) => (
                  <div
                    className="portfolio-text-div"
                    key={index}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <h4 data-aos="fade-left" data-aos-duration="1000">
                      <b>{item.title}</b>
                    </h4>
                    <p
                      className={`portfolio-text ${
                        hoveredIndex === index ? "show" : ""
                      }`}
                    >
                      {item.text}
                    </p>
                  </div>
                ))}
                    </div>
                </div>
            </div>
        </div>
    </section>
   

    {/* Optional styles */}
    <style jsx>{`
      .firstTextSec.fixed {
    position: fixed;
    top: 100px;
    width: 515px;
}
    
.firstTextSec.move-to-top {
    bottom: 65%;
    width: 515px;
    position: fixed;
    top: 100px;
}

    `}</style>
    </>
    
  )
}
