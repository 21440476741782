import React, { useEffect, useRef } from "react";
import banner from "../assets/bannerThird.mp4";
import { MdKeyboardDoubleArrowDown } from "react-icons/md";
import "swiper/css";
import "swiper/css/pagination";
import AOS from "aos";
import "aos/dist/aos.css";

const Main = () => {
  const textRef = useRef(null);

  useEffect(() => {
    // Initialize AOS for other animations
    AOS.init({
      duration: 1500,
      easing: "ease-in-out",
    });

    // 3D scroll effect on text
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (textRef.current) {
        textRef.current.style.transform = `translateZ(${scrollPosition * 0.1}px) scale(${1 + scrollPosition * 0.001})`;
        textRef.current.style.opacity = `${1 - scrollPosition * 0.002}`;
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      {/* <div id="light"></div> */}
      <div className="video-container">
        <video className="videoTag" autoPlay loop muted>
          <source src={banner} type="video/mp4" />
        </video>
        <section className="overlay bg-sec">
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-8">
                <div
                  ref={textRef}
                  className="second-para"
                  data-aos="fade-down"
                >
                  <h1 className="scroll-text">
                    Join us for <span>smart online plans digital</span>{" "}
                    marketing <span>agency</span>
                  </h1>
                  <p>
                    Apex Creative Designs Agency, a leading professional website
                    design company. We make awesome websites to boost your brand. Let's take your online presence to the{" "}
                    <span>next level!</span>
                  </p>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="text-center toggle">
                  <span>
                    <a href="#second">
                      <MdKeyboardDoubleArrowDown />
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Main;
