import React, { useEffect, useRef, useState } from "react";

export const ThirdSec = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = (index) => setHoveredIndex(index);
  const handleMouseLeave = () => setHoveredIndex(null);
  return (
    <>
      <section className="designThirdSec" >
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div
                className={`firstTextSec`}>
                <h3>
                  Our <span>Custom Book</span> Design Capabilities
                </h3>
                <p>
                  Our range of book design services ensures that every element
                  of your project is carefully considered and crafted to
                  perfection. From eye-catching covers to clean and intuitive
                  page layouts, we deliver designs that make your book a
                  standout.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="firstTextSec">
              {[
                  {
                    title: "Our Range of SEO Services",
                    text:
                      "Your cover is the first thing readers see, so we provide book cover design ideas that resonate with your audience. Whether you want something bold and modern or classic and subtle, our team will design a cover that reflects the heart of your book."
                  },
                  {
                    title: "Interior Book Layout and Page Design",
                    text:
                      "Creating a smooth reading experience is key to keeping readers engaged. We specialize in book page design that offers clean typography, easy-to-follow layouts, and a visually appealing flow from start to finish."
                  },
                  {
                    title: "eBook Design Services",
                    text:
                      "If you're publishing digitally, we offer responsive eBook design that adapts seamlessly across different devices. We ensure your eBook looks as great on a Kindle as it does on a tablet or smartphone."
                  },
                  {
                    title: "Typography and Illustration Integration",
                    text:
                      "Whether your book includes detailed illustrations, graphs, or photographs, we integrate these elements harmoniously with the text, ensuring a balanced and beautiful layout."
                  },
                  {
                    title: "Print and Digital Compatibility",
                    text:
                      "Our book designers understand the unique needs of both print and digital formats. Whether you're preparing your book for a traditional print run or a digital platform, we ensure that your design book is optimized for both."
                  }
                ].map((item, index) => (
                  <div
                    className="portfolio-text-div"
                    key={index}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <h4 data-aos="fade-left" data-aos-duration="1000">
                      <b>{item.title}</b>
                    </h4>
                    <p
                      className={`portfolio-text ${
                        hoveredIndex === index ? "show" : ""
                      }`}
                    >
                      {item.text}
                    </p>
                  </div>
                ))}
                {/* <div
                  className="portfolio-text-div"
                  data-index="0"
                >
                  <h4>
                    <b>Our Range of SEO Services</b>
                    <br />
                  </h4>
                  <p>
                  Your cover is the first thing readers see, so we provide book cover design ideas that resonate with your audience. Whether you want something bold and modern or classic and subtle, our team will design a cover that reflects the heart of your book.
                  </p>
                </div>
                <div
                  className="portfolio-text-div"
                  data-index="1"
                >
                  <h4>Interior Book Layout and Page Design</h4>
                  <p>
                  Creating a smooth reading experience is key to keeping readers engaged. We specialize in book page design that offers clean typography, easy-to-follow layouts, and a visually appealing flow from start to finish.
                  </p>
                </div>
                <div
                  className="portfolio-text-div"
                  data-index="2"
                >
                  <h4>eBook Design Services</h4>
                  <p>
                  If you're publishing digitally, we offer responsive eBook design that adapts seamlessly across different devices. We ensure your eBook looks as great on a Kindle as it does on a tablet or smartphone.
                  </p>
                </div>
                <div
                  className="portfolio-text-div"
                  data-index="3"
                >
                  <h4>Typography and Illustration Integration</h4>
                  <p>
                  Whether your book includes detailed illustrations, graphs, or photographs, we integrate these elements harmoniously with the text, ensuring a balanced and beautiful layout.
                  </p>
                </div>
                <div
                  className="portfolio-text-div"
                  data-index="4"
                >
                  <h4>Print and Digital Compatibility</h4>
                  <p>
                  Our book designers understand the unique needs of both print and digital formats. Whether you're preparing your book for a traditional print run or a digital platform, we ensure that your design book is optimized for both.
                  </p>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Optional styles */}
      <style jsx>{`
        .firstTextSec.fixed {
          position: fixed;
          top: 100px;
          width: 515px;
        }

        .firstTextSec.move-to-top {
          bottom: 65%;
          width: 515px;
          position: fixed;
          top: 100px;
        }
      `}</style>
    </>
  );
};
