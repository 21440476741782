import React from 'react'
import { FaArrowAltCircleRight } from 'react-icons/fa'
import { Link } from 'react-router-dom'

export const SectionFive = () => {
  return (
    <>
       <div className="service-item right bottom">
                <div className="container d-flex align-items-center">
                    <div className="left" data-aos="fade-right">
                        <h3 className="title">Social Media Marketing
                        Services</h3>
                        <p className="short-description">In the digital age, having a solid presence on social media platforms is essential for every business. At Apex Creative Designs, we specialize in providing comprehensive social media marketing services that help your brand grow and connect with your audience in meaningful ways. Whether you are looking to enhance your brand’s visibility or drive engagement, our expert team can tailor solutions that fit your specific goals.</p>
                        <div className="service-links">
                            {/* <ul>
                                <li><a className="php" href="#">PHP Development</a></li>
                                <li><a className="mern" href="#">Mern Stack Developers</a></li>
                                <li><a className="mean" href="#">Mean Stack Developers</a></li>
                                <li><a className="fullstack" href="#">Full Stack Developers</a></li>
                            </ul> */}
                        </div>
                        <div className="cta-button">
                        <Link className="primary-btn get-center hvr-shutter-out-horizontal btn" to="/contact-us"><span>Talk To Our Experts  </span><FaArrowAltCircleRight className='banner-icon' /></Link>
                            </div>
                    </div>
                    <div className="right" data-aos="fade-left">
                        <div className="service-image text-center">
                            <picture> <img decoding="async" className=" lazyloaded" src={require('../../assets/website-development.webp')} data-src={require('../../assets/website-development.webp')} data-srcset={require('../../assets/website-development.webp')} alt="Website Development" height="432" width="583" srcset={require('../../assets/website-development.webp')}/> </picture>
                        </div>
                    </div>
                </div>
            </div>
    </>
  )
}
