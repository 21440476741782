import React from "react";

export const FifthSec = () => {
  return (
    <>
      <section className="materialDigital" style={{background:'#232323'}} >
        <div className="container">
          <div className="row workwithuk align-items-center">
            <div className="col-lg-6">
              <div className="colEight">
                <h3>
                  <span>E-Commerce</span> Website Agency
                </h3>
                <p>
                We are looking for an eCommerce website agency to build an online store that sells. You've come to the right place. At Apex Creative Designs, we specialize in creating eCommerce websites that look great and are designed to convert. Our team has experience working with various eCommerce platforms, including Magento and WordPress, so we've got you covered no matter your preference.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
             
                <img src={require("../assets/ecommerce_3051733 1.png")} />
             </div>
            <div className="col-lg-6">
             
                <img src={require("../assets/wikiia_3044152 1.png")} />
             </div>
            <div className="col-lg-6">
              <div className="colEight">
                <h3>
                  <span>WORDPRESS</span> Website DEVELOPMENT COMPANY
                </h3>
                <p>
                WordPress is one of the most popular platforms for businesses of all sizes. At Apex Creative Designs, we offer specialized WordPress website development services that take full advantage of this versatile platform. From custom themes to plugin development, our team ensures your WordPress site is created to meet your needs. As a leading WordPress website development company, we offer comprehensive services, including design and ongoing maintenance. This ensures that your WordPress site remains up-to-date and secure.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="colEight">
                <h3>
                  <span>Web Development</span> Consultancy
                </h3>
                <p>
                Need expert advice on how to improve your current website? Our web development consultancy services are designed to provide you with the insights and guidance you need to make informed decisions about your online presence. Whether you want to upgrade your site or need help planning a new project, our consultants are here to help.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              
                <img src={require("../assets/master_16126872-1.png")} />
              
            </div>
            <div className="col-lg-6">
              
                <img src={require("../assets/award_7002099-00.png")} />
              
            </div>
            <div className="col-lg-6">
              <div className="colEight">
                <h3>
                  <span>Top</span> Web Design Companies <span>in the UK</span>
                </h3>
                <p>
                When it comes to finding the top web design companies UK businesses trust, Apex Creative Designs ranks high. We are known for delivering high-quality websites that meet and exceed client expectations. Our goal is to provide you with a website that supports your business goals while offering a seamless experience for your users.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
