import React, { useEffect, useRef, useState } from 'react'

export const ThirdSec = () => {
    const [isImageFixed, setIsImageFixed] = useState(false);
    const [isInFifthSection, setIsInFifthSection] = useState(false);
    
    const portfolioRef = useRef(null);
    const textSections = useRef([]);
  
    useEffect(() => {
      const options = {
        root: null,
        threshold: 0.1,
      };
  
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          const index = entry.target.getAttribute("data-index");
  
          if (entry.isIntersecting) {
            switch (parseInt(index)) {
              case 0:
                return(
                  <>
                  <h3>
                  Why You Need <span>UX Design</span> 
                  </h3>
                  <p>
                  At Apex Creative Design, we specialize in elevating user experience services through top-notch UX, UI, and graphic design solutions. Whether you’re starting from scratch or enhancing an existing digital product, our design team ensures your users enjoy an intuitive, engaging, and visually stunning experience every time.
                  </p>
                  </>
          );
          break;
              case 1:
                return(
                        <>
                        <h3>
                        Why You Need <span>UX Design</span> 
                        </h3>
                        <p>
                        At Apex Creative Design, we specialize in elevating user experience services through top-notch UX, UI, and graphic design solutions. Whether you’re starting from scratch or enhancing an existing digital product, our design team ensures your users enjoy an intuitive, engaging, and visually stunning experience every time.
                        </p>
                        </>
                );
                break;
                case 2:
                return(
                        <>
                        <h3>
                        Why You Need <span>UX Design</span> 
                        </h3>
                        <p>
                        At Apex Creative Design, we specialize in elevating user experience services through top-notch UX, UI, and graphic design solutions. Whether you’re starting from scratch or enhancing an existing digital product, our design team ensures your users enjoy an intuitive, engaging, and visually stunning experience every time.
                        </p>
                        </>
                );
                break;
                
              case 3:
                setIsInFifthSection(true);  // When the fifth section is visible
                break;
              default:
                break;
            }
          } else {
            if (parseInt(index) === 3) {
              setIsInFifthSection(false); // Reset when leaving fifth section
            }
          }
        });
      }, options);
  
      textSections.current.forEach((section) => {
        if (section) observer.observe(section);
      });
  
      return () => {
        textSections.current.forEach((section) => {
          if (section) observer.unobserve(section);
        });
      };
    }, []);
  
    useEffect(() => {
      const handleScroll = () => {
        if (portfolioRef.current) {
          const rect = portfolioRef.current.getBoundingClientRect();
          setIsImageFixed(rect.top <= 0 && rect.bottom > window.innerHeight);
        }
      };
  
      window.addEventListener("scroll", handleScroll);
  
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);
  return (
    <>
    <section className='designThirdSec'  >
        <div className='container'>
            <div className='row'>
                <div className='col-lg-6'>
                    <div className={`firstTextSec   `}>
                    <h3>
                        Why You Need <span>UX Design</span> 
                        </h3>
                        <p>
                        At Apex Creative Design, we specialize in elevating user experience services through top-notch UX, UI, and graphic design solutions. Whether you’re starting from scratch or enhancing an existing digital product, our design team ensures your users enjoy an intuitive, engaging, and visually stunning experience every time.
                        </p>
                    </div>
                </div>
                <div className='col-lg-6'>
                <div className='firstTextSec'>
                <div
                  className="portfolio-text-div"
                  data-index="0"
                  ref={(el) => (textSections.current[0] = el)}
                >
                        <h4>
                        WEB DESIGN
                        </h4>
                        <p>
                        Our UX specialists excel in UX web design and complex interfaces for cutting-edge web applications. With Apex Creative Design, you'll get a product that integrates seamless user flows into an intuitive and user-friendly interface. We follow UX design principles to ensure your website is accessible, compatible, and stands out from the competition.
                        </p>
                        </div>
                        <div
                  className="portfolio-text-div"
                  data-index="1"
                  ref={(el) => (textSections.current[1] = el)}
                >
                        <h4>
                        APP DESIGN
                        </h4>
                        <p>
                        Designing a standout mobile app requires the perfect mix of following industry standards and adding unique touches. At Apex Creative Design, our experts create flawless user experience designs for iOS and Android apps, ensuring your app looks great and functions seamlessly on every device.
                        </p>
                        </div>
                        <div
                  className="portfolio-text-div"
                  data-index="2"
                  ref={(el) => (textSections.current[2] = el)}
                >
                        <h4>
                        PROTOTYPING
                        </h4>
                        <p>
                        We bring your ideas to life quickly and precisely, from mockups and wireframes to animations and interactive prototypes. Apex Creative Design starts prototyping after thorough research and planning, ensuring every step is aligned with your vision and user needs.
                        </p>
                        </div>
                        <div
                  className="portfolio-text-div"
                  data-index="3"
                  ref={(el) => (textSections.current[3] = el)}
                >
                        <h4>
                        Data-Driven Design
                        </h4>
                        <p>
                        Data is the backbone of compelling, user-centered design. At Apex Creative Design, we leverage real user data to refine your app's performance. Whether you're collecting data or planning to, our experts help define performance metrics, set up analytics tools, and adjust user flows to optimize the user experience and hit your KPIs.
                        </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
   

    {/* Optional styles */}
    <style jsx>{`
      .firstTextSec.fixed {
    position: fixed;
    top: 100px;
    width: 515px;
}
    
.firstTextSec.move-to-top {
    bottom: 65%;
    width: 515px;
    position: fixed;
    top: 100px;
}

    `}</style>
    </>
    
  )
}
