import React from "react";

export const FourthSec = () => {
  return (
    <>
      <section className="designThirdSec text-center">
        <div className="container">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="firstTextSecght">
                  <h3>
                    <span>What to Expect from </span> <br /> Digital Branding
                  </h3>
                  <p>
                    At Apex Creative Design, our Digital Branding Services are
                    designed to help your business grow and stand out in today’s
                    competitive digital landscape. Here’s what you can expect
                    when you choose to elevate your brand with us.
                  </p>
                </div>
              </div>
            </div>
            <div className="uiForthSec-btm">
              <div className="row justify-content-center">
                <div className="col-lg-5">
                  <div className="uiForthSec-btm-lft">
                    <ul>
                      <li>A Unique Brand Identity</li>
                      <li>Consistency Across Platforms</li>
                      <li>Improved Customer Engagement </li>
                      <li>Tailored Strategies for Growth </li>
                      <li>Enhanced Online Presence</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
