import React from 'react'

export const Banner = () => {
  return (
    <>
    <section className='digitalBanner'>
    <div className='container'>
        <div className='row align-items-center'>
            <div className='col-lg-12'>
                <h2>
                    <span>
                    UI DESIGN
                    </span><br/>
                    Services
                </h2>
                <p>
                At Apex Creative Design, creativity seamlessly integrates with functionality. We emphasize creating user interfaces that are not only visually stunning but also highly usable. Our goal is to craft digital products with interfaces that provide a seamless, intuitive, and enjoyable experience for every user.
                </p>
            </div>
              
        </div>
    </div>
    </section>
    </>
  )
}
