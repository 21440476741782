import React from "react";
import { Banner } from '../../CustomFrontEndApplication/Banner'
import { SecondSec } from '../../CustomFrontEndApplication/SecondSec'
import { ThirdSec } from '../../CustomFrontEndApplication/ThirdSec'
import { FourthSec } from '../../CustomFrontEndApplication/FourthSec'
import { FifthSec } from '../../CustomFrontEndApplication/FifthSec'
import { SixtySec } from '../../CustomFrontEndApplication/SixtySec'
import { SeventhSec } from "../../CustomFrontEndApplication/SeventhSec";

export const CustomFrontEndApplication = () => {
  return (
    <>
      <Banner />
      <SecondSec />
      <ThirdSec />
      <FourthSec />
      <FifthSec />
      {/* <SixtySec /> */}
      <SeventhSec />
    </>
  );
};
