import logo from './component/assets/logo-white.png';
import './App.css';
import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './component/screens/Home';
import Header from './component/HeaderFooterr/Header';
import Footer from './component/HeaderFooterr/Footer';
import SidebarContactus from './component/HomeComponent/SidebarContactus';
import { Digitalmarketing } from './component/screens/servicesPages/Digitalmarketing';
import { WebsiteDevelopment } from './component/screens/servicesPages/WebsiteDevelopment';
import { SocialMediaMarketing } from './component/screens/servicesPages/SocialMediaMarketing';
import { ProfessionalSeo } from './component/screens/servicesPages/ProfessionalSeo';
import { PpcMarketing } from './component/screens/servicesPages/PpcMarketing';
import { AppDevelopment } from './component/screens/servicesPages/AppDevelopment';
import { UxDesign } from './component/screens/DelightingDesign/UxDesign';
import { UiDesign } from './component/screens/DelightingDesign/UiDesign';
import { WebDeveloping } from './component/screens/DevelopingLasting/WebDeveloping';
import { CustomApplication } from './component/screens/DevelopingLasting/CustomApplication';
import { CustomFrontEndApplication } from './component/screens/DevelopingLasting/CustomFrontEndApplication';
import { DigitalBranding } from './component/screens/DelightingDesign/DigitalBranding';
import { BookDesign } from './component/screens/DelightingDesign/BookDesign';
import { LogoDesign } from './component/screens/DelightingDesign/LogoDesign';
import { MockupDesign } from './component/screens/DelightingDesign/MockupDesign';
import { PackagingDesign } from './component/screens/DelightingDesign/PackagingDesign';
import { StationaryDesign } from './component/screens/DelightingDesign/StationaryDesign';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import { About } from './component/screens/About';
import { Portfollio } from './component/screens/Portfollio';
import { Packages } from './component/screens/Packages';
import { ConatctUs } from './component/screens/ConatctUs';
import ScrollToTop from './component/ScrollComp/ScrollToTop';
import useScrollRestoration from './component/ScrollComp/useScrollRestoration';

function Loader() {
  return (
    <div className="loader">
      <img src={logo} alt="Loading..." />
    </div>
  );
}

function App() {
  useScrollRestoration(); // Use the custom hook

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    AOS.init();

    // Hide the loader after 3 seconds
    const timer = setTimeout(() => setLoading(false), 3000);

    // Load Tawk.to script
    const Tawk_API = window.Tawk_API || {};
    const Tawk_LoadStart = new Date();
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://embed.tawk.to/672139ee4304e3196ada4d59/1ibcskjgt";
    script.charset = "UTF-8";
    script.setAttribute("crossorigin", "*");
    document.body.appendChild(script);

    return () => {
      clearTimeout(timer); // Cleanup on component unmount
      document.body.removeChild(script); // Cleanup Tawk.to script if needed
    };
  }, []);

  return (
    <div className="App">
      {loading ? (
        <div className="loader">
          <div className="buffer-ring"></div>
          <img src={logo} alt="Loading..." />
        </div>
      ) : (
        <>
          <Header />
          <ScrollToTop/>
          <SidebarContactus />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/digital-marketing" element={<Digitalmarketing />} />
            <Route path="/website-development" element={<WebsiteDevelopment />} />
            <Route path="/social-media-marketing" element={<SocialMediaMarketing />} />
            <Route path="/professional-seo" element={<ProfessionalSeo />} />
            <Route path="/ppc-marketing" element={<PpcMarketing />} />
            <Route path="/app-development" element={<AppDevelopment />} />
            <Route path="/ux-design" element={<UxDesign />} />
            <Route path="/ui-design" element={<UiDesign />} />
            <Route path="/web-developing" element={<WebDeveloping />} />
            <Route path="/custom-application" element={<CustomApplication />} />
            <Route path="/custom-frontend-application" element={<CustomFrontEndApplication />} />
            <Route path="/digital-branding" element={<DigitalBranding />} />
            <Route path="/book-design" element={<BookDesign />} />
            <Route path="/logo-design" element={<LogoDesign />} />
            <Route path="/mockup-design" element={<MockupDesign />} />
            <Route path="/packaging-design" element={<PackagingDesign />} />
            <Route path="/stationary-design" element={<StationaryDesign />} />
            <Route path="/about" element={<About />} />
            <Route path="/portfollio" element={<Portfollio />} />
            <Route path="/packages" element={<Packages />} />
            <Route path="/contactus" element={<ConatctUs />} />
          </Routes>
          <Footer />
        </>
      )}
    </div>
  );
}

export default App;
