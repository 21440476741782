import React from "react";

export const SixtySec = () => {
  return (
    <>
      <section className="designSixthSec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12"></div>
          </div>
          <div className="row justify-content-center item-content-center gap-5">
            <div className="col-lg-8">
              <div className="partnerApex">
                <h2>
                  Design <br />
                  <span>Process </span>
                </h2>
                <p>
                  At Apex Creative Design, our design process is a blend of
                  creativity, research, and collaboration. We focus on
                  delivering tailored solutions that align with your business
                  goals and create meaningful user experiences. Here’s how we
                  bring your vision to life:
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="portfolio-text-sec-left" data-aos="fade-right" data-aos-duration="1000">
                  <h6>STEP 01</h6>
                  <h4>
                    Discovery & <br /> Research
                  </h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6"></div>
              <div className="col-lg-6">
               <div className="portfolio-text-sec-right" data-aos="fade-left" data-aos-duration="1000">
                  <h6>STEP 02</h6>
                  <h4>
                    Strategy &<br /> Planning
                  </h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="portfolio-text-sec-left" data-aos="fade-right" data-aos-duration="1000">
                  <h6>STEP 03</h6>
                  <h4>
                  Wireframing &<br />Prototyping 
                  </h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6"></div>
              <div className="col-lg-6">
               <div className="portfolio-text-sec-right" data-aos="fade-left" data-aos-duration="1000">
                  <h6>STEP 04</h6>
                  <h4>
                   Design &<br /> Development
                  </h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="portfolio-text-sec-left" data-aos="fade-right" data-aos-duration="1000">
                  <h6>STEP 05</h6>
                  <h4>
                  Feedback &<br />Iteration
                  </h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6"></div>
              <div className="col-lg-6">
               <div className="portfolio-text-sec-right" data-aos="fade-left" data-aos-duration="1000">
                  <h6>STEP 06</h6>
                  <h4>
                  Final Review &<br /> Launch
                  </h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="portfolio-text-sec-left" data-aos="fade-right" data-aos-duration="1000">
                  <h6>STEP 07</h6>
                  <h4>
                  Ongoing <br/>Support & <br />Growth
                  </h4>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </section>
    </>
  );
};
