import React from 'react'
import './Homeservices.css'
import { SectionOne } from './services_sections/SectionOne'
import { SectionTwo } from './services_sections/SectionTwo'
import { SectionThree } from './services_sections/SectionThree'
import { SectionFour } from './services_sections/SectionFour'
import { SectionFive } from './services_sections/SectionFive'

export const HomeService = () => {
  return (
    <>
        <section className="home-services-sec">
            <h3 className="overlay-text">SERVICES</h3> 
        <div className="top-content">
            <div className="container">
                <h2 className="section-title">See What We Can Do For You</h2>
            </div>
        </div>
        <div className="services">
           
            <SectionOne/>
            <SectionTwo/>
            <SectionThree/>
            <SectionFour/>
            <SectionFive/>
            
        </div>
    </section>
    </>
  )
}
