import React from 'react'
import { Hero } from '../PackagesComponent/Hero'
import PackagesMain from '../PackagesComponent/Packages'

export const Packages = () => {
  return (
    <>
    <Hero/>
    <PackagesMain/>
    </>
  )
}
