import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

export const FifthSec = () => {
  var settings = {
    // dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: true,
    className: "myCustomCarousel",
  };
  return (
    <>
      <section className="materialDigital" style={{background:'#232323'}}>
        <div className="container">
          <div className="row workwithuk">
            <div className="col-lg-8">
              <div className="colEight">
                <h3>
                  <span>What to Expect from </span><br/>Custom Application Development
                </h3>
                <p>
                Partnering with Apex Creative Design for custom application development services means you can expect a smooth and collaborative process. Our team works closely with you to ensure every aspect of your application aligns with your business goals.
                </p>
              </div>
            </div>
            <Slider {...settings}>
                <div>
                  <div className="box-digital">
                    <img src={require("../assets/person-filled.png")} />
                    <h4>Tailored Solutions for Your Business</h4>
                    <p>
                    Every project we take on is customized to fit your specific needs. We work with you to understand your vision, objectives, and target audience, delivering a solution that reflects your brand and meets your goals.
                    </p>
                  </div>
                </div>
                <div>
                  <div className="box-digital">
                    <img src={require("../assets/ux-design_1370315.png")} />
                    <h4>High-Quality User Experience</h4>
                    <p>
                    We prioritize user experience in every application we build. Whether it's an e-commerce platform or a content management system, our focus is on creating applications that are easy to navigate, visually engaging, and designed to keep users coming back.
                    </p>
                  </div>
                </div>
                <div>
                  <div className="box-digital">
                    <img src={require("../assets/design-reniew.png")} />
                    <h4>Secure and Reliable Development</h4>
                    <p>
                    Security is a top priority in our custom web application development process. We implement the latest security measures to protect your data and ensure your application remains secure, reliable, and compliant with industry standards.
                    </p>
                  </div>
                </div>
              </Slider>
           
            <div className="col-lg-12">
              <div className="colEight">
                <h3>
                  <span>Apex Creative Design:</span> Your Custom Application Development Partner
                </h3>
                <p>
                At Apex Creative Design, we're dedicated to delivering custom application development services that help your business grow. Whether you need a new e-commerce site, a custom CMS, or an MVP, our team of experts is here to bring your vision to life.
                </p>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-2">
                <img width={150} src={require("../assets/dashicons_awards.png")} />
              </div>
              <div className="col-lg-10">
                <div className="colEight">
                  <h3>Industry Expertise</h3>
                  <p>
                  With years of experience across various industries, we understand the unique challenges businesses face when developing custom applications. Our solutions are built to address these challenges while providing a competitive edge in the marketplace.
                  </p>
                </div>
              </div>
            </div>
            <div className="div-border-line"></div> 
            <div className="row align-items-center">
              <div className="col-lg-2">
                <img width={150} src={require("../assets/data_drive_fromt.png")} />
              </div>
              <div className="col-lg-10">
                <div className="colEight">
                  <h3>Data-Driven Development</h3>
                  <p>
                  We take a data-driven approach to development, using analytics and user feedback to inform our decisions. This ensures that your application is optimized for performance, user engagement, and business success.
                  </p>
                </div>
              </div>
            </div>
            <div className="div-border-line"></div>
            <div className="row align-items-center">
              <div className="col-lg-2">
                <img width={150} src={require("../assets/business_1513923.png")} />
              </div>
              <div className="col-lg-10">
                <div className="colEight">
                  <h3>Agile Development Process</h3>
                  <p>
                  Our agile development process ensures that your project stays on track, with regular check-ins and updates to keep you informed. This approach allows for flexibility and ensures that your application is delivered on time and within budget.
                  </p>
                </div>
              </div>
            </div>
            <div className="div-border-line"></div>
            <div className="row align-items-center">
              <div className="col-lg-2">
                <img width={150} src={require("../assets/assessment_16761775w2.png")} />
              </div>
              <div className="col-lg-10">
                <div className="colEight">
                  <h3>Scalable and Future-Proof Solutions</h3>
                  <p>
                  We build applications that are designed to grow with your business. Whether you're adding new features, expanding your user base, or scaling operations, our custom application development services provide the flexibility you need for long-term success.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
