import React from 'react'
import { FaArrowAltCircleRight } from 'react-icons/fa'
import { Link } from 'react-router-dom'

export const SectionOne = () => {
  return (
    <>
        <div className="service-item right bottom">
                <div className="container d-flex align-items-center">
                    <div className="left" data-aos="fade-right">
                        <h3 className="title">App Development</h3>
                        <p className="short-description">Regarding iOS app development, Apex Creative Designs is a leader in the field. Our team of skilled iPhone app developers is dedicated to creating apps that run seamlessly on all Apple devices. From initial concept to final launch, we ensure every aspect of your iOS app is designed to provide an exceptional user experience. Our iOS development process is thorough and meticulous, ensuring that your app looks great and performs flawlessly.</p>
                        <div className="service-links">
                            <ul>
                                <li><a className="iphone" href="#">iPhone App Development</a></li>
                                <li><a className="flutter" href="#">Flutter App Development</a></li>
                                <li><a className="android" href="#">Android App Development</a></li>
                                <li><a className="ionic" href="#">Ionic App Development</a></li>
                            </ul>
                        </div>
                        <div className="cta-button">
                            <Link className="primary-btn get-center hvr-shutter-out-horizontal btn" to="/contact-us"><span>Talk To Our Experts  </span><FaArrowAltCircleRight className='banner-icon' /></Link> 
                            </div>
                    </div>
                    <div className="right">
                        <div className="service-image text-center">
                            <picture> <img decoding="async" data-aos="fade-left" className=" ls-is-cached lazyloaded" src={require('../../assets/mobile-application-development.webp')} data-src={require('../../assets/mobile-application-development.webp')} data-srcset={require('../../assets/mobile-application-development.webp')} alt="Mobile Application Development" height="516" width="512" srcset={require('../../assets/mobile-application-development.webp')}/> </picture>
                        </div>
                    </div>
                </div>
            </div>
    </>
  )
}
