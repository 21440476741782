import React from 'react'
import Main from '../HomeComponent/Main'
import SecondSec from '../HomeComponent/SecondSec'
import FifthSec from '../HomeComponent/FifthSec'
import { Testimonial } from '../HomeComponent/Testiomonial'
import Packages from '../HomeComponent/Packages'
import { OurPortfolio } from '../HomeComponent/OurPortfolio'
import ThirdSec from '../HomeComponent/ThirdSec'
import { PrvPortfolio } from '../HomeComponent/PrvPortfolio'
import Portfolio from '../HomeComponent/Portfolio'
import { HomeService } from '../HomeComponent/HomeService'

const Home = () => {
  return (
    <>
      <Main/>
      <SecondSec/>
      {/* <ThirdSec /> */}
      {/* <OurPortfolio/> */}
      <HomeService/>
      {/* <PrvPortfolio /> */}
      <Portfolio />
      <FifthSec />
      <Packages/>
      <Testimonial/>
    </>
  )
}

export default Home
