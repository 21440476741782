import React from "react";
import "./packages.css";
import Slider from "react-slick";

const PackagesMain = () => {
  var settings = {
    dots: false,
    infinite: true,
    // initialSlide: 3,
    // infinite: false,
    // adaptiveHeight: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: true,
    className: "myCustomCarousel",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };
  return (
    <>
      <section id="price-plans-section">
        <div className="container">
          <div className="row">
            <div className="heading-text" style={{ textalign: "center" }}>
              <h3>Packages </h3>
            </div>

            {/* TABS PRICE START */}
            <ul className="nav nav-tabs d-lg-flex" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="home-tab09"
                  data-bs-toggle="tab"
                  data-bs-target="#home-tab-pane09"
                  type="button"
                  role="tab"
                  aria-controls="home-tab-pane09"
                  aria-selected="true"
                >
                  Websites
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="profile-tab08"
                  data-bs-toggle="tab"
                  data-bs-target="#profile-tab-pane08"
                  type="button"
                  role="tab"
                  aria-controls="profile-tab-pane08"
                  aria-selected="false"
                >
                  Logos
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="profile-tab07"
                  data-bs-toggle="tab"
                  data-bs-target="#profile-tab-pane07"
                  type="button"
                  role="tab"
                  aria-controls="profile-tab-pane07"
                  aria-selected="false"
                >
                  Branding
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="profile-tab06"
                  data-bs-toggle="tab"
                  data-bs-target="#profile-tab-pane06"
                  type="button"
                  role="tab"
                  aria-controls="#profile-tab-pane06"
                  aria-selected="false"
                >
                  Social Media Marketing
                </button>
              </li>

              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="profile-tab05"
                  data-bs-toggle="tab"
                  data-bs-target="#profile-tab-pane05"
                  type="button"
                  role="tab"
                  aria-controls="#profile-tab-pane05"
                  aria-selected="false"
                >
                  SEO
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="profile-tab04"
                  data-bs-toggle="tab"
                  data-bs-target="#profile-tab-pane04"
                  type="button"
                  role="tab"
                  aria-controls="#profile-tab-pane04"
                  aria-selected="false"
                >
                  Animations
                </button>
              </li>
            </ul>
            {/* TABS PRICE END */}

            {/* WEBSITE PRICE PLAN SECTION */}
            <div
              className="tab-content accordion ghm"
              id="myTabContent"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              <div
                className="tab-pane fade show active accordion-item"
                id="home-tab-pane09"
                role="tabpanel"
                aria-labelledby="home-tab09"
                tabindex="0"
              >
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse show  d-lg-block"
                  aria-labelledby="headingOne"
                  data-bs-parent="#myTabContent"
                >
                  <div className="container PackageSlick">
                    <Slider {...settings}>
                      <div className="price-inner-plan">
                        <h2 className="heading-pricing-colors">
                          ESSENTIAL <br /> <span>PLAN</span>
                        </h2>
                        <p>Value Added Design Solution</p>
                        <div className="price-list">
                          <ol>
                            <li>3 Page Website</li>
                            <li>2 Stock Images</li>
                            <li>Contact/Query Form</li>
                            <li>Complete Deployment</li>
                            <li>Dedicated Account Manager</li>
                            <li>48 to 72 hours TAT</li>
                            <li>100% Satisfaction Guaranteed</li>
                            <li>100% Unique Design Guaranteed</li>
                          </ol>
                        </div>
                        <div className="price-heading-text">
                          {/* <h2>E-Commerce Basic</h2> */}
                          <h4>€199.00</h4>
                        </div>
                      </div>
                      <div className="price-inner-plan">
                        <h2 className="heading-pricing-colors">
                          TEAM <br /> <span>PLAN</span>
                        </h2>
                        <p>Value Added Design Solution</p>
                        <div className="price-list">
                          <ol>
                            <li>5-6 Unique Pages Website</li>
                            <li>5 Stock Photos</li>
                            <li>1 Banner Design</li>
                            <li>1 jQuery Slider Banner</li>
                            <li>Google Friendly Sitemap</li>
                            <li>Complete Deployment</li>
                            <li>W3C Certified HTML</li>
                            <li>100% Owner Rights</li>
                            <li>Cross Browser Compatible</li>
                            <li>Dedicated Account Manager</li>
                            <li>48 to 72 hours TAT</li>
                            <li>100% Satisfaction Guaranteed</li>
                            <li>100% Unique Design Guaranteed</li>
                          </ol>
                        </div>
                        <div className="price-heading-text">
                          {/* <h2>E-Commerce Professional</h2> */}
                          <h4>€499.00</h4>
                        </div>
                      </div>
                      <div className="price-inner-plan">
                        <h2 className="heading-pricing-colors">
                          CORPORATE <br /> <span>PLAN</span>
                        </h2>
                        <p>Value Added Design Solution</p>
                        <div className="price-list">
                          <ol>
                            <li>10 Unique Pages Website</li>
                            <li>CMS Integration</li>
                            <li>Custom WordPress Backend</li>
                            <li>8 Stock images</li>
                            <li>3 Slider Banner Design</li>
                            <li>1 jQuery Slider Banner</li>
                            <li>FREE Google Friendly Sitemap</li>
                            <li>Complete Deployment</li>
                            <li>W3C Certified HTML</li>
                            <li>Mobile Responsive</li>
                            <li>Cross Browser Compatible</li>
                            <li>Dedicated Account Manager</li>
                            <li>48 to 72 hours TAT</li>
                            <li>100% Satisfaction Guaranteed</li>
                            <li>100% Unique Design Guaranteed</li>
                          </ol>
                        </div>
                        <div className="price-heading-text">
                          {/* <h2>E-Commerce Corporate</h2> */}
                          <h4>€799.00</h4>
                        </div>
                      </div>
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
            {/* WEBSITE PRICE SECTION END */}

            {/* LOGOS PRICE PLAN Start */}
            <div
              className="tab-pane fade accordion-item prr"
              id="profile-tab-pane08"
              role="tabpanel"
              aria-labelledby="profile-tab08"
              tabindex="0"
            >
              <div
                id="collapseTwo"
                className="accordion-collapse collapse d-lg-block"
                aria-labelledby="headingTwo"
                data-bs-parent="#myTabContent"
              >
                <div className="container PackageSlick">
                  <Slider {...settings}>
                    <div className="price-inner-plan">
                      <h2 className="heading-pricing-colors">ESSENTIAL</h2>
                      <p>Value Added Design Solution</p>
                      <div className="price-list">
                        <ol>
                          <li>2 Logo Concepts</li>
                          <li>4 Revisions (Additional $20)</li>
                          <li>By 2 Top Rated Dedicated Designers</li>
                          <li>24 - 48 Hours Turnaround Time</li>
                          <li>Final Files (Jpeg, PNG)</li>
                        </ol>
                      </div>
                      <div className="price-heading-text">
                        {/* <h2>Logos Basic</h2> */}
                        <h4>€49.00</h4>
                      </div>
                    </div>

                    <div className="price-inner-plan">
                      <h2 className="heading-pricing-colors">TEAM</h2>
                      <p>Value Added Design Solution</p>
                      <div className="price-list">
                        <ol>
                          <li>4 Logo Concepts</li>
                          <li>
                            Free Business Card Design Stationery Design
                            (Business Cards, Letterhead, Invoice)
                          </li>
                          <li>Free Icon</li>
                          <li>24 - 48 Hours Turnaround Time</li>
                          <li>Final Files (PDF, SVG, EPS, Jpeg, PNG, Tif)</li>
                        </ol>
                      </div>
                      <div className="price-heading-text">
                        {/* <h2>Logos Professional</h2> */}
                        <h4>€119.00</h4>
                      </div>
                    </div>

                    <div className="price-inner-plan">
                      <h2 className="heading-pricing-colors">CORPORATE</h2>
                      <p>Value Added Design Solution</p>
                      <div className="price-list">
                        <ol>
                          <li>6 Logo Concepts</li>
                          <li>12 Revisions</li>
                          <li>By 2 Top Rated Dedicated Designers</li>
                          <li>
                            Free Business Card Design Stationery Design
                            (Business Cards, Letterhead, Invoice)
                          </li>
                          <li>Free Icon </li>
                          <li>24 - 48 Hours Turnaround Time</li>
                          <li>Final Files (PDF, SVG, EPS, Jpeg, PNG, Tiff)</li>
                        </ol>
                      </div>
                      <div className="price-heading-text">
                        {/* <h2>Logos Corporate</h2> */}
                        <h4>€249.00</h4>
                      </div>
                    </div>
                  </Slider>
                  <div
                    className="custom-price-button"
                    style={{ paddingTop: "70px" }}
                  >
                    <button>Get Started</button>
                  </div>
                </div>
              </div>
            </div>
            {/* LOGOS PRICE PLAN END */}

            {/* BRANDING PRICE PLAN Start */}
            <div
              className="tab-pane fade accordion-item prr"
              id="profile-tab-pane07"
              role="tabpanel"
              aria-labelledby="profile-tab07"
              tabindex="0"
            >
              <div
                id="collapseTwo"
                className="accordion-collapse collapse d-lg-block"
                aria-labelledby="headingTwo"
                data-bs-parent="#myTabContent"
              >
                <div className="container PackageSlick">
                  <Slider {...settings}>
                    <div className="price-inner-plan">
                      <h2 className="heading-pricing-colors">
                        Startup <br /> <span>Collateral</span>
                      </h2>
                      <p>Best Value for Money Guaranteed!</p>
                      <div className="price-list">
                        <ol>
                          <li>2 Stationery Design Set</li>
                          <li>FREE Fax Template</li>
                          <li>Print Ready Formats</li>
                          <li>UNLIMITED Revisions</li>
                          <li>100% Satisfaction Guarantee</li>
                          <li>100% Money Back Guarantee</li>
                        </ol>
                      </div>
                      <div className="price-heading-text">
                        {/* <h2>Logos Basic</h2> */}
                        <h4>€99</h4>
                      </div>
                    </div>

                    <div className="price-inner-plan">
                      <h2 className="heading-pricing-colors">
                        Collateral <br /> <span>Classic</span>
                      </h2>
                      <p>Best Value for Money Guaranteed!</p>
                      <div className="price-list">
                        <ol>
                          <li>4 Stationery Design Set</li>
                          <li>UNLIMITED Revisions</li>
                          <li>Flyer Design</li>
                          <li>Brochure Design (Bi-fold/Tri-fold)</li>
                          <li>100% Satisfaction Guarantee</li>
                          <li>100% Money Back Guarantee</li>
                        </ol>
                      </div>
                      <div className="price-heading-text">
                        {/* <h2>Logos Professional</h2> */}
                        <h4>€199</h4>
                      </div>
                    </div>

                    <div className="price-inner-plan">
                      <h2 className="heading-pricing-colors">
                        Premium <br /> <span>Collateral</span>
                      </h2>
                      <p>Best Value for Money Guaranteed!</p>
                      <div className="price-list">
                        <ol>
                          <li>6 Stationery Design Set</li>
                          <li>Packaging Design</li>
                          <li>UNLIMITED Revisions</li>
                          <li>T-Shirt Design</li>
                          <li>100% Satisfaction Guarantee </li>
                          <li>100% Money Back Guarantee</li>
                        </ol>
                      </div>
                      <div className="price-heading-text">
                        {/* <h2>Logos Corporate</h2> */}
                        <h4>€399</h4>
                      </div>
                    </div>
                  </Slider>
                  <div
                    className="custom-price-button"
                    style={{ paddingTop: "70px" }}
                  >
                    <button>Get Started</button>
                  </div>
                </div>
              </div>
            </div>
            {/* BRANDING PRICE PLAN END  */}

            {/* Social Media Marketing PRICE PLAN Start */}
            <div
              className="tab-pane fade accordion-item prr"
              id="profile-tab-pane06"
              role="tabpanel"
              aria-labelledby="profile-tab06"
              tabindex="0"
            >
              <div
                id="collapseTwo"
                className="accordion-collapse collapse d-lg-block"
                aria-labelledby="headingTwo"
                data-bs-parent="#myTabContent"
              >
                <div className="container PackageSlick">
                  <Slider {...settings}>
                    <div className="price-inner-plan">
                      <h2 className="heading-pricing-colors">
                        Startup <br /> <span>Plan</span>
                      </h2>
                      <p>Quarterly Plan</p>
                      <div className="price-list">
                        <ol>
                          <li>Features: 12 Post Per Month</li>
                          <li>Reels Posts: 3 Reel Per Month</li>
                          <li>Graphics Posts: 4 Graphics Per Month</li>
                          <li>Carousel: 5 Carousel Per Month</li>
                          <li>
                            Stories+Highlights (Complimentary): 2
                            Stories/Highlights
                          </li>
                          <li>Number Of Networks: 2 Networks</li>
                          <li>Social Media Marketing Strategy</li>
                          <li>Dedicated Social Media Account Manager</li>
                          <li>
                            Daily/Weekly Monitoring Of Included Social Assets
                          </li>
                        </ol>
                      </div>
                      <div className="price-heading-text">
                        {/* <h2>Logos Basic</h2> */}
                        <h4>€1125</h4>
                      </div>
                    </div>

                    <div className="price-inner-plan">
                      <h2 className="heading-pricing-colors">
                        Scaling <br /> <span>Plan</span>
                      </h2>
                      <p>Quarterly Plan</p>
                      <div className="price-list">
                        <ol>
                          <li>Features: 16 Post Per Month</li>
                          <li>Reels Posts: 4 Reel Per Month</li>
                          <li>Graphics Posts: 6 Graphics Per Month</li>
                          <li>Carousel: 6 Carousel Per Month</li>
                          <li>
                            Stories+Highlights (Complimentary): 4
                            Stories/Highlights{" "}
                          </li>
                          <li>Number Of Networks: 3 Networks</li>
                          <li>Social Media Marketing Strategy</li>
                          <li>Social Media Competitive Analysis</li>
                          <li>Social Media Brand Reputation Analysis</li>
                          <li>Social Media Audit + Recommendations</li>
                          <li>Dedicated Social Media Account Manager</li>
                          <li>
                            Daily/Weekly Monitoring Of Included Social Assets
                          </li>
                          <li>Standard Monthly Reporting And Analysis</li>
                          <li>Cross-Platform Integration</li>
                          <li>Network Setup & Optimization</li>
                        </ol>
                      </div>
                      <div className="price-heading-text">
                        {/* <h2>Logos Professional</h2> */}
                        <h4>€2250</h4>
                      </div>
                    </div>

                    <div className="price-inner-plan">
                      <h2 className="heading-pricing-colors">
                        Venture <br /> <span>Plan</span>
                      </h2>
                      <p>Quarterly Plan</p>
                      <div className="price-list">
                        <ol>
                          <li>Features: 24 Post Per Month</li>
                          <li>Reels Posts: 6 Reel Per Month</li>
                          <li>Graphics Posts: 9 Graphics Per Month</li>
                          <li>Carousel: 9 Carousel Per Month</li>
                          <li>
                            Stories+Highlights (Complimentary): 6
                            Stories/Highlights{" "}
                          </li>
                          <li>Number Of Networks: 7 Networks</li>
                          <li>Social Media Marketing Strategy</li>
                          <li>Social Media Competitive Analysis</li>
                          <li>Social Media Brand Reputation Analysis</li>
                          <li>Social Media Audit + Recommendations</li>
                          <li>Dedicated Social Media Account Manager</li>
                          <li>
                            Daily/Weekly Monitoring Of Included Social Assets
                          </li>
                          <li>Standard Monthly Reporting And Analysis</li>
                          <li>Cross-Platform Integration</li>
                          <li>Network Setup & Optimization</li>
                        </ol>
                      </div>
                      <div className="price-heading-text">
                        {/* <h2>Logos Corporate</h2> */}
                        <h4>€4725</h4>
                      </div>
                    </div>
                  </Slider>
                  <div
                    className="custom-price-button"
                    style={{ paddingTop: "70px" }}
                  >
                    <button>Get Started</button>
                  </div>
                </div>
              </div>
            </div>
            {/* Social Media Marketing PRICE PLAN END */}

            {/* SEO PRICE PLAN Start */}
            <div
              className="tab-pane fade accordion-item prr"
              id="profile-tab-pane05"
              role="tabpanel"
              aria-labelledby="profile-tab05"
              tabindex="0"
            >
              <div
                id="collapseTwo"
                className="accordion-collapse collapse d-lg-block"
                aria-labelledby="headingTwo"
                data-bs-parent="#myTabContent"
              >
                <div className="container PackageSlick">
                  <Slider {...settings}>
                    <div className="price-inner-plan">
                      <h2 className="heading-pricing-colors">
                        Startup <br /> <span>Plan</span>
                      </h2>
                      <p>Quarterly Plan</p>
                      <div className="price-list">
                        <ol>
                          <li>Features: 10 Keywords</li>
                          <li>Prior Analysis</li>
                          <li>Pages Keyword Targeted: 5 Pages</li>
                          <li>Web Page Optimization</li>
                          <li>On Page Optimization</li>
                          <li>Meta Data</li>
                          <li>Off Page Optimization</li>
                          <li>Content Creation</li>
                          <li>Guest Bloging: 06 Per Month</li>
                          <li>Web 2.0</li>
                          <li>Bussiness Listing</li>
                        </ol>
                      </div>
                      <div className="price-heading-text">
                        {/* <h2>Logos Basic</h2> */}
                        <h4>€750</h4>
                      </div>
                    </div>

                    <div className="price-inner-plan">
                      <h2 className="heading-pricing-colors">
                        Scaling <br /> <span>Plan</span>
                      </h2>
                      <p>Quarterly Plan</p>
                      <div className="price-list">
                        <ol>
                          <li>Features: 25 Keywords</li>
                          <li>Prior Analysis</li>
                          <li>Pages Keyword Targeted: 15 Pages</li>
                          <li>Web Page Optimization</li>
                          <li>On Page Optimization</li>
                          <li>Meta Data</li>
                          <li>Off Page Optimization</li>
                          <li>Content Creation</li>
                          <li>Guest Bloging: 12 Per Month</li>
                          <li>Web 2.0</li>
                          <li>Bussiness Listing</li>
                          <li>Reporting</li>
                          <li>Website Audit</li>
                        </ol>
                      </div>
                      <div className="price-heading-text">
                        {/* <h2>Logos Professional</h2> */}
                        <h4>€1750</h4>
                      </div>
                    </div>

                    <div className="price-inner-plan">
                      <h2 className="heading-pricing-colors">
                        Venture <br /> <span>Plan</span>
                      </h2>
                      <p>Quarterly Plan</p>
                      <div className="price-list">
                        <ol>
                          <li>Features: 35 Keywords</li>
                          <li>Prior Analysis</li>
                          <li>Pages Keyword Targeted: 25 Pages</li>
                          <li>Web Page Optimization</li>
                          <li>On Page Optimization</li>
                          <li>Meta Data</li>
                          <li>Off Page Optimization</li>
                          <li>Content Creation</li>
                          <li>Guest Bloging: 16 Per Month</li>
                          <li>Web 2.0</li>
                          <li>Bussiness Listing</li>
                          <li>Reporting</li>
                          <li>Website Audit</li>
                        </ol>
                      </div>
                      <div className="price-heading-text">
                        {/* <h2>Logos Corporate</h2> */}
                        <h4>€2500</h4>
                      </div>
                    </div>
                  </Slider>
                  <div
                    className="custom-price-button"
                    style={{ paddingTop: "70px" }}
                  >
                    <button>Get Started</button>
                  </div>
                </div>
              </div>
            </div>
            {/* SEO PRICE END PLAN Start */}

            {/* ANIMATIONS PRICE PLAN Start */}
            <div
              className="tab-pane fade accordion-item prr"
              id="profile-tab-pane04"
              role="tabpanel"
              aria-labelledby="profile-tab04"
              tabindex="0"
            >
              <div
                id="collapseTwo"
                className="accordion-collapse collapse d-lg-block"
                aria-labelledby="headingTwo"
                data-bs-parent="#myTabContent"
              >
                <div className="container PackageSlick">
                  <Slider {...settings}>
                    <div className="price-inner-plan">
                      <h2 className="heading-pricing-colors">
                        Startup <br /> <span>Video</span>
                      </h2>
                      <p>15 Sec Video</p>
                      <div className="price-list">
                        <ol>
                          <li>Premium Video References</li>
                          <li>15s Duration</li>
                          <li>Video Quality – HD 1080</li>
                          <li>1 revision allowed for each phase</li>
                          <li>Storyboard </li>
                          <li>Voice Over (optional)</li>
                          <li>Characters & Graphics</li>
                          <li>Animation</li>
                          <li>Final Files Included: MP4</li>
                        </ol>
                      </div>
                      <div className="price-heading-text">
                        {/* <h2>Logos Basic</h2> */}
                        <h4>€299.00</h4>
                      </div>
                    </div>

                    <div className="price-inner-plan">
                      <h2 className="heading-pricing-colors">
                        Basic <br /> <span>Video</span>
                      </h2>
                      <p>30 Sec Video</p>
                      <div className="price-list">
                        <ol>
                          <li>Premium Video References</li>
                          <li>30s Duration – HD 1080</li>
                          <li>2 revisions allowed for each phase</li>
                          <li>Professional Script</li>
                          <li>Storyboard</li>
                          <li>Voice Over </li>
                          <li>Custom Setting, Characters & Graphics</li>
                          <li>Animation Effects</li>
                          <li>Final Files Included: MP4</li>
                          <li>Customize Tailor Made Design</li>
                          <li>User Friendly</li>
                          <li>5 Banner Designs</li>
                        </ol>
                      </div>
                      <div className="price-heading-text">
                        {/* <h2>Logos Professional</h2> */}
                        <h4>€599.00</h4>
                      </div>
                    </div>

                    <div className="price-inner-plan">
                      <h2 className="heading-pricing-colors">
                        Silver <br /> <span>Video</span>
                      </h2>
                      <p>45 Sec Video</p>
                      <div className="price-list">
                        <ol>
                          <li>Premium Video References</li>
                          <li>45s Duration – HD 1080</li>
                          <li>3 revisions allowed for each phase</li>
                          <li>Professional Script</li>
                          <li>Storyboard draft/theme and illustrations</li>
                          <li>Voice Over</li>
                          <li>Custom Setting, Characters & Graphics</li>
                          <li>Animation Effects</li>
                          <li>Final Files Included: MP4</li>
                        </ol>
                      </div>
                      <div className="price-heading-text">
                        {/* <h2>Logos Corporate</h2> */}
                        <h4>€899.00</h4>
                      </div>
                    </div>
                  </Slider>
                  <div
                    className="custom-price-button"
                    style={{ paddingTop: "70px" }}
                  >
                    <button>Get Started</button>
                  </div>
                </div>
              </div>
            </div>
            {/* ANIMATIONS PRICE PLAN END */}
          </div>
        </div>
      </section>
    </>
  );
};

export default PackagesMain;
