import React from 'react'

export const Banner = () => {
  return (
    <>
    <section className='digitalBanner'>
    <div className='container'>
        <div className='row align-items-center'>
            <div className='col-lg-12'>
                <h2>
                    <span>
                    Stationery Design
                    </span><br/>
                    Services
                </h2>
                <p>
                At Apex Creative Design, our stationery design experts are dedicated to making your brand memorable with beautifully crafted business stationery. We know that business stationery design is more than just a letterhead or a business card. It's an essential part of your brand's identity. Our professional stationery designer team creates custom stationery that enhances your company's image and ensures you leave a lasting impression on your clients and customers.
                </p>
            </div>
              
        </div>
    </div>
    </section>
    </>
  )
}
