import React, { useState } from 'react'

export const Banner = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <>
   <section id="home-sec1" className='digitalBanner'>
  <div className="container">
    <div className="row align-items-center">
      <div className="col-lg-7 col-12">
        <div className="headings-home" data-aos="fade-left" data-aos-duration="5000">
        <h1>
                    <span>
                    Mobile App Design 
                    </span><br/>
                    <b>Services</b>
                </h1>
                <p>
                Welcome to Apex Creative Designs, your go-to expert for all things related to mobile app design. In today's fast-paced digital world, having a sleek, functional, and user-friendly mobile app is crucial for engaging your audience and enhancing your business. Our experienced team of designers and developers is here to help you create a mobile app that meets your needs and exceeds your expectations.
                </p>
        </div>
        <div className="buttons">
          <ul>
            <li>
              <a className="open-popup" onClick={openPopup}>Get a quote</a>
            </li>
          </ul>
        </div> 
      </div>
      <div className="col-lg-5 col-12">
        <div className="side-image">
          <img width="100%" src={require('../assets/modileDesign.png')} />
        </div>
      </div>
    </div>
  </div>

  {isPopupOpen && (
          <div className="popup-overlay" id="quote-popup">
            <div className="popup-content">
              <span className="close-popup" onClick={closePopup}>&times;</span>
              <div className="row align-items-center">
                <div className="col-lg-7">
                  <div className="popup-form">
                    <h2>Request a Quote</h2>
                    <p>Fill out the form below and we'll get back to you as soon as possible!</p>
                    <form>
                      <input type="text" placeholder="Your Name" required />
                      <input type="email" placeholder="Your Email" required />
                      <textarea placeholder="Your Message" required></textarea>
                      <button type="submit">Send Message</button>
                    </form>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className='animatedPopupImage'>
                    <img src={require('../assets/digitalMarketting.png')} alt='digital marketing' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

</section>
    </>
  )
}
