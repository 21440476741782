import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

export const FifthSec = () => {
  var settings = {
    // dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: true,
    className: "myCustomCarousel",
  };
  return (
    <>
      <section className="materialDigital" style={{background:'#232323'}} >
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="colEight">
                <h3>
                  <span>Our Design </span><br/>Standards
                </h3>
                <p>
                  Our design process focuses on understanding your current goals
                  while anticipating future needs, ensuring your brand evolves
                  seamlessly over time.
                </p>
              </div>
            </div>
            <div className="row">
              <Slider {...settings}>
                <div>
                  <div className="box-digital">
                    <img src={require("../assets/person-filled.png")} />
                    <h4>USER EXPERIENCE</h4>
                    <p>
                      We design flexible, intuitive, and engaging user journeys
                      that build brand loyalty and consistency.
                    </p>
                  </div>
                </div>
                <div>
                  <div className="box-digital">
                    <img src={require("../assets/ux-design_1370315.png")} />
                    <h4>USER INTERFACE</h4>
                    <p>
                      We capture attention with visually stunning,
                      user-friendly, and responsive designs that elevate the
                      user experience.
                    </p>
                  </div>
                </div>
                <div>
                  <div className="box-digital">
                    <img src={require("../assets/design-reniew.png")} />
                    <h4>DESIGN REVIEW</h4>
                    <p>
                      Our team conducts a detailed analysis of visual design,
                      user interface, and user experience to identify and
                      resolve any usability issues.
                    </p>
                  </div>
                </div>
              </Slider>
            </div>
            <div className="col-lg-12">
              <div className="colEight">
                <h3>
                  <span>How We Help</span> Brands
                </h3>
                <p>
                  We’ve partnered with leading companies and startups across
                  various industries to deliver exceptional design solutions
                  that drive success.
                </p>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-2">
                <img width={150} src={require("../assets/dashicons_awards.png")} />
              </div>
              <div className="col-lg-10">
                <div className="colEight">
                  <h3>Quality</h3>
                  <p>
                    Our collaborative approach ensures a high-quality user
                    experience that aligns with your goals. Through regular
                    check-ins, feedback sessions, and review meetings, we
                    maintain seamless communication and cooperation between your
                    team and our UX experts.
                  </p>
                </div>
              </div>
            </div>
            <div className="div-border-line"></div> 
            <div className="row align-items-center">
              <div className="col-lg-2">
                <img width={150} src={require("../assets/Vector-search.png")} />
              </div>
              <div className="col-lg-10">
                <div className="colEight">
                  <h3>Research</h3>
                  <p>
                    UX research is vital to our UI/UX design services, helping
                    us understand the target audience's needs, preferences, and
                    pain points. Through thorough research, we ensure the final
                    product is user-focused and aligned with design best
                    practices.
                  </p>
                </div>
              </div>
            </div>
            <div className="div-border-line"></div>
            <div className="row align-items-center">
              <div className="col-lg-2">
                <img width={150} src={require("../assets/lets-icons_paper-light.png")} />
              </div>
              <div className="col-lg-10">
                <div className="colEight">
                  <h3>Iteration</h3>
                  <p>
                    Once we’ve mapped out the plan, we begin building a new
                    design library. Our iterative process involves constant
                    collaboration, ensuring that everything is developed in line
                    with your business standards and objectives, with regular
                    updates along the way.
                  </p>
                </div>
              </div>
            </div>
            <div className="div-border-line"></div>
            <div className="row align-items-center">
              <div className="col-lg-2">
                <img width={150} src={require("../assets/assessment_16761775.png")} />
              </div>
              <div className="col-lg-10">
                <div className="colEight">
                  <h3>Assessment</h3>
                  <p>
                    At the start of every project, we work closely with you to
                    fully understand your business challenges. We tailor our
                    discovery process to match your project’s needs and goals,
                    ensuring a focused and efficient approach.
                  </p>
                </div>
              </div>
            </div>
            <div className="div-border-line"></div>
            <div className="row align-items-center">
              <div className="col-lg-2">
                <img width={150} src={require("../assets/check_5610944.png")} />
              </div>
              <div className="col-lg-10">
                <div className="colEight">
                  <h3>Clearance</h3>
                  <p>
                    When the design guidelines, components, and patterns are
                    complete, we assist with the following steps, whether
                    handing them off to your team or working with our
                    developers. We stay engaged throughout the process to ensure
                    smooth integration and support the growth of your design
                    system.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
