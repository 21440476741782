import React from "react";
import { Banner } from '../../CustomApplication/Banner'
import { SecondSec } from '../../CustomApplication/SecondSec'
import { ThirdSec } from '../../CustomApplication/ThirdSec'
import { FourthSec } from '../../CustomApplication/FourthSec'
import { FifthSec } from '../../CustomApplication/FifthSec'
import { SixtySec } from '../../CustomApplication/SixtySec'
import { SeventhSec } from "../../CustomApplication/SeventhSec";

export const CustomApplication = () => {
  return (
    <>
      <Banner />
      <SecondSec />
      <ThirdSec />
      {/* <FourthSec /> */}
      <FifthSec />
      {/* <SixtySec /> */}
      <SeventhSec />
    </>
  );
};
