import React, { useEffect, useRef, useState } from 'react'

export const ThirdSec = () => {
    
  return (
    <>
    <section className='designThirdSec' >
        <div className='container'>
            <div className='row'>
                <div className='col-lg-6'>
                    <div className={`firstTextSec `}>
                    <h3>
                        Discover Our Custom  <span>Front-End Development </span> Capabilities
                        </h3>
                        <p>
                        At Apex Creative Design, we offer a comprehensive range of custom front-end development services designed to bring your digital projects to life. From interactive design to responsive layouts and seamless navigation, our team delivers high-quality front-end solutions that enhance your brand's digital presence.
                        </p>
                    </div>
                </div>
                <div className='col-lg-6'>
                <div className='firstTextSec'>
                <div
                  className="portfolio-text-div"
                  data-index="0"
                >
                        <h4>
                        Responsive Web Design and Development
                        </h4>
                        <p>
                         We design and develop responsive websites that provide a seamless experience across all devices. Whether it's a desktop, tablet, or smartphone, our front-end development ensures your website adapts beautifully to any screen size without sacrificing performance or design quality.
                        </p>
                        </div>
                        <div
                  className="portfolio-text-div"
                  data-index="1"
                >
                        <h4>
                        Interactive UI/UX Design  
                        </h4>
                        <p>
                         Our team creates intuitive, user-centered interfaces that make it easy for visitors to navigate and engage with your website. From animations to interactive elements, we ensure every feature is designed to enhance user experience while reflecting your brand identity.
                        </p>
                        </div>
                        <div
                  className="portfolio-text-div"
                  data-index="2"
                >
                        <h4>
                        Cross-Browser Compatibility
                        </h4>
                        <p>
                        Your website should perform flawlessly across all major browsers. We ensure that your site's front end is compatible with various browsers, including Chrome, Safari, Firefox, and Edge, providing a consistent experience for all users.
                        </p>
                        </div>
                        <div
                  className="portfolio-text-div"
                  data-index="3"
                >
                        <h4>
                        Custom JavaScript Development
                        </h4>
                        <p>
                        Whether you need dynamic content, interactive elements, or real-time updates, we leverage JavaScript to enhance the functionality of your website. Our custom JavaScript solutions are designed to improve user engagement and streamline processes.
                        </p>
                        </div>
                        <div
                  className="portfolio-text-div"
                  data-index="4"
                >
                        <h4>
                        Single Page Application (SPA) Development
                        </h4>
                        <p>
                        SPAs provide a fast, smooth, and responsive user experience by loading only the necessary content when required. We specialize in building SPAs using modern frameworks like React and Angular, ensuring your site delivers a seamless, app-like experience.
                        </p>
                        </div>
                        <div
                  className="portfolio-text-div"
                  data-index="5"
                >
                        <h4>
                        Website Performance Optimization
                        </h4>
                        <p>
                         A fast-loading website is crucial for user retention. Our custom front-end development focuses on optimizing the performance of your website, reducing load times, improving speed, and ensuring a smooth user experience that keeps visitors engaged.
                        </p>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </section>
   

    {/* Optional styles */}
    <style jsx>{`
      .firstTextSec.fixed {
    position: fixed;
    top: 100px;
    width: 515px;
}
    
.firstTextSec.move-to-top {
    bottom: 65%;
    width: 515px;
    position: fixed;
    top: 100px;
}

    `}</style>
    </>
    
  )
}
