import React, { useEffect, useRef, useState } from "react";

export const ThirdSec = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = (index) => setHoveredIndex(index);
  const handleMouseLeave = () => setHoveredIndex(null);

  return (
    <>
      <section className="designThirdSec">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div
                className={`firstTextSec`}
              >
                <h3>
                  Why Choose <span>PPC?</span>
                </h3>
                <p>
                  PPC advertising offers several benefits for businesses of all
                  sizes. Unlike organic marketing strategies, PPC delivers
                  immediate results. When you work with Apex Creative Designs,
                  our pay per click management services will ensure your ads are
                  optimized to reach the right people at the right time. With
                  precise targeting options, you can focus on users based on
                  location, demographics, search behavior, and more.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="firstTextSec">
              {[
                  {
                    title: "Our PPC Management Services",
                    text:
                      "Managing a successful PPC campaign requires expertise, strategy, and constant monitoring. That's where our PPC management services come in. At Apex Creative Designs, we handle every aspect of your PPC campaign, from initial research to ongoing optimization. Our services include:"
                  },
                  {
                    title: "Keyword research",
                    text:
                      "We identify high-performing keywords that are relevant to your business and industry, ensuring your ads are seen by the right audience."
                  },
                  {
                    title: "Ad creation",
                    text:
                      "Our team develops compelling ad copy that resonates with your target audience and encourages clicks."
                  },
                  {
                    title: "Campaign setup",
                    text:
                      "We structure your PPC campaigns in a way that maximizes budget efficiency and ensures your ads are displayed to the right people."
                  },
                  {
                    title: "Ongoing optimization",
                    text:
                      "We monitor the performance of your ads daily, making adjustments to keywords, bids, and targeting to improve results over time."
                  },
                  {
                    title: "Detailed reporting",
                    text:
                      "Transparency is important to us. We provide regular reports so you can see exactly how your campaign is performing and where improvements can be made."
                  },
                  {
                    title: "PPC Cloud Services",
                    text:
                      "With the rise of cloud-based solutions, PPC cloud services offer even more flexibility and efficiency in managing advertising campaigns. Apex Creative Designs takes advantage of the latest PPC cloud technologies to manage and scale your campaigns seamlessly. Our cloud-based approach allows for real-time updates, better tracking, and improved collaboration, ensuring that your campaigns are always up-to-date and aligned with your business goals."
                  },
                  {
                    title: "Combining SEO and PPC for Maximum Results",
                    text:
                      "At Apex Creative Designs, we believe in combining the best of both worlds by integrating SEO PPC services into your digital marketing strategy. While SEO focuses on improving organic search rankings over time, PPC delivers immediate results. By using both strategies together, we create a holistic approach to search engine marketing. Our experts analyze how your organic and paid search campaigns can complement each other to maximize traffic and conversions. For example, while your SEO efforts are building long-term visibility, PPC can drive immediate traffic to your website. Over time, this combination allows you to dominate both the organic and paid sections of search engine results, giving your business the best possible chance of success."
                  },
                ].map((item, index) => (
                  <div
                    className="portfolio-text-div"
                    key={index}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <h4 data-aos="fade-left" data-aos-duration="1000">
                      <b>{item.title}</b>
                    </h4>
                    <p
                      className={`portfolio-text ${
                        hoveredIndex === index ? "show" : ""
                      }`}
                    >
                      {item.text}
                    </p>
                  </div>
                ))}
                {/* <div
                  className="portfolio-text-div"
                  data-index="0"
                 
                >
                  <h4>
                    <b>Our PPC Management Services</b>
                  </h4>
                  <p>
                    Managing a successful PPC campaign requires expertise,
                    strategy, and constant monitoring. That's where our PPC
                    management services come in. At Apex Creative Designs, we
                    handle every aspect of your PPC campaign, from initial
                    research to ongoing optimization. Our services include:
                  </p>
                </div>
                <div
                  className="portfolio-text-div"
                  data-index="1"
                 
                >
                  <h4>Keyword research</h4>
                  <p>
                    We identify high-performing keywords that are relevant to
                    your business and industry, ensuring your ads are seen by
                    the right audience.
                  </p>
                </div>
                <div
                  className="portfolio-text-div"
                  data-index="2"
                 
                >
                  <h4>Ad creation</h4>
                  <p>
                    Our team develops compelling ad copy that resonates with
                    your target audience and encourages clicks.
                  </p>
                </div>
                <div
                  className="portfolio-text-div"
                  data-index="3"
                 
                >
                  <h4>Campaign setup</h4>
                  <p>
                    We structure your PPC campaigns in a way that maximizes
                    budget efficiency and ensures your ads are displayed to the
                    right people.
                  </p>
                </div>
                <div
                  className="portfolio-text-div"
                  data-index="4"
                 
                >
                  <h4>Ongoing optimization</h4>
                  <p>
                    We monitor the performance of your ads daily, making
                    adjustments to keywords, bids, and targeting to improve
                    results over time.
                  </p>
                </div>
                <div
                  className="portfolio-text-div"
                  data-index="5"
                 
                >
                  <h4>Detailed reporting</h4>
                  <p>
                    Transparency is important to us. We provide regular reports
                    so you can see exactly how your campaign is performing and
                    where improvements can be made.
                  </p>
                </div>
                <div
                  className="portfolio-text-div"
                  data-index="6"
                 
                >
                  <h4>PPC Cloud Services</h4>
                  <p>
                  With the rise of cloud-based solutions, PPC cloud services offer even more flexibility and efficiency in managing advertising campaigns. Apex Creative Designs takes advantage of the latest PPC cloud technologies to manage and scale your campaigns seamlessly. Our cloud-based approach allows for real-time updates, better tracking, and improved collaboration, ensuring that your campaigns are always up-to-date and aligned with your business goals.
                  </p>
                </div>
                <div
                  className="portfolio-text-div"
                  data-index="7"
                 
                >
                  <h4>Combining SEO and PPC for Maximum Results</h4>
                  <p>
                  At Apex Creative Designs, we believe in combining the best of both worlds by integrating SEO PPC services into your digital marketing strategy. While SEO focuses on improving organic search rankings over time, PPC delivers immediate results. By using both strategies together, we create a holistic approach to search engine marketing. Our experts analyze how your organic and paid search campaigns can complement each other to maximize traffic and conversions.
                  <br/>
                  <br/>
                  For example, while your SEO efforts are building long-term visibility, PPC can drive immediate traffic to your website. Over time, this combination allows you to dominate both the organic and paid sections of search engine results, giving your business the best possible chance of success.
                  </p>
                </div> */}

              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Optional styles */}
      <style jsx>{`
        .firstTextSec.fixed {
          position: fixed;
          top: 100px;
          width: 515px;
        }

        .firstTextSec.move-to-top {
          bottom: 65%;
          width: 515px;
          position: fixed;
          top: 100px;
        }
      `}</style>
    </>
  );
};
