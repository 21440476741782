import React, { useState } from 'react';

export const Banner = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <>
      <section id="home-sec1" className='digitalBanner'>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7 col-12">
              <div className="headings-home" data-aos="fade-left" data-aos-duration="5000">
                <h1>
                  <span>Social Media Marketing</span>                  <b> Services</b>
                </h1>
                <p>
                  In the digital age, having a solid presence on social media platforms is essential for every business. At Apex Creative Designs, we specialize in providing comprehensive social media marketing services that help your brand grow and connect with your audience in meaningful ways. Whether you are looking to enhance your brand’s visibility or drive engagement, our expert team can tailor solutions that fit your specific goals.
                </p>
              </div>
              <div className="buttons">
                <ul>
                  <li>
                    <a onClick={openPopup} href="#">Get a quote</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-5 col-12">
              <div className="side-image">
                <img width={100} src={require('../assets/smmSec.png')} alt="Social Media Marketing" />
              </div>
            </div>
          </div>
        </div>

        {isPopupOpen && (
          <div className="popup-overlay" id="quote-popup">
            <div className="popup-content">
              <span className="close-popup" onClick={closePopup}>&times;</span>
              <div className="row align-items-center">
                <div className="col-lg-7">
                  <div className="popup-form">
                    <h2>Request a Quote</h2>
                    <p>Fill out the form below and we'll get back to you as soon as possible!</p>
                    <form>
                      <input type="text" placeholder="Your Name" required />
                      <input type="email" placeholder="Your Email" required />
                      <textarea placeholder="Your Message" required></textarea>
                      <button type="submit">Send Message</button>
                    </form>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className='animatedPopupImage'>
                    <img src={require('../assets/digitalMarketting.png')} alt='digital marketing' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};
