import React from "react";
import banner from "../assets/banner.mp4";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <div className="footer-payment-sec">
        <h5>
          Accepted <span> Merchants</span>
        </h5>
        <div class="auto_slider_container">
          <ul class="auto_slider">
            <li>
              <img src={require("../assets/paypal.png")} />
            </li>
            <li>
              <img src={require("../assets/payoneer.png")} />
            </li>
            <li>
              <img src={require("../assets/square.png")} />
            </li>
            <li>
              <img src={require("../assets/mastero.png")} />
            </li>
          </ul>
          <ul class="auto_slider">
            <li>
              <img src={require("../assets/stripe.png")} />
            </li>
            <li>
              <img src={require("../assets/visa.png")} />
            </li>
            <li>
              <img src={require("../assets/mastercard.png")} />
            </li>
            <li>
              <img src={require("../assets/discovernetwork.png")} />
            </li>
            <li>
              <img src={require("../assets/americanexpress.png")} />
            </li>
          </ul>
          <ul class="auto_slider">
            <li>
              <img src={require("../assets/stripe.png")} />
            </li>
            <li>
              <img src={require("../assets/visa.png")} />
            </li>
            <li>
              <img src={require("../assets/mastercard.png")} />
            </li>
            <li>
              <img src={require("../assets/discovernetwork.png")} />
            </li>
            <li>
              <img src={require("../assets/americanexpress.png")} />
            </li>
          </ul>
        </div>
      </div>
      <web-particles id="tsparticles" options='{"fps_limit":60,"interactivity":{"detectsOn":"canvas","events":{"onClick":{"enable":true,"mode":"push"},"onHover":{"enable":true,"mode":"repulse"},"resize":true},"modes":{"push":{"particles_nb":4},"repulse":{"distance":200,"duration":0.4}}},"particles":{"color":{"value":"#ffffff"},"links":{"color":"#ffffff","distance":150,"enable":true,"opacity":0.4,"width":1},"move":{"bounce":false,"direction":"none","enable":true,"outMode":"out","random":false,"speed":2,"straight":false},"number":{"density":{"enable":true,"area":800},"value":80},"opacity":{"value":0.5},"shape":{"type":"circle"},"size":{"random":true,"value":5}},"detectRetina":true}'>
      <canvas class="tsparticles-canvas-el" width="1280" height="560" style={{width: '100%', height: '100%', pointerEvents: 'initial'}}></canvas>
      </web-particles>
      <div className="footer-video-container">
        <section className="footer">
          <div className="container footer main">
            <footer>
              <div className="row">
                <div className="col-lg-4">
                  <div className="d-flex gap-3">
                    <div className="w-100" style={{ textAlign: "left" }}>
                      <img
                        width={10}
                        src={require("../assets/logo-white.png")}
                      />
                      <ul>
                        <li>
                          You’ll find your next Marketing value you prefer.
                        </li>
                        {/* <li>Free Tools</li>
                    <li>Agents</li>
                    <li>Blog</li> */}
                      </ul>
                      <div className="icons">
                        <img
                          className="icon-img"
                          src={require("../assets/logos_facebook.png")}
                        />
                        <img
                          className="icon-img"
                          src={require("../assets/logos_insta.png")}
                        />
                      </div>
                    </div>
                    {/* <div className="w-50">
                     
                    </div> */}
                  </div>
                </div>
                <div className="col-lg-4">
                  <div>
                    <h3>Quick Links</h3>
                    <ul>
                      <li><Link to="/">Home</Link></li>
                      <li><Link to="/about">About Us</Link></li>
                      <li><Link to="/services">Services</Link></li>
                      <li><Link to="/packages">Packages</Link></li>
                      <li><Link to="/contact">Contact Us</Link></li>
                    </ul>
                  </div>
                </div>
                
                <div className="col-lg-4">
                  <div>
                    <h3>Contact Us</h3>
                    <ul>
                      <li>Phone : <a href="tel:+447311830572" className="header-phone">+44 (731) 183-0572</a></li>
                      <li>Email: <a href="mailto:info@apexcreativedesigns.co.uk" className="header-email">info@apexcreativedesigns.co.uk</a></li>
                      <li>
                        A 79, Allenby Road, Lady Margaret road, Southall,
                        Middlesex, UB1 2EZ,
                      </li>
                      <li>UNITED KINGDOM</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="footer-bottom-line"></div>
             <div className="row">
             <div className="col-lg-8">
              <ul className="useful-links">
                      <li>Terms & Condition</li>
                      <li>Privacy Policy</li>
                      <li>Refund Policy</li>
                      <li>Site Map</li>
                    </ul>
              </div>
              <div className="col-lg-4">
              <div className="footer-banner">
                <p>
                  @2024 <span>Apex Creative Designs.</span> All Right Reserved.
                </p>
              </div>
              </div>
             </div>
            </footer>
          </div>
        </section>
      </div>
    </>
  );
};

export default Footer;
