import React, { useState } from 'react'
import { IoIosArrowForward } from 'react-icons/io';

export const SixtySec = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleForm = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
    <section className='designseventhSec'>
        <div className='container'>
            <div className='row align-items-center'>
                <div className='col-lg-6'>
                    <div className='partnerApex'>
                            <h2 data-aos="fade-down" data-aos-duration="1000">
                            <span>Partner With</span><br/>
                            Apex Creative Designs
                            </h2>
                            <p data-aos="fade-up" data-aos-duration="1000">
                            If you want to enhance your brand’s online presence, Apex Creative Designs is here to help. As a trusted social media marketing agency UK, we provide comprehensive services that drive actual results. Whether you need expert consulting, content creation, or management services, our team is ready to craft a strategy that works for you.
                            <br/>
                            <br/>
                            to learn more about our social media marketing services and how we can help your business grow. Together, let’s build a social media presence that connects with your audience and drives long-term success.
                            </p>
                    </div>
                </div>
                <div className='col-lg-6'>
                    <div className='sixtySecImg' data-aos="fade-left" data-aos-duration="1000">
                    <div className="contact-form-container open show">
     
        <div className="contact-form">
          {/* <h2>Contact Us</h2> */}
          <form>
            <input type="text" placeholder="Full Name" />
            <input type="email" placeholder="Email Address" />
            <input type="tel" placeholder="Phone" />
            <textarea placeholder="Project Description"></textarea>
            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
