import React from 'react'
import { Banner } from '../../ProfessionalSeo/Banner'
import { SecondSec } from '../../ProfessionalSeo/SecondSec'
import { ThirdSec } from '../../ProfessionalSeo/ThirdSec'
import { FourthSec } from '../../ProfessionalSeo/FourthSec'
import { FifthSec } from '../../ProfessionalSeo/FifthSec'
import { SixtySec } from '../../ProfessionalSeo/SixtySec'

export const ProfessionalSeo = () => {
  return (
    <>
    <Banner/>
    <SecondSec/>
    <ThirdSec/>
    {/* <FourthSec/> */}
    <FifthSec/>
    <SixtySec/>
    </>
  )
}
