import React from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

export const FifthSec = () => {
    var settings = {
        // dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        arrows: true,
        className: "myCustomCarousel"
      };
  return (
    <>
    <section className='materialDigital'>
        <div className='container'>
        <div className='row workwithuk'>
        {/* <div className='col-lg-12'>
                        <div className='firstTextSecght'>
                        <h3>
                        <span>White Label SEO Solutions for Agencies</span>
                        </h3>
                        <p>
                        If you're an agency looking for reliable SEO services to offer your clients, our white label SEO services UK can help you expand your offerings. With our white-label solutions, you can deliver top-notch SEO services without building an in-house team. Apex Creative Designs handles all the behind-the-scenes SEO work, allowing you to focus on growing your business.
                        </p>
                        </div>
                    </div> */}
                <div className='col-lg-8'>
                   <div className='colEight'>
                   <h3>
                    <span>Why Choose</span> Apex Creative Designs <span>for SEO?</span>
                    </h3>
                    <p>
                    Digital marketing has become more critical than ever in today's fast-paced world. Businesses that need to adapt and embrace digital marketing risk being left behind. Here's why it's essential:
                    </p>
                   </div>
                </div>
            <Slider {...settings}>
            <div>
            <div className='box-digital'>
                        <img src={require('../assets/seo_RECORD.png')} />
                        <h4>
                        PROVEN TRACK RECORD  
                        </h4>
                        <p>
                        We have a proven track record of delivering exceptional business results across various industries. Our clients trust us to improve their search rankings, drive organic traffic, and boost their bottom line. Whether you're looking for the best SEO services in UK or specialized search engine optimization UK strategies, we have the expertise to help you succeed.
                        </p>
                    </div>
                    </div>
                   <div>
                   <div className='box-digital'>
                        <img src={require('../assets/seo_STRATEGIES.png')} />
                        <h4>
                        CUSTOMIZED SEO STRATEGIES
                        </h4>
                        <p>
                        At Apex Creative Designs, we understand that no two businesses are the same. We create customized SEO strategies tailored to your unique needs and goals. We take the time to understand your business, target audience, and competition and then develop an SEO plan that delivers measurable results.
                        </p>
                    </div>
                    </div>
                    <div>
                    <div className='box-digital'>
                        <img src={require('../assets/seo_reportting.png')} />
                        <h4>
                        TRANSPARENCY AND REPORTING
                        </h4>
                        <p>
                        We believe in complete transparency with our clients. Throughout your SEO campaign, we provide regular reports that detail your site's performance, keyword rankings, and overall progress. You'll always know precisely how your SEO services are working to improve your online visibility.
                        </p>
                    </div>
                    </div>
                    <div>
                    <div className='box-digital'>
                        <img src={require('../assets/sep_team.png')} />
                        <h4>
                        EXPERIENCED SEO TEAM
                        </h4>
                        <p>
                        Our experienced SEO professionals stay updated with the latest search engine algorithms and best practices. From on-page optimization to technical SEO, our experts know what it takes to get your website ranking higher on search engines like Google.
                        </p>
                    </div>
                    </div>
                    <div>
                    <div className='box-digital'>
                        <img src={require('../assets/customer_1251685.png')} />
                        <h4>
                        COMPREHENSIVE APPROACH
                        </h4>
                        <p>
                        We take a comprehensive approach to search engine optimization, including technical SEO, on-page SEO, off-page SEO, and content marketing. This ensures that every aspect of your website is optimized for search engines and users. Our holistic approach to SEO sets us apart as one of the industry's best SEO services providers.
                        </p>
                    </div>
                    </div>
            </Slider>
            </div>
           
        </div>
    </section>
    </>
  )
}
