import React from 'react'
import { Banner } from '../../DigitalBranding/Banner'
import { SecondSec } from '../../DigitalBranding/SecondSec'
import { ThirdSec } from '../../DigitalBranding/ThirdSec'
import { FourthSec } from '../../DigitalBranding/FourthSec'
import { FifthSec } from '../../DigitalBranding/FifthSec'
import { SixtySec } from '../../DigitalBranding/SixtySec'
import { SeventhSec } from "../../DigitalBranding/SeventhSec";

export const DigitalBranding = () => {
  return (
    <>
      <Banner />
      <SecondSec />
      <ThirdSec />
      <FourthSec />
      <FifthSec />
      {/* <SixtySec /> */}
      <SeventhSec />
    </>
  )
}
