import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

export const FifthSec = () => {
  var settings = {
    // dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: true,
    className: "myCustomCarousel",
  };
  return (
    <>
      <section className="materialDigital" style={{background:'#232323'}}>
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="colEight">
                <h3>
                Our
                 <span> Packaging Design  </span>Services Tailored to Your Brand
                </h3>
                <p>
                When it comes to packaging design services, we go beyond aesthetics. We focus on creating functional and sustainable designs that not only look great but also meet the practical needs of your product. Whether you need a simple, sleek design or something bold and intricate, our packaging designers work closely with you to ensure every detail reflects your brand's personality and values.
                </p>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="colEight">
                <h3>
                Our
                 <span> Packaging Design  </span>that Speaks to Your Customers
                </h3>
                <p>
                Our product packaging design approach ensures that your packaging is not just visually appealing but also communicates the essence of your product to consumers. We understand the importance of first impressions, and our designs are crafted to make a lasting impact.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
