import React from 'react'
import { Banner } from '../../BookDesign/Banner';
import { SecondSec } from '../../BookDesign/SecondSec';
import { ThirdSec } from '../../BookDesign/ThirdSec';
import { FourthSec } from '../../BookDesign/FourthSec';
import { FifthSec } from '../../BookDesign/FifthSec';
import { SixtySec } from '../../BookDesign/SixtySec';
import { SeventhSec } from "../../BookDesign/SeventhSec";

export const BookDesign = () => {
  return (
    <>
      <Banner />
      <SecondSec />
      <ThirdSec />
      {/* <FourthSec /> */}
      <FifthSec />
      <SixtySec />
      <SeventhSec />
    </>
  )
}
