import React from 'react'

export const SecondSec = () => {
  return (
    <>
    <section className='designBannerSec'>
        <div className='container'>
            <div className='row'>   
                <div className='col-lg-12'>
                <img src={require('../assets/digitalBrandingBanner.png')} alt=''/>
                    <div className='secdesign'>
                    <h3>At Apex Creative Design, our Digital Branding Services go beyond visuals.</h3>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
