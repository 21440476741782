import React, { useState } from 'react'

export const Banner = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <>
     <section id="home-sec1" className='digitalBanner'>
  <div className="container">
    <div className="row align-items-center">
      <div className="col-lg-7 col-12">
        <div className="headings-home" data-aos="fade-left" data-aos-duration="5000">
        
        <h1>
                    <span>
                    Professional SEO 
                    </span><br/>
                    <b>Services</b>
                </h1>
                <p>
                In today's digital world, having a website is just the first step toward building an online presence. To ensure your site gets found by potential customers, it's essential to optimize it for search engines. Apex Creative Designs offers professional SEO services that help businesses improve online visibility, attract organic traffic, and rank higher on search engine results pages (SERPs).
                <br/>
                <br/>
                Our comprehensive SEO services cover everything from in-depth audits and local SEO to expert consultations and white-label solutions. With a team of skilled SEO professionals, we provide tailored strategies to meet your unique needs and help you achieve long-term success.
                </p>
        </div>
        <div className="buttons">
          <ul>
            <li>
              <a className="open-popup" onClick={openPopup}>Get a quote</a>
            </li>
          </ul>
        </div> 
      </div>
      <div className="col-lg-5 col-12">
        <div className="side-image">
          <img width="100%" src={require('../assets/professionalSeo.png')} />
        </div>
      </div>
    </div>
  </div>

  {isPopupOpen && (
          <div className="popup-overlay" id="quote-popup">
            <div className="popup-content">
              <span className="close-popup" onClick={closePopup}>&times;</span>
              <div className="row align-items-center">
                <div className="col-lg-7">
                  <div className="popup-form">
                    <h2>Request a Quote</h2>
                    <p>Fill out the form below and we'll get back to you as soon as possible!</p>
                    <form>
                      <input type="text" placeholder="Your Name" required />
                      <input type="email" placeholder="Your Email" required />
                      <textarea placeholder="Your Message" required></textarea>
                      <button type="submit">Send Message</button>
                    </form>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className='animatedPopupImage'>
                    <img src={require('../assets/digitalMarketting.png')} alt='digital marketing' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
</section>

    </>
  )
}
