import React from 'react'

export const SeventhSec = () => {
  return (
    <>
    <section className='designseventhSec'>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-12'>
                    <div className='partnerApex'>
                            <h2>
                            <span>Partner With </span><br/>
                            Apex Creative Designs
                            </h2>
                            <p>
                            By partnering with Apex Creative Design for your UX design services, you’ll ensure that your products and services align with user expectations, reduce risks, and deliver a lasting, positive impact. Let us help you elevate your brand through user-centric, data-driven design.
                            <br/>
                            <br/>
                            <span>Contact Apex Creative Designs today  </span>
                            </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
