import React from "react";

export const SixtySec = () => {
  return (
    <>
      <section className="designSixthSec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12"></div>
          </div>
          <div className="row justify-content-center item-content-center gap-5">
            <div className="col-lg-8">
              <div className="partnerApex">
                <h2>
                  Design <br />
                  <span>Process </span>
                </h2>
                <p>
                  At Apex Creative Design, our design process is a blend of
                  creativity, research, and collaboration. We focus on
                  delivering tailored solutions that align with your business
                  goals and create meaningful user experiences. Here’s how we
                  bring your vision to life:
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="portfolio-text-sec-left" data-aos="fade-right" data-aos-duration="1000">
                  <h6>STEP 01</h6>
                  <h4>
                    Discovery & <br /> Research
                  </h4>
                  <p>
                  Every project begins with understanding your brand, audience, and goals. Through in-depth research and discovery sessions, we analyze your target market, competitors, and user behavior to lay a solid foundation for the design process.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6"></div>
              <div className="col-lg-6">
               <div className="portfolio-text-sec-right" data-aos="fade-left" data-aos-duration="1000">
                  <h6>STEP 02</h6>
                  <h4>
                    Strategy &<br /> Planning
                  </h4>
                  <p>
                  With the insights gathered, we develop a strategic roadmap. This includes defining project objectives, setting clear milestones, and outlining user flows. This stage ensures that every design decision is intentional and aligned with your business vision.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="portfolio-text-sec-left" data-aos="fade-right" data-aos-duration="1000">
                  <h6>STEP 03</h6>
                  <h4>
                  Wireframing &<br />Prototyping 
                  </h4>
                  <p>
                  We then create wireframes and prototypes to visualize the structure and functionality of your project. This phase allows us to refine the user interface (UI) and user experience (UX), ensuring everything is intuitive and user-friendly before moving to the final design.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6"></div>
              <div className="col-lg-6">
               <div className="portfolio-text-sec-right" data-aos="fade-left" data-aos-duration="1000">
                  <h6>STEP 04</h6>
                  <h4>
                   Design &<br /> Development
                  </h4>
                  <p>
                  Once the prototype is approved, our creative team brings the design to life. We focus on crafting visually appealing, responsive, and accessible designs that resonate with your audience. Collaboration between designers and developers ensures smooth transitions from design to code.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="portfolio-text-sec-left" data-aos="fade-right" data-aos-duration="1000">
                  <h6>STEP 05</h6>
                  <h4>
                  Feedback &<br />Iteration
                  </h4>
                  <p>
                  We believe in continuous improvement. During the development phase, we gather feedback from your team and make any necessary adjustments. This iterative approach helps ensure the final design meets your expectations and adapts to any changes or insights along the way.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6"></div>
              <div className="col-lg-6">
               <div className="portfolio-text-sec-right" data-aos="fade-left" data-aos-duration="1000">
                  <h6>STEP 06</h6>
                  <h4>
                  Final Review &<br /> Launch
                  </h4>
                  <p>
                  Before going live, we conduct a thorough review of the design, functionality, and user flows. Once everything is tested and perfected, we help you launch the final product, ensuring a smooth handoff and integration.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="portfolio-text-sec-left" data-aos="fade-right" data-aos-duration="1000">
                  <h6>STEP 07</h6>
                  <h4>
                  Ongoing <br/>Support & <br />Growth
                  </h4>
                  <p>
                  Our partnership doesn’t end with the launch. We provide ongoing support to help you grow and adapt your design as your business evolves. Whether it’s further optimization or scaling your project, we’re with you every step of the way.
                  </p>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </section>
    </>
  );
};
