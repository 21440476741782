import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from '../assets/logo.png'
import { Link, useLocation } from 'react-router-dom';
import { FaEnvelope, FaPhone } from 'react-icons/fa';
import { FaLocationDot, FaLocationPin } from 'react-icons/fa6';
const Header = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [showServicesBox, setShowServicesBox] = useState(false);
  const [menuExpanded, setMenuExpanded] = useState(false);
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(null); // Single state to manage open/close status

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleServicesBox = () => {
    setShowServicesBox((prevShowServicesBox) => !prevShowServicesBox);
  };

  const closeMenu = () => {
    setShowServicesBox(false);
    setMenuExpanded(false);
    setActiveSubMenu(null);
    setIsMobileMenuOpen(null);
  };

  const handleMobileMenuToggle = (menuId) => {
    setIsMobileMenuOpen((prevMenuId) => (prevMenuId === menuId ? null : menuId));
  };

  const location = useLocation();
  const currentPath = location.pathname;

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };
  return (
    <>
    <div className={`header-container ${isSticky ? 'header-sticky' : ''}`}> 
      <div className="container header-top">
        <a href="tel:+447311830572" className="header-phone">
        <h3>
          <FaPhone/>
          +44 (731) 183-0572
        </h3>
        </a>
        <h3>
          <FaLocationDot/>
          A 79, Allenby Road, Lady Margaret road, Southall, Middlesex, UB1 2EZ
        </h3>
        <a href="mailto:info@apexcreativedesigns.co.uk" className="header-email">
        <h3>
          <FaEnvelope/>
          info@apexcreativedesigns.co.uk
        </h3>
        </a>

      </div>
    </div>
       <Navbar expand="lg">
      <Container>
        <Link className='navbar-brand' to="/"><img src={logo} alt='' /></Link>
        <Navbar.Toggle />
        <Navbar.Collapse id="navbarScroll" style={{justifyContent:'end'}}>
          <Nav
            className=" my-2 my-lg-0 topBotomBordersOut"
            navbarScroll
          >
            <Link className='nav-link' to="/">HOME</Link>
            <Link className='nav-link' to='/about'>ABOUT US</Link>
            
            <Link className={`nav-link hoverlink none-for-res ${showServicesBox ? 'additional-class' : ''}`}
              onClick={toggleServicesBox}
              onMouseEnter={() => setShowServicesBox(true)}
              onMouseLeave={() => setShowServicesBox(false)}>
            SERVICES
            <div class="triangle-down"></div>

            </Link>
            {showServicesBox && (
              <div
              className="full-width-box"
              onMouseEnter={() => setShowServicesBox(true)}
              onMouseLeave={() => setShowServicesBox(false)}
              >
                <div className="menu_container">
                  <div className="menu_items">
                  <div className='row align-items-center'>
                    <div className="col-md-4">
                      <img
                      style={{
                        objectFit: "contain",
                        width: "100%",
                        height: "100%"
                      }}
                      className='develop'
                        width={100}
                        src={require("../assets/illustration.png")}
                        alt=""
                        />
                    </div>
                    <div className="col-md-4">
                    <div className="resp-submenu">
                        {" "}
                        <img
                        width={25}
                          src={require("../assets/social.png")}
                          alt=""
                        />
                        <span className="menu_type under_line">
                          {" "}
                          <Link to='/social-media-marketing'>Social media marketing</Link>
                        </span>
                      </div>
                      <div className="resp-submenu">
                        {" "}
                        <img
                        width={25}
                          src={require("../assets/digital-marketting.png")}
                          alt=""
                        />
                        <span className="menu_type under_line">
                          {" "}
                          <Link to='/digital-marketing'>Digital marketing</Link>
                        </span>
                       
                      </div>
                      <div className="resp-submenu">
                        {" "}
                        <img
                        width={25}
                          src={require("../assets/ppc.png")}
                          alt=""
                        />
                        <span className="menu_type under_line">
                          {" "}
                          <Link to='/ppc-marketing'>ppc Marketing</Link>
                        </span>
                        
                      </div>
                    </div>
                    <div className="col-md-4">
                    <div className="resp-submenu">
                        {" "}
                        <img
                        width={25}
                          src={require("../assets/mobile-apps.png")}
                          alt=""
                        />
                        <span className="menu_type under_line">
                          {" "}
                          <Link to='/app-development'>App Development</Link>
                        </span>
                      </div>
                      <div className="resp-submenu">
                        {" "}
                        <img
                        width={25}
                          src={require("../assets/seo.png")}
                          alt=""
                        />
                        <span className="menu_type under_line">
                          {" "}
                          <Link to='/professional-seo'>Professional Seo</Link>
                        </span>
                        
                      </div>
                      <div className="resp-submenu">
                        {" "}
                        <img
                        width={25}
                          src={require("../assets/website-development.png")}
                          alt=""
                        />
                        <span className="menu_type under_line">
                          {" "}
                          <Link to='/website-development'>Website Development</Link>
                        </span>
                      </div>
                    </div>
                  </div>
                    
                  </div>
                </div>
              </div>
            )}
            <Link className='nav-link' to='/packages'>
            PACKAGES
            </Link>
            <Link className='nav-link' to='/portfollio'>
            PORTFOLIO
            </Link>
            <Link className='nav-link' to="/contactus">
            CONTACT
            </Link>
            <Link className='nav-link button-link' onClick={openPopup}>
            Get A Quote
            </Link>
          </Nav>
          
        </Navbar.Collapse>
      </Container>
    </Navbar>
    {isPopupOpen && (
          <div className="popup-overlay" id="quote-popup">
            <div className="popup-content">
              <span className="close-popup" onClick={closePopup}>&times;</span>
              <div className="row align-items-center">
                <div className="col-lg-7">
                  <div className="popup-form">
                    <h2>Request a Quote</h2>
                    <p>Fill out the form below and we'll get back to you as soon as possible!</p>
                    <form>
                      <input type="text" placeholder="Your Name" required />
                      <input type="email" placeholder="Your Email" required />
                      <textarea placeholder="Your Message" required></textarea>
                      <button type="submit">Send Message</button>
                    </form>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className='animatedPopupImage'>
                    <img src={require('../assets/digitalMarketting.png')} alt='digital marketing' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
    </>
  )
}

export default Header
