import React from 'react'
import { Banner } from '../../PackagingDesign/Banner'
import { SecondSec } from '../../PackagingDesign/SecondSec'
import { ThirdSec } from '../../PackagingDesign/ThirdSec'
import { FourthSec } from '../../PackagingDesign/FourthSec'
import { FifthSec } from '../../PackagingDesign/FifthSec'
import { SixtySec } from '../../PackagingDesign/SixtySec'
import { SeventhSec } from "../../PackagingDesign/SeventhSec";

export const PackagingDesign = () => {
  return (
    <>
    <Banner />
    <SecondSec />
    <ThirdSec />
    {/* <FourthSec /> */}
    <FifthSec />
    {/* <SixtySec /> */}
    <SeventhSec />
  </>
  )
}
