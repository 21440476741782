import React from 'react'

export const SecondSec = () => {
  return (
    <>
    <div className='container'>
        <div className='row'>
            <div className='col-lg-12' data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="3000">
                <img src={require('../assets/centerBanner.png')} className='img-fluid mx-auto d-block' alt='services' />
            </div>
        </div>
    </div>
    </>
  )
}
