import React from 'react'
import { Banner } from '../../StationaryDesign/Banner'
import { SecondSec } from '../../StationaryDesign/SecondSec'
import { ThirdSec } from '../../StationaryDesign/ThirdSec'
import { FourthSec } from '../../StationaryDesign/FourthSec'
import { FifthSec } from '../../StationaryDesign/FifthSec'
import { SixtySec } from '../../StationaryDesign/SixtySec'
import { SeventhSec } from "../../StationaryDesign/SeventhSec";

export const StationaryDesign = () => {
  return (
    <>
    <Banner />
    <SecondSec />
    <ThirdSec />
    {/* <FourthSec /> */}
    <FifthSec />
    {/* <SixtySec /> */}
    <SeventhSec />
  </>
  )
}
