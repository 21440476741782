import React from 'react'

export const SixtySec = () => {
  return (
    <>
    <section className='designseventhSec'>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-12'>
                    <div className='partnerApex'>
                            <h2>
                            <span>Partner With </span><br/>
                            Apex Creative Designs
                            </h2>
                            <p>
                            At Apex Creative Designs, we are committed to delivering the best digital marketing services tailored to your business's needs. Whether you're looking to boost your SEO, run a PPC campaign, or improve your social media presence, we are here to help. As a top digital marketing agency in the UK, we pride ourselves on our ability to create winning strategies that deliver accurate results.
                            <br/>
                            <br/>
                            Don't wait to elevate your business. <span>Contact us today</span> to learn more about how our digital marketing services can help you reach your business goals.
                            </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
