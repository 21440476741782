import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

export const FifthSec = () => {
  var settings = {
    // dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: true,
    className: "myCustomCarousel",
  };
  return (
    <>
      <section className="materialDigital" style={{background:'#232323'}}>
        <div className="container">
        
           
          <div className="row workwithuk">
            <div className="col-lg-8">
              <div className="colEight">
                <h3>
                  Digital Branding <br />
                  <span>Benchmarks </span>
                </h3>
                <p>
                  At Apex Creative Design, we set clear benchmarks to ensure
                  your brand’s digital presence thrives. By combining innovative
                  design with strategic branding and performance tracking, we
                  help your brand achieve measurable success.
                </p>
              </div>
            </div>
            <div className="row">
              <Slider {...settings}>
                <div>
                  <div className="box-digital">
                    <img src={require("../assets/discovery_176212.png")} />
                    <h4>Consistent Brand Messaging</h4>
                    <p>
                      We ensure your brand’s voice and message remain consistent
                      across all platforms, whether it’s your website, social
                      media, or digital campaigns. Consistency builds trust and
                      helps your audience recognize and relate to your brand.
                    </p>
                  </div>
                </div>
                <div>
                  <div className="box-digital">
                    <img src={require("../assets/graphic-design_10448097.png")} />
                    <h4>UI/UX Design Excellence</h4>
                    <p>
                      Great branding isn’t just about visuals; it’s about user
                      experience. Our team merges UI/UX design with your brand
                      strategy to create seamless, user-friendly interactions
                      that leave a lasting impression on your audience.
                    </p>
                  </div>
                </div>
                <div>
                  <div className="box-digital">
                    <img src={require("../assets/coding_2010990.png")} />
                    <h4>Strategic Brand Development</h4>
                    <p>
                      We develop a tailored branding strategy that aligns with
                      your business goals. From identifying your target audience
                      to refining your messaging and visual identity, we lay the
                      groundwork for long-term brand success.
                    </p>
                  </div>
                </div>
                <div>
                  <div className="box-digital">
                    <img src={require("../assets/monitor_1717220.png")} />
                    <h4>User Engagement Metrics</h4>
                    <p>
                    Engaging with your audience is key to successful digital branding. We set benchmarks for user engagement, tracking key performance indicators (KPIs) like website traffic, social media interactions, and content shares to measure your brand’s impact.
                    </p>
                  </div>
                </div>
                <div>
                  <div className="box-digital">
                    <img src={require("../assets/design-reniew.png")} />
                    <h4>Performance Benchmarking</h4>
                    <p>
                    To ensure your brand stays competitive, we continuously monitor its performance. We use data-driven insights to assess how well your branding efforts are translating into tangible results, such as increased sales, lead generation, or customer retention.
                    </p>
                  </div>
                </div>
              </Slider>
            </div>
            <div className="colEight">
            <div className="row justify-content-center text-center">
              <div className="col-lg-7">
                <h3 className="">
                  <span>Apex Creative Design:</span> Your Trusted UX Design
                  Partner
                </h3>
              </div>
              <div className="col-lg-12">
                <p>
                  At Apex Creative Design, we are dedicated to creating
                  seamless, user-centered experiences that not only look great
                  but also function flawlessly. As your trusted UX design
                  partner, we focus on delivering designs that enhance user
                  satisfaction and drive results for your business.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 bg-darkShadow bg-darkShadow-left">
                <h2>User-Centered Design Approach</h2>
                <p>We put your users at the heart of every design decision. By understanding their needs, behaviors, and preferences, we create intuitive interfaces that offer a smooth and enjoyable experience across all digital platforms.</p>
              </div>
            <div className="col-lg-4 bg-lightShadow">
                <h2>Responsive and Accessible Design</h2>
                <p>A great user experience means being accessible to all. We design responsive websites and apps that work seamlessly across devices and are accessible to users with different abilities, ensuring your brand reaches a wider audience.</p>
              </div>
            <div className="col-lg-4 bg-darkShadow bg-darkShadow-left">
                <h2>Ongoing Testing and Optimization</h2>
                <p>User needs evolve, and so do our designs. We continuously test and refine your UX based on user feedback and analytics, ensuring that your platform stays up-to-date with the latest trends and provides the best possible experience.</p>
              </div>
          </div>
          
         
        </div>
      </section>
    </>
  );
};
