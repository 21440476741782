import React, { useState, useEffect, useRef } from 'react';

const Card = ({ images, title, subtitle, text }) => {
  const [currentImage, setCurrentImage] = useState(images[0]);
  const [hovering, setHovering] = useState(false);
  const imageIndex = useRef(0);

  useEffect(() => {
    if (hovering) {
      const interval = setInterval(() => {
        imageIndex.current = (imageIndex.current + 1) % images.length;
        setCurrentImage(images[imageIndex.current]);
      }, 1000); // Change image every 2000 milliseconds
      return () => clearInterval(interval);
    }
  }, [hovering, images]);

  return (
    <div className='col-lg-6 col-md-6 mb-4' data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="3000" onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)}>
      <div className='card h-100'>
        <a href='#'><img className='card-img-top' src={currentImage} alt=''></img></a>
        <div className='card-body'>
          <h5>{title}</h5>
          <h4 className='card-title'>{subtitle}</h4>
          <p className='card-text'>{text}</p>
        </div>
      </div>
    </div>
  );
};

export const Main = () => {
  const cards = [
    {
      images: [require('../assets/port1.png'), require('../assets/port1-2.png'), require('../assets/port1-3.png')], // Array of images for each card
      title: 'Personal Assistance',
      subtitle: 'MOBILE APPLICATION',
      text: 'Best creative design agencies for innovative, tailored solutions that elevate your brand and drive engagement. Experience excellence in design and unmatched client satisfaction.'
    },
    {
      images: [require('../assets/port2.png'), require('../assets/port2-2.png'), require('../assets/port2-3.png')], // Array of images for each card
      title: 'Personal Assistance',
      subtitle: 'MOBILE APPLICATION',
      text: 'Best creative design agencies for innovative, tailored solutions that elevate your brand and drive engagement. Experience excellence in design and unmatched client satisfaction.'
    },
    {
      images: [require('../assets/port3.png'), require('../assets/port3-2.png'), require('../assets/port3-3.png')], // Array of images for each card
      title: 'Personal Assistance',
      subtitle: 'MOBILE APPLICATION',
      text: 'Best creative design agencies for innovative, tailored solutions that elevate your brand and drive engagement. Experience excellence in design and unmatched client satisfaction.'
    },
    {
      images: [require('../assets/port4.png'), require('../assets/port4-2.png'), require('../assets/port4-3.png')], // Array of images for each card
      title: 'Personal Assistance',
      subtitle: 'MOBILE APPLICATION',
      text: 'Best creative design agencies for innovative, tailored solutions that elevate your brand and drive engagement. Experience excellence in design and unmatched client satisfaction.'
    },
    // Repeat for other cards
  ];

  return (
    <>
      <div className='container'>
        <div className='text-start'>
          <h1 className='main-title'>Our <span>Portfolio</span></h1>
        </div>
        <div className='row'>
          {cards.map((card, index) => (
            <Card key={index} images={card.images} title={card.title} subtitle={card.subtitle} text={card.text} />
          ))}
        </div>
      </div>
    </>
  );
};
