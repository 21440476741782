import React from 'react'
import { Banner } from '../../AppDevelopment/Banner'
import { SecondSec } from '../../AppDevelopment/SecondSec'
import { ThirdSec } from '../../AppDevelopment/ThirdSec'
import { FourthSec } from '../../AppDevelopment/FourthSec'
import { FifthSec } from '../../AppDevelopment/FifthSec'
import { SixtySec } from '../../AppDevelopment/SixtySec'

export const AppDevelopment = () => {
  return (
    <>
    <Banner/>
    <SecondSec/>
    <ThirdSec/>
    {/* <FourthSec/> */}
    <FifthSec/>
    <SixtySec/>
    </>
  )
}
