import React from 'react'
import { Banner } from '../../PpcMarketing/Banner'
import { SecondSec } from '../../PpcMarketing/SecondSec'
import { ThirdSec } from '../../PpcMarketing/ThirdSec'
import { FourthSec } from '../../PpcMarketing/FourthSec'
import { FifthSec } from '../../PpcMarketing/FifthSec'
import { SixtySec } from '../../PpcMarketing/SixtySec'

export const PpcMarketing = () => {
  return (
    <>
    <Banner/>
    <SecondSec/>
    <ThirdSec/>
    {/* <FourthSec/> */}
    <FifthSec/>
    <SixtySec/>
    </>
  )
}
