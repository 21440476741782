import React from 'react'
import { Banner } from '../../SocialMediaMarketing/Banner'
import { SecondSec } from '../../SocialMediaMarketing/SecondSec'
import { ThirdSec } from '../../SocialMediaMarketing/ThirdSec'
import { FourthSec } from '../../SocialMediaMarketing/FourthSec'
import { FifthSec } from '../../SocialMediaMarketing/FifthSec'
import { SixtySec } from '../../SocialMediaMarketing/SixtySec'

export const SocialMediaMarketing = () => {
  return (
    <>
    <Banner/>
    <SecondSec/>
    <ThirdSec/>
    {/* <FourthSec/> */}
    <FifthSec/>
    <SixtySec/>
    </>
  )
}
