import React from 'react'

export const FourthSec = () => {
  return (
    <>
    <section className="designFourthSec text-center">
        <div className='container'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='firstTextSecght'>
                        <h3>
                        <span>What to Expect from Custom </span><br/>Front-End Development
                        </h3>
                        <p>
                        At Apex Creative Design, our custom front-end development services are crafted to deliver a<br/> polished and highly functional digital platform. Here's what you can expect when partnering <br/>with us for your front-end development needs.
                        </p>
                        <div className='DevelopingLasting'>
                       <ul>
                        <li>
                        Responsive and Mobile-First Design
                        </li>
                        <li>
                        High-Performance Websites
                        </li>
                        <li>
                        Custom Solutions Tailored to Your Brand
                        </li>
                        <li>
                        User-Friendly Navigation and Design
                        </li>
                        <li>
                        User-Friendly Navigation and Design
                        </li>
                       </ul>
                        </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
