import React, { useState } from 'react';

export const Banner = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <>
      <section id="home-sec1" className='digitalBanner'>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7 col-12">
              <div className="headings-home" data-aos="fade-left" data-aos-duration="5000">
                <h1>
                  <span>Custom Application Development</span>                  <b> Services</b>
                </h1>
                <p>
                At Apex Creative Design, our custom web application development services offer tailored solutions to bring your unique business vision to life. In today's competitive digital landscape, having an application that caters specifically to your business needs is essential for growth and success. We specialize in creating robust, scalable, user-friendly applications that streamline operations, improve user engagement, and drive business efficiency.<br/>
Our custom application development services are designed to meet the distinct needs of businesses across industries. Whether you need a minimum viable product (MVP), an e-commerce platform, or a custom content management system (CMS), we have the expertise to build solutions that fit your goals. At Apex Creative Design, we take pride in delivering high-quality, reliable applications that help your business thrive.
                </p>
              </div>
              <div className="buttons">
                <ul>
                  <li>
                    <a onClick={openPopup} href="#">Get a quote</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-5 col-12">
              <div className="side-image">
                <img width={100} src={require('../assets/applicationDevSec.png')} alt="Social Media Marketing" />
              </div>
            </div>
          </div>
        </div>

        {isPopupOpen && (
          <div className="popup-overlay" id="quote-popup">
            <div className="popup-content">
              <span className="close-popup" onClick={closePopup}>&times;</span>
              <div className="row align-items-center">
                <div className="col-lg-7">
                  <div className="popup-form">
                    <h2>Request a Quote</h2>
                    <p>Fill out the form below and we'll get back to you as soon as possible!</p>
                    <form>
                      <input type="text" placeholder="Your Name" required />
                      <input type="email" placeholder="Your Email" required />
                      <textarea placeholder="Your Message" required></textarea>
                      <button type="submit">Send Message</button>
                    </form>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className='animatedPopupImage'>
                    <img src={require('../assets/digitalMarketting.png')} alt='digital marketing' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};
