import React from 'react'
import { FaArrowAltCircleRight } from 'react-icons/fa'
import { Link } from 'react-router-dom'

export const SectionThree = () => {
  return (
    <>
       <div className="service-item right bottom">
                <div className="container d-flex align-items-center">
                    <div className="left" data-aos="fade-right">
                        <h3 className="title">Website Development Services</h3>
                        <p className="short-description">At Apex Creative Designs, we understand that no two businesses are alike. That's why our custom website development services are designed to deliver a site that reflects your brand identity and supports your long-term business objectives. We offer complete flexibility in design and functionality, allowing you to have a website that stands out from the competition.</p>
                        <div className="service-links">
                            <ul>
                                <li><a className="php" href="#">PHP Development</a></li>
                                <li><a className="mern" href="#">Mern Stack Developers</a></li>
                                <li><a className="mean" href="#">Mean Stack Developers</a></li>
                                <li><a className="fullstack" href="#">Full Stack Developers</a></li>
                            </ul>
                        </div>
                        <div className="cta-button">
                            <Link className="primary-btn get-center hvr-shutter-out-horizontal btn" to="/contact-us"><span>Talk To Our Experts  </span><FaArrowAltCircleRight className='banner-icon' /></Link> 
                            </div>
                    </div>
                    <div className="right" data-aos="fade-left">
                        <div className="service-image text-center">
                            <picture> <img decoding="async" className=" lazyloaded" src={require('../../assets/websiteBanner.png')} data-src={require('../../assets/websiteBanner.png')} data-srcset={require('../../assets/websiteBanner.png')} alt="Website Development" height="432" width="583" srcset={require('../../assets/websiteBanner.png')}/> </picture>
                        </div>
                    </div>
                </div>
            </div>
    </>
  )
}
