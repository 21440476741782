import React, { useState } from 'react';

export const ThirdSec = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = (index) => setHoveredIndex(index);
  const handleMouseLeave = () => setHoveredIndex(null);

  return (
    <>
      <section className="designThirdSec">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="firstTextSec">
                <h3 data-aos="fade-right" data-aos-duration="1000">
                  Why <span>Social Media Marketing</span> Matters
                </h3>
                <p data-aos="fade-left" data-aos-duration="1000">
                  Social media has become one of the most powerful tools for reaching potential customers and building lasting relationships. With billions of users across platforms like Facebook, Instagram, Twitter, and LinkedIn, social media gives businesses the opportunity to connect directly with their audience, share their message, and boost brand awareness. However, effectively managing your online presence can be overwhelming. This is where Apex Creative Designs comes in. Our social media marketing consulting services are designed to help you develop and execute strategies that drive results.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="firstTextSec">
                {[
                  {
                    title: "Tailored Social Media Marketing Strategies",
                    text:
                      "At Apex Creative Designs, we understand that every business is unique. That’s why we don’t believe in one-size-fits-all solutions. Our social media marketing agency UK, offers custom-tailored strategies to meet the specific needs of your business. We take the time to understand your goals, audience, and industry to craft a personalized approach. Whether you’re looking to increase brand awareness, boost sales, or enhance engagement, our social media marketing management services are designed to deliver results."
                  },
                  {
                    title: "Comprehensive Social Media Marketing Consulting Services",
                    text:
                      "Our team of social media experts offers full social media marketing consulting services to help you navigate the complexities of today’s digital landscape. From planning and strategy development to execution and analysis, we cover every aspect of social media marketing. Our in-depth consultation process includes analyzing your current presence, understanding your competitors, and identifying opportunities for growth. We are one of the leading social media marketing companies UK, offering expertise in crafting strategies that align with your business objectives."
                  },
                  {
                    title: "Expertise in Content Creation",
                    text:
                      "Creating engaging, high-quality content is the backbone of any successful social media campaign. At Apex Creative Designs, we offer specialized social media content creation services that are designed to captivate your audience. Our team of content creators develops posts, videos, and images that are tailored to your brand voice and message. By leveraging our content creation services, your social media channels will showcase content that resonates with your audience, driving more engagement and interactions."
                  },
                  {
                    title: "Paid Social Media Advertising",
                    text:
                      "One of the fastest ways to grow your audience and drive conversions is through paid social media advertising. As a paid social agency, Apex Creative Designs is experienced in running effective ad campaigns across all major platforms. Whether you’re looking to increase sales, generate leads, or promote a new product, we create customized ads that target your specific audience. Our expertise in paid social helps you maximize your return on investment, ensuring every penny spent is working to grow your business."
                  },
                  {
                    title: "Your Social Insights",
                    text:
                      "Understanding the impact of your social media efforts is crucial to refining your strategy. At Apex Creative Designs, we provide your social insights by offering detailed reports and analytics that help you track your performance. With insights into metrics such as engagement, reach, and conversion rates, we give you the tools to make informed decisions about your social media strategy. By consistently analyzing your social performance, we ensure your campaigns are always optimized for success."
                  }
                ].map((item, index) => (
                  <div
                    className="portfolio-text-div"
                    key={index}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <h4 data-aos="fade-left" data-aos-duration="1000">
                      <b>{item.title}</b>
                    </h4>
                    <p
                      className={`portfolio-text ${
                        hoveredIndex === index ? "show" : ""
                      }`}
                    >
                      {item.text}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
