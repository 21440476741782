import React from "react";

export const SixtySec = () => {
  return (
    <>
      <section className="designSixthSec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12"></div>
          </div>
          <div className="row justify-content-center item-content-center gap-5">
            <div className="col-lg-8">
              <div className="partnerApex">
                <h2>
                The Process of Creating Your <br />
                  <span>Animated Logo </span>
                </h2>
                <p>
                You can expect a seamless, collaborative process when you work with Apex Creative Design. Here's how we bring your animated logo to life:
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="portfolio-text-sec-left" data-aos="fade-right" data-aos-duration="1000">
                  <h6>STEP 01</h6>
                  <h4>
                  Initial Consultation and Concept Development
                  </h4>
                  <p>We start by understanding your brand and vision. Whether you need a bold car logo design or an elegant luxury logo, we work with you to develop concepts that resonate with your target audience.</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6"></div>
              <div className="col-lg-6">
               <div className="portfolio-text-sec-right" data-aos="fade-left" data-aos-duration="1000">
                  <h6>STEP 02</h6>
                  <h4>
                   Design and Animation
                  </h4>
                  <p>Once the concept is approved, our design team gets to work. We combine cutting-edge tools and creativity to craft a stunning animated logo that aligns with your brand's identity, whether it's a restaurant logo or a fashion brand logo.</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="portfolio-text-sec-left" data-aos="fade-right" data-aos-duration="1000">
                  <h6>STEP 03</h6>
                  <h4>
                  Revisions and Finalization
                  </h4>
                  <p>Your satisfaction is our priority. We offer multiple rounds of revisions to ensure your animated logo meets your expectations, delivering a final product that stands out in your industry.</p>
                </div>
              </div>
            </div>
            
            
          </div>
        </div>
      </section>
    </>
  );
};
