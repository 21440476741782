import React from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

export const FifthSec = () => {
    var settings = {
        // dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        arrows: true,
        className: "myCustomCarousel"
      };
  return (
    <>

    <section className='materialDigital'>
        <div className='container'>
        {/* <div className='row'>
                    <div className='col-lg-12'>
                        <div className='firstTextSecght'>
                        <h3 data-aos="fade-left" data-aos-duration="1000">
                        <span>Local & Nationwide Expertise</span>
                        </h3>
                        <p data-aos="fade-up" data-aos-duration="1000">
                        Are you running an e-commerce business? Our ecommerce digital marketing agency services are tailored to help online stores attract more customers and increase sales. We optimize your product listings, implement retargeting strategies, and create conversion-focused marketing campaigns that drive traffic and sales.
                        <br/>
                        <br/>
                        If you're searching for a digital marketing company in the UK specializing in e-commerce, our team is here to deliver results that boost your revenue and online presence.
                        </p>
                        </div>
                    </div>
                </div> */}
        <div className='row workwithuk'>
                <div className='col-lg-8'>
                   <div className='colEight'>
                   <h3 data-aos="fade-down" data-aos-duration="1000">
                    <span>Why</span> Digital Marketing <span>Matters</span>
                    </h3>
                    <p data-aos="fade-up" data-aos-duration="1000">
                    Digital marketing has become more critical than ever in today's fast-paced world. Businesses that need to adapt and embrace digital marketing risk being left behind. Here's why it's essential:
                    </p>
                   </div>
                </div>
                <Slider {...settings}>
            <div>
            <div className='box-digital'>
                        <img src={require('../assets/target.png')} />
                        <h4>
                        TARGETED CAMPAIGNS
                        </h4>
                        <p>
                        Using strategies like SEO, PPC, and social media marketing, you can target your ideal customers and boost your chances of conversion.
                        </p>
                    </div>
                    </div>
                   <div>
                   <div className='box-digital'>
                        <img src={require('../assets/costEffective.png')} />
                        <h4>
                        COST EFFECTIVE
                        </h4>
                        <p>
                        Digital marketing offers some of the most cost-effective ways to reach your target audience compared to traditional marketing.
                        </p>
                    </div>
                    </div>
                    <div>
                    <div className='box-digital'>
                        <img src={require('../assets/measurable.png')} />
                        <h4>
                        MEASURABLE RESULT
                        </h4>
                        <p>
                        With digital marketing, you can track everything from website visits to conversions, allowing you to see real-time results.
                        </p>
                    </div>
                    </div>
            </Slider>
            </div>
            <div className='row'>
           
            </div>
            <div className='row'>
                <div className='col-lg-4'>
                    
                </div>
                <div className='col-lg-4'>
                
                </div>
                <div className='col-lg-4'>
               
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
