import React from 'react'

export const SixtySec = () => {
  return (
    <>
    <section className='designseventhSec'>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-12'>
                    <div className='partnerApex'>
                    <h2 data-aos="fade-down" data-aos-duration="1000">
                    <span>Partner With </span><br/>
                            Apex Creative Designs
                            </h2>
                            <p data-aos="fade-up" data-aos-duration="1000">
                            When you choose Apex Creative Designs for your mobile app design and development needs, you are with a team of experts who are dedicated to your success. We take pride in our work and are committed to delivering a product that not only meets your needs but also exceeds your expectations.
                            <br/>
                            <br/>
                            Ready to take your business to the next level with a custom mobile app? <span> Contact Apex Creative Designs today</span> to learn more about our mobile app design and development services. We're here to help you create an app that not only meets your needs but also exceeds your.
                            </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
