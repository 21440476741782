import React from 'react'

export const SecondSec = () => {
  return (
    <>
    <section className='designBannerSec'>
        <div className='container'>
            <div className='row'>   
                <div className='col-lg-12'>
                <img width={50} src={require('../assets/packageBanner.png')} alt=''/>
                    <div className='secdesign'>
                        <h3>Bring Your Vision to Life with Stunning, Realistic Digital Mockups!</h3>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
