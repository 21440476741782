import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

export const FifthSec = () => {
  return (
    <>
      <section className="materialDigital" style={{background:'#232323'}} >
        <div className="container">
          <div className="row workwithuk align-items-center">
            <div className="col-lg-8">
              <div className="colEight">
                <h3>
                  <span>Why Work with a</span> Pay-Per-Click{" "}
                  <span>Marketing Agency?</span>
                </h3>
                <p>
                  Managing PPC campaigns in-house can be time-consuming and
                  challenging. By partnering with a professional pay per click
                  marketing agency like Apex Creative Designs, you gain access
                  to expert knowledge and dedicated resources that ensure your
                  campaigns are set up for success from day one. We take the
                  guesswork out of PPC, allowing you to focus on other areas of
                  your business while we drive results.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div >
                <img src={require("../assets/pay-per-click_5214855-ppc.png")} />
              </div>
            </div>
            <div className="col-lg-4">
              <div >
                <img src={require("../assets/sale_10847705-ppc.png")} />
              </div>
            </div>
            <div className="col-lg-8">
              <div className="colEight">
                <h3>
                  Pay-Per-Click <span>Agencies That Deliver Results</span>
                </h3>
                <p>
                  When choosing from the many pay per click agencies, it's
                  essential to partner with one that understands your goals and
                  can deliver measurable outcomes. Apex Creative Designs has
                  years of experience managing PPC campaigns across various
                  industries, and we pride ourselves on our ability to deliver
                  tangible results for our clients. Our focus is always on
                  driving traffic, increasing conversions, and maximizing your
                  advertising budget.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
