import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

export const FifthSec = () => {
  var settings = {
    // dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: true,
    className: "myCustomCarousel",
  };
  return (
    <>
      <section className="materialDigital" style={{background:'#232323'}}>
        <div className="container">
          <div className="row workwithuk">
            <div className="col-lg-8">
              <div className="colEight">
                <h3>
                WHY <br />
                 <span> WORK  </span>WITH APEX CREATIVE DESIGN
                </h3>
                <p>
                When you choose Apex Creative Design for your animated logo needs, you're partnering with a team of professionals committed to making your brand shine. We stand out from other design companies by offering:
                </p>
              </div>

            </div>
            <Slider {...settings}>
                <div>
                  <div className="box-digital">
                    <img src={require("../assets/custom_logo.png")} />
                    <h4>Customized Solutions</h4>
                    <p>
                    No two logos are the same. We tailor each animated logo to reflect your brand's unique personality.
                    </p>
                  </div>
                </div>
                <div>
                  <div className="box-digital">
                    <img src={require("../assets/graphic-design_10448097.png")} />
                    <h4>Modern Logo Design</h4>
                    <p>
                    Our team stays on top of design trends, ensuring your logo remains fresh and relevant in today's competitive market.
                    </p>
                  </div>
                </div>
                <div>
                  <div className="box-digital">
                    <img src={require("../assets/expert_logo.png")} />
                    <h4>industry Expertise</h4>
                    <p>
                     With experience in designing everything from abstract logos to luxury brand logos, we know what it takes to make your brand stand out.
                    </p>
                  </div>
                </div>
                
              </Slider>
          </div>
        </div>
      </section>
    </>
  );
};
