import React from 'react'

export const SecondSec = () => {
  return (
    <>
    <section className='designBannerSec'>
        <div className='container'>
            <div className='row'>   
                <div className='col-lg-12'>
                <img src={require('../assets/applicationDevelopmentBanner.png')} alt=''/>
                    <div className='secdesign'>
                    <h3>We leverage cutting-edge technologies and expert insights to<br/> deliver tailor-made solutions for superior User Experience</h3>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
