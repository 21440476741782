import React from "react";
import { Banner } from '../../UxDesign/Banner'
import { SecondSec } from '../../UxDesign/SecondSec'
import { ThirdSec } from '../../UxDesign/ThirdSec'
import { FourthSec } from '../../UxDesign/FourthSec'
import { FifthSec } from '../../UxDesign/FifthSec'
import { SixtySec } from '../../UxDesign/SixtySec'
import { SeventhSec } from "../../UxDesign/SeventhSec";

export const UxDesign = () => {
  return (
    <>
      <Banner />
      <SecondSec />
      <ThirdSec />
      <FourthSec />
      <FifthSec />
      <SixtySec />
      <SeventhSec />
    </>
  );
};
