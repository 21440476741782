import React, { useEffect, useRef, useState } from 'react'

export const ThirdSec = () => {
    const [isImageFixed, setIsImageFixed] = useState(false);
    const [isInFifthSection, setIsInFifthSection] = useState(false);
    
    const portfolioRef = useRef(null);
    const textSections = useRef([]);
  
    useEffect(() => {
      const options = {
        root: null,
        threshold: 0.1,
      };
  
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          const index = entry.target.getAttribute("data-index");
  
          if (entry.isIntersecting) {
            switch (parseInt(index)) {
              case 0:
                {
                    
                }
                break;
              case 1:
                return(
                        <>
                        <h3>
                        Why Choose <span>Apex Creative Designs</span> for Digital Marketing?
                        </h3>
                        <p>
                        Choosing the right digital marketing services company can make all the difference in how effectively you reach your audience. At Apex Creative Designs, we believe in transparency, measurable results, and a personalized marketing approach.
                        </p>
                        </>
                );
                break;
              case 2:
                return(
                    <>
                    <h3>
                    Why Choose <span>Apex Creative Designs</span> for Digital Marketing?
                    </h3>
                    <p>
                    Choosing the right digital marketing services company can make all the difference in how effectively you reach your audience. At Apex Creative Designs, we believe in transparency, measurable results, and a personalized marketing approach.
                    </p>
                    </>
            );
                break;
              case 3:
                return(
                    <>
                    <h3>
                    Why Choose <span>Apex Creative Designs</span> for Digital Marketing?
                    </h3>
                    <p>
                    Choosing the right digital marketing services company can make all the difference in how effectively you reach your audience. At Apex Creative Designs, we believe in transparency, measurable results, and a personalized marketing approach.
                    </p>
                    </>
            );

              case 4:
                setIsInFifthSection(true);  // When the fifth section is visible
                break;
              default:
                break;
            }
          } else {
            if (parseInt(index) === 5) {
              setIsInFifthSection(false); // Reset when leaving fifth section
            }
          }
        });
      }, options);
  
      textSections.current.forEach((section) => {
        if (section) observer.observe(section);
      });
  
      return () => {
        textSections.current.forEach((section) => {
          if (section) observer.unobserve(section);
        });
      };
    }, []);
  
    useEffect(() => {
      const handleScroll = () => {
        if (portfolioRef.current) {
          const rect = portfolioRef.current.getBoundingClientRect();
          setIsImageFixed(rect.top <= 0 && rect.bottom > window.innerHeight);
        }
      };
  
      window.addEventListener("scroll", handleScroll);
  
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);
  return (
    <>
    <section className='designThirdSec'  >
        <div className='container'>
            <div className='row'>
                <div className='col-lg-6'>
                    <div className={`firstTextSec   `}>
                        <h3>
                        Why Choose <span>Apex Creative Designs</span> for Digital Marketing?
                        </h3>
                        <p>
                        Choosing the right digital marketing services company can make all the difference in how effectively you reach your audience. At Apex Creative Designs, we believe in transparency, measurable results, and a personalized marketing approach.
                        </p>
                    </div>
                </div>
                <div className='col-lg-6'>
                <div className='firstTextSec'>
                <div
                  className="portfolio-text-div"
                  data-index="0"
                  ref={(el) => (textSections.current[0] = el)}
                >
                        <h4>
                            <b>Our Comprehensive Digital Marketing Services</b><br/><br/>
                            Search Engine Optimization (SEO)
                        </h4>
                        <p>
                        Our digital marketing services start with the cornerstone of online visibility: SEO. We optimize your website to ensure it ranks high in search engine results, increasing traffic and ensuring your target audience finds you when searching for your products or services.
                        <br/>
                        <br/>
                        We specialize in on-page and off-page SEO, ensuring your site is technically sound while building high-quality backlinks. If you're searching for the best digital marketing agency UK to enhance your SEO, look no further. Our team knows how to stay ahead of search engine algorithms and keep your site at the top.
                        </p>
                        </div>
                        <div
                  className="portfolio-text-div"
                  data-index="1"
                  ref={(el) => (textSections.current[1] = el)}
                >
                        <h4>
                        Social Media Marketing
                        </h4>
                        <p>
                        Social media is one of the most powerful tools for connecting with your audience and promoting your brand. As a top digital marketing agency, we help businesses grow their social media presence across platforms like Facebook, Instagram, LinkedIn, and Twitter. Our social media marketing services include content creation, paid ads, and community management to ensure your brand stays relevant and engaging.
                        <br/>
                        <br/>
                        Whether you're a small local business or a large enterprise, our team creates strategies that drive engagement, boost followers, and ultimately convert followers into customers.
                        </p>
                        </div>
                        <div
                  className="portfolio-text-div"
                  data-index="2"
                  ref={(el) => (textSections.current[2] = el)}
                >
                        <h4>
                        Email Marketing
                        </h4>
                        <p>
                        Looking for a direct way to communicate with your customers? Email marketing remains one of the most effective digital marketing tools. At Apex Creative Designs, we create personalized email campaigns that speak directly to your audience. We manage everything, from designing eye-catching templates to writing compelling copy that drives conversions. With our digital marketing services, we ensure your emails get opened, read, and clicked.
                        </p>
                        </div>
                        <div
                  className="portfolio-text-div"
                  data-index="3"
                  ref={(el) => (textSections.current[3] = el)}
                >
                        <h4>
                        Content Marketing
                        </h4>
                        <p>
                        Content is at the heart of digital marketing. Building trust with your audience or ranking on search engines is hard without valuable and engaging content. Our content marketing strategies are designed to establish your authority in your industry and keep your audience coming back for more. We craft blog posts, articles, infographics, and videos that educate and drive traffic and conversions.
                        <br/>
                        Content marketing is essential for businesses looking for digital marketing services UK to build a solid online presence. Our team ensures that all content is optimized for SEO and tailored to your brand's voice and tone.
                        </p>
                        </div>
                        <div
                  className="portfolio-text-div"
                  data-index="4"
                  ref={(el) => (textSections.current[4] = el)}
                >
                        <h4>
                        E-commerce Digital Marketing
                        </h4>
                        <p>
                        Are you running an e-commerce business? Our ecommerce digital marketing agency services are tailored to help online stores attract more customers and increase sales. We optimize your product listings, implement retargeting strategies, and create conversion-focused marketing campaigns that drive traffic and sales.
                        <br/>
                        <br/>
                        If you're searching for a digital marketing company in the UK specializing in e-commerce, our team is here to deliver results that boost your revenue and online presence.
                        </p>
                        </div>
                        <div
                  className="portfolio-text-div"
                  data-index="5"
                  ref={(el) => (textSections.current[6] = el)}
                >
                        <h4>
                        Marketing Consultancy
                        </h4>
                        <p>
                        Where to start with your digital marketing efforts? Our expert marketing consultants are here to guide you. We assess your current marketing strategies, identify areas for improvement, and provide you with a roadmap to achieve your business goals. Whether you're looking for SEO, social media, or PPC advice, our consultants can help you develop a plan that works for you.
                        </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
   

    {/* Optional styles */}
    <style jsx>{`
      .firstTextSec.fixed {
    position: fixed;
    top: 100px;
    width: 515px;
}
    
.firstTextSec.move-to-top {
    bottom: 65%;
    width: 515px;
    position: fixed;
    top: 100px;
}

    `}</style>
    </>
    
  )
}
