import React from 'react'
import { Banner } from '../../DigitalMarketting/Banner'
import { SecondSec } from '../../DigitalMarketting/SecondSec'
import { ThirdSec } from '../../DigitalMarketting/ThirdSec'
import { FourthSec } from '../../DigitalMarketting/FourthSec'
import { FifthSec } from '../../DigitalMarketting/FifthSec'
import { SixtySec } from '../../DigitalMarketting/SixtySec'

export const Digitalmarketing = () => {
  return (
    <>
    <Banner/>
    <SecondSec/>
    <ThirdSec/>
    {/* <FourthSec/> */}
    <FifthSec/>
    <SixtySec/>
    </>
  )
}
