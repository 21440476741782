import React from "react";

export const SixtySec = () => {
  return (
    <>
      <section className="designSixthSec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12"></div>
          </div>
          <div className="row justify-content-center item-content-center gap-5">
            <div className="col-lg-8">
              <div className="partnerApex">
                <h2>
                  Design <br />
                  <span>Process </span>
                </h2>
                <p>
                  At Apex Creative Design, our design process is a blend of
                  creativity, research, and collaboration. We focus on
                  delivering tailored solutions that align with your business
                  goals and create meaningful user experiences. Here’s how we
                  bring your vision to life:
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="portfolio-text-sec-left" data-aos="fade-right" data-aos-duration="1000">
                  <h6>STEP 01</h6>
                  <h4>
                  Concept Development
                  </h4>
                  <p>We begin by discussing your vision for the design book, understanding your preferences, and brainstorming book cover design ideas that align with your goals.</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6"></div>
              <div className="col-lg-6">
               <div className="portfolio-text-sec-right" data-aos="fade-left" data-aos-duration="1000">
                  <h6>STEP 02</h6>
                  <h4>
                   Layout and Page Design
                  </h4>
                  <p>Once the concept is approved, we move on to the book page design phase. This includes designing the layout of each page, incorporating any images, and finalizing typography choices.</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="portfolio-text-sec-left" data-aos="fade-right" data-aos-duration="1000">
                  <h6>STEP 03</h6>
                  <h4>
                  Revisions and Final Approval
                  </h4>
                  <p>We provide you with drafts for review and are happy to make any changes necessary to ensure your satisfaction. Once the design is approved, we prepare the final files for printing or digital distribution.</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6"></div>
              <div className="col-lg-6">
               <div className="portfolio-text-sec-right" data-aos="fade-left" data-aos-duration="1000">
                  <h6>STEP 04</h6>
                  <h4>
                   Production and Delivery
                  </h4>
                  <p>After final approval, we ensure your files are print-ready or optimized for digital platforms so you can publish your book confidently.</p>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </section>
    </>
  );
};
