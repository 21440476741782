import React from 'react'
import { FaArrowAltCircleRight } from 'react-icons/fa'
import { Link } from 'react-router-dom'

export const SectionFour = () => {
  return (
    <>
            <div className="service-item bottom">
                <div className="container d-flex align-items-center flex-row-reverse">
                    <div className="left" data-aos="fade-left">
                        <h3 className="title">PPC SERVICES</h3>
                        <p className="short-description">Pay-per-click (PPC) advertising is one of the fastest and most effective ways to get your business in front of the right audience. At Apex Creative Designs, we offer comprehensive PPC services that are designed to help you maximize your online visibility and achieve measurable results. Our team of experts specializes in creating, managing, and optimizing PPC campaigns that drive traffic and generate leads. </p>
                        <div className="service-links">
                            {/* <ul>
                                <li><a className="magento" href="#">Magento Development</a></li>
                                <li><a className="woocommerce" href="#">Woocommerce Development</a></li>
                                <li><a className="wordpress" href="#">Wordpress Development</a></li>
                                <li><a className="shopify" href="#">Shopify Development</a></li>
                            </ul> */}
                        </div>
                        <div className="cta-button">
                            <Link className="primary-btn get-center hvr-shutter-out-horizontal btn" to="/contact-us"><span>Talk To Our Experts  </span><FaArrowAltCircleRight className='banner-icon' /></Link> 
                            </div>
                    </div>
                    <div className="right" data-aos="fade-right">
                        <div className="service-image text-center">
                            <picture> <img decoding="async" className=" lazyloaded" src={require('../../assets/ppcMarketting.png')} data-src={require('../../assets/ecommerce-application.webp')} data-srcset={require('../../assets/ecommerce-application.webp')} alt="eCommerce Applications" height="420" width="610" srcset={require('../../assets/ecommerce-application.webp')}/> </picture>
                        </div>
                    </div>
                </div>
            </div>        
    </>
  )
}
