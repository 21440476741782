import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

export const FifthSec = () => {
  var settings = {
    // dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: true,
    className: "myCustomCarousel",
  };
  return (
    <>
      <section className="materialDigital" style={{background:'#232323'}} >
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="colEight">
                <h3>
                Our Business
                 <span> Stationery Design  </span> Services Tailored to Your Brand
                </h3>
                <p>
                Our business stationery design goes beyond just visual appeal. We focus on delivering polished, professional, and cohesive designs that reflect your brand's values and personality. Whether you are looking for small business stationery UK or international logo stationery solutions, we provide personalized designs that showcase your brand and add a touch of elegance to all your communications.
                </p>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="colEight">
                <h3>
                Why Choose Our
                 <span> Custom Stationery Design  </span> Solutions?
                </h3>
                <p>
                At Apex Creative Design, business stationery should work in harmony with the rest of your brand. Our expert stationery designer team ensures that each piece of stationery, from business cards to letterheads, carries your branding seamlessly across all platforms. By integrating your logo and brand colors, we create stationery that reinforces your professional image and enhances brand recognition.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
