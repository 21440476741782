import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

export const FifthSec = () => {
  var settings = {
    // dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: true,
    className: "myCustomCarousel",
  };
  return (
    <>
      <section className="materialDigital" style={{background:'#232323'}}>
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="colEight">
                <h3>
                WHY <br />
                 <span> WORK  </span>WITH APEX CREATIVE DESIGN
                </h3>
                <p>
                When you choose Apex Creative Design for your animated logo needs, you're partnering with a team of professionals committed to making your brand shine. We stand out from other design companies by offering:
                </p>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
              <div className="col-lg-2">
                <img width={150} src={require("../assets/save-time_10446391.png")} />
              </div>
              <div className="col-lg-10">
                <div className="colEight">
                  <h3>Save Time and Money</h3>
                  <p>
                  With digital mock ups, you can visualize your product without investing in physical prototypes. This not only speeds up the design process but also saves money on materials and production. Our mockup packaging services allow you to make changes to your design quickly and easily, ensuring that the final product is perfect before manufacturing begins.
                  </p>
                </div>
              </div>
            </div>
            <div className="div-border-line"></div> 
            <div className="row align-items-center">
              <div className="col-lg-2">
                <img width={150} src={require("../assets/improve_marketing.png")} />
              </div>
              <div className="col-lg-10">
                <div className="colEight">
                  <h3>Improve Marketing and Presentation</h3>
                  <p>
                  A well-designed digital mock up can be a powerful marketing tool. Use it to showcase your product on your website, social media, and in promotional materials. Our food packaging mockup designs, for example, can help restaurants and food companies present their menu items in the best possible light, attracting customers and driving sales.
                  </p>
                </div>
              </div>
            </div>
            <div className="div-border-line"></div>
            <div className="row align-items-center">
              <div className="col-lg-2">
                <img width={150} src={require("../assets/better_feedback.png")} />
              </div>
              <div className="col-lg-10">
                <div className="colEight">
                  <h3>Better Collaboration and Feedback</h3>
                  <p>
                  By providing a clear and detailed digital mock up of your product, you can share your design with stakeholders, partners, and customers to gather feedback before moving forward with production. This collaborative approach ensures that everyone involved is on the same page, resulting in a product that meets everyone's expectations.
                  </p>
                </div>
              </div>
            </div>
            <div className="div-border-line"></div>
            <div className="row align-items-center">
              <div className="col-lg-2">
                <img width={150} src={require("../assets/realisticbusiness_11425653.png")} />
              </div>
              <div className="col-lg-10">
                <div className="colEight">
                  <h3>Realistic Visual Representation</h3>
                  <p>
                  Our digital mock ups offer realistic representations of your product, giving you a clear understanding of how it will look in real life. From texture to color accuracy, our mockup packaging services are designed to help you see every detail before making final design decisions.
                  </p>
                </div>
              </div>
            </div>
        </div>
      </section>
    </>
  );
};
