import React from 'react'

export const Hero = () => {
  return (
    <section className='digitalBanner'>
    <div className='container'>
        <div className='row align-items-center'>
            <div className='col-lg-12'>
                <h2>
                    <span>
                    All  </span> Packages
                </h2>
                <p>
                At Apex Creative Design, digital branding is more than just a logo or a clever tagline. It's about creating a distinctive identity that resonates with your audience. In today's fast-paced digital world, your brand needs to stand out and make a lasting impact. 
                <br />
                Our Digital Branding Services are crafted to reflect the core values and aspirations of your business, ensuring that every touchpoint, from website design to social media, delivers meaningful connections with your customers. We help your brand break through the noise and communicate its unique value, driving engagement and loyalty.
                </p>
            </div>
              
        </div>
    </div>
    </section>
  )
}
